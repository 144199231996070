import React, { useRef, useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import JsSIP from "react-native-jssip";
import { RTCSession } from "react-native-jssip/lib/RTCSession";
import { CallOptions, UAConfiguration } from "react-native-jssip/lib/UA";
import { Button, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import SVG from "react-inlinesvg";

import { RootState } from "../../redux/store";
import { onStartCall, onStopCall } from "../../redux/conferenceReducer";
import getUpload from "../../utils/getUrl";

import IconExpand from "../../images/expand_icon.svg";
import { VerticalButton } from "../Buttons/VerticalButton";
import { JitsiMeeting } from "@jitsi/react-sdk";
import {
    AudioIcon,
    ChatIcon,
    EndCallIcon,
    FormIcon,
    RecIcon,
    ShareCallIcon,
    UsersIcon,
    VideoCallIcon,
} from "../../assets/images";
import { CustomCircularProgress } from "../Loader";

// JsSIP.debug.enable("JsSIP:*");

interface StreamTypes {
    conference: any;
    onSetStream: () => void;
}

const Stream: React.FC<StreamTypes> = ({ conference, onSetStream, setShowEndPopup, mode = "full" }) => {
    const apiRef = useRef();
    const [logItems, updateLog] = useState([]);
    const [showNew, toggleShowNew] = useState(false);
    const [recording, setRecording] = useState(false);
    const [loadingRecord, setLoadingRecord] = useState(false);
    const [knockingParticipants, updateKnockingParticipants] = useState([]);

    const printEventOutput = (payload) => {
        updateLog((items) => [...items, JSON.stringify(payload)]);
    };

    const handleAudioStatusChange = (payload, feature) => {
        if (payload.muted) {
            updateLog((items) => [...items, `${feature} off`]);
        } else {
            updateLog((items) => [...items, `${feature} on`]);
        }
    };

    const handleChatUpdates = (payload) => {
        if (payload.isOpen || !payload.unreadCount) {
            return;
        }
        apiRef.current.executeCommand("toggleChat");
        updateLog((items) => [...items, `you have ${payload.unreadCount} unread messages`]);
    };

    const handleKnockingParticipant = (payload) => {
        updateLog((items) => [...items, JSON.stringify(payload)]);
        const { participant } = payload;
        updateKnockingParticipants((participants) => [...participants, participant]);
    };

    const resolveKnockingParticipants = (condition) => {
        knockingParticipants.forEach((participant) => {
            const { id } = participant;
            apiRef.current.executeCommand("answerKnockingParticipant", id, condition(participant));
            updateKnockingParticipants((participants) => participants.filter((item) => item.id === participant.id));
        });
    };

    const handleJitsiIFrameRef1 = (iframeRef) => {
        iframeRef.style.height = "400px";
        iframeRef.style.marginBottom = "20px";
    };

    const handleJitsiIFrameRef2 = (iframeRef) => {
        iframeRef.style.marginTop = "10px";
        iframeRef.style.border = "10px dashed #df486f";
        iframeRef.style.padding = "5px";
        iframeRef.style.height = "400px";
    };

    const handleJaaSIFrameRef = (iframeRef) => {
        iframeRef.style.border = "10px solid #3d3d3d";
        iframeRef.style.background = "#3d3d3d";
        iframeRef.style.height = "400px";
        iframeRef.style.marginBottom = "20px";
    };

    const handleApiReady = (apiObj) => {
        apiRef.current = apiObj;
        apiRef.current.on("knockingParticipant", handleKnockingParticipant);
        apiRef.current.on("audioMuteStatusChanged", (payload) => handleAudioStatusChange(payload, "audio"));
        apiRef.current.on("videoMuteStatusChanged", (payload) => handleAudioStatusChange(payload, "video"));
        apiRef.current.on("raiseHandUpdated", printEventOutput);
        apiRef.current.on("titleViewChanged", printEventOutput);
        apiRef.current.on("chatUpdated", handleChatUpdates);
        apiRef.current.on("knockingParticipant", handleKnockingParticipant);
        apiRef.current.on("recordingLinkAvailable", (e) => {
            console.log("LINK", e);
        });
        apiRef.current.on("recordingStatusChanged", (e) => {
            console.log("REC2", e);
            if (!e.error) setRecording(e.on);
        });
        setStart(true);
    };

    const handleReadyToClose = () => {
        /* eslint-disable-next-line no-alert */
        alert("Ready to close...");
    };

    const generateRoomName = () => `JitsiMeetRoomNo${Math.random() * 100}-${Date.now()}`;

    // Multiple instances demo
    const renderNewInstance = () => {
        if (!showNew) {
            return null;
        }

        return <JitsiMeeting roomName={generateRoomName()} getIFrameRef={handleJitsiIFrameRef2} />;
    };

    const recordHandler = () => {
        if (recording) {
            apiRef.current.executeCommand("stopRecording", "file");
        } else {
            apiRef.current.executeCommand("startRecording", {
                mode: "file",
            });
        }
    };

    const copyUrl = () => {
        const content = `https:/tkbel.b24chat.com/conference/${conference.hashCode}`;
        navigator.clipboard
            .writeText(content)
            .then(() => {
                alert("Url copied...");
                console.log("Text copied to clipboard...");
            })
            .catch((err) => {
                console.log("Something went wrong", err);
            });
    };

    const renderButtons = () => (
        <div className={classes.buttons}>
            <VerticalButton onClick={() => apiRef.current.executeCommand("toggleAudio")}>
                <AudioIcon />
                <div>Sound</div>
            </VerticalButton>

            <VerticalButton onClick={() => apiRef.current.executeCommand("toggleVideo")}>
                <VideoCallIcon />
                <div>Video</div>
            </VerticalButton>

            <VerticalButton onClick={() => apiRef.current.executeCommand("toggleShareScreen")}>
                <ShareCallIcon />
                <div>Screen</div>
            </VerticalButton>

            <VerticalButton onClick={() => apiRef.current.executeCommand("toggleChat")}>
                <ChatIcon />
                <div>Chat</div>
            </VerticalButton>

            <VerticalButton onClick={() => apiRef.current.executeCommand("toggleParticipantsPane")}>
                <UsersIcon />
                <div>Members</div>
            </VerticalButton>

            <VerticalButton onClick={() => recordHandler()}>
                {loadingRecord && <CustomCircularProgress />}
                {!loadingRecord && (
                    <>
                        <RecIcon />
                        <div>Recording</div>
                    </>
                )}
            </VerticalButton>

            <VerticalButton onClick={() => setShowShare(true)}>
                <FormIcon />
                <div>Share</div>
            </VerticalButton>

            <VerticalButton onClick={() => setShowEndPopup(true)}>
                <EndCallIcon />
                <div>Stop</div>
            </VerticalButton>
        </div>
    );

    const renderLog = () =>
        logItems.map((item, index) => (
            <div
                style={{
                    fontFamily: "monospace",
                    padding: "5px",
                }}
                key={index}
            >
                {item}
            </div>
        ));

    const renderSpinner = () => (
        <div
            style={{
                fontFamily: "sans-serif",
                textAlign: "center",
            }}
        >
            Loading..
        </div>
    );

    const classes = useStyles({});
    const user = useSelector((state: RootState) => state.user);
    const [isInit, setInit] = useState(false);
    const [isStart, setStart] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onUnload = useCallback(() => {
        dispatch(onStopCall());
    }, []); // eslint-disable-line

    useEffect(() => {
        window.addEventListener("beforeunload", onUnload);
        return () => window.removeEventListener("beforeunload", onUnload);
    }, [onUnload]);

    console.log("conference", conference);

    const ShareModal = () => {
        return (
            showShare && (
                <Modal className={classes.modal} onClose={() => setShowShare(false)} open={true}>
                    <div className={classes.wrapperDiv}>
                        <div className={classes.modalTitle}>Conference link</div>
                        <div className={classes.modalTitle}>
                            https://tkbel.b24chat.com/conference/{conference.hashCode}
                        </div>
                        <button onClick={() => copyUrl()} className={classes.modalBtn}>
                            Copy url
                        </button>
                    </div>
                </Modal>
            )
        );
    };

    return (
        <>
            {mode === "full" ? (
                <div className={classes.videoContainer}>
                    <div className={classes.video}>
                        {!isStart && (
                            <img className={classes.videoPreview} src={getUpload(conference.posterImage)} alt="" />
                        )}
                        <div
                            className={cx(
                                classes.videoSrcContainer,
                                !isStart && classes.videoSrcHide,
                                fullScreen && classes.videoSrcContainerFull,
                            )}
                        >
                            <JitsiMeeting
                                domain="video.b24chat.com"
                                roomName={conference.hashCode}
                                spinner={renderSpinner}
                                userInfo={{
                                    displayName: `${user.profile.firstName} ${user.profile.lastName}`,
                                    email: user.profile.email,
                                }}
                                configOverwrite={{
                                    subject: conference.title,
                                    hideConferenceSubject: false,
                                    toolbarButtons: [],
                                    requireDisplayName: false,
                                    prejoinPageEnabled: false,
                                    disableDeepLinking: true,
                                }}
                                interfaceConfigOverwrite={{
                                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                    DISPLAY_WELCOME_FOOTER: false,
                                    DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
                                    DISPLAY_WELCOME_PAGE_CONTENT: false,
                                    DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
                                    SHOW_JITSI_WATERMARK: false,
                                }}
                                onApiReady={(externalApi) => handleApiReady(externalApi)}
                                onReadyToClose={handleReadyToClose}
                                getIFrameRef={handleJitsiIFrameRef1}
                            />
                            {renderButtons()}
                        </div>
                    </div>
                    <ShareModal />
                </div>
            ) : (
                ""
            )}
        </>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        margin: "35px 0",
        textAlign: "left",
    },
    videoContainer: {
        position: "relative",
    },
    video: {
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px",
        borderRadius: "24px",
        background: "white",
        "& img": {
            width: "100%",
        },
    },
    videoPreview: {
        width: "100%",
        objectFit: "cover",
    },
    videoSrc: {
        width: "100%",
        height: "100%",
    },
    audioSrc: {
        display: "none",
    },
    videoSrcContainer: {
        position: "relative",
        width: "100%",
        height: "max-content",
        backgroundColor: "#fff",
        "& #jitsiMeeting-2": {
            borderRadius: "20px",
            overflow: "hidden",
        },
    },
    videoSrcHide: {
        display: "none",
    },
    fullScreenBtn: {
        position: "absolute",
        bottom: 20,
        right: 15,
    },
    videoSrcContainerFull: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
        height: "100vh",
        width: "100vw",
        "& svg": {
            bottom: 50,
            right: 50,
            width: 50,
            height: 50,
        },
    },
    buttons: {
        display: "flex",
        flexDirection: "row",
        columnGap: "12px",
        "@media (max-width: 820px)": {
            flexWrap: "wrap",
            rowGap: "12px",
            "& > div": {
                width: "fit-content",
            },
        },
    },
    modal: {},
    wrapperDiv: {
        minHeight: 200,
        background: "#ffffff",
        width: 600,
        margin: "0 auto",
        top: "50%",
        position: "relative",
        transform: "translate(0, -50%)",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    },
    modalTitle: {
        fontSize: 20,
        margin: "0 0 15px",
    },
});

export default Stream;

import React from "react";
import { FieldProps } from "formik";
import { makeStyles } from "@material-ui/core";
import { getNestedValue } from "../../helpers/getNestedValue";
interface IInputProps extends FieldProps {
    label?: string;
}

const FormikTeaxtarea = ({
    label,
    field: { value, onChange, ...field },
    form: { errors, touched },
    ...props
}: IInputProps) => {
    const classes = useStyles({});
    const fieldError = getNestedValue(errors, field.name);
    const fieldTouched = getNestedValue(touched, field.name);
    return (
        <div className={classes.wrapper}>
            {label && <p className={classes.label}>{label}</p>}
            <textarea value={value} onChange={onChange} {...field} {...props} />
            {fieldError && fieldTouched && <p className={classes.error}>{fieldError}</p>}
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",

        "& textarea": {
            resize: "none",
            width: "100%",

            "&:focus": {
                outline: "none",
            },
        },
    },
    error: {
        color: "#cc0606",
        fontWeight: 500,
    },
    label: {
        fontWeight: 600,
        margin: "0 0 5px 1px!important",
    },
});

export default FormikTeaxtarea;

import React from "react";
import { ListItem, Grid } from "@material-ui/core";
import { isOngoing, convertToDate, I18n, isConferenceAborted } from "@tkbel/common";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import cx from "classnames";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import HighlighteText from "./TextWithHighlight";
import { red } from "../helpers/colors";
import { getFormatHr } from "../helpers/dateFormat";
import { VideoIcon, HeadphoneIcon } from "../assets/images";
import FileIcon from "../images/File_icon.svg";
import getUpload from "../utils/getUrl";

const SectionItem: React.FC<{
    conference: any;
    id: string;
    search: string;
    onClickItem: (hash: any) => void;
}> = ({ conference, search, id, onClickItem }) => {
    const classes = useStyles({});
    const cityToDisplay = conference.city;

    return (
        <ListItem button className={classes.listItem} key={id} onClick={() => onClickItem(conference.hashCode)}>
            <Grid container className={classes.grid} spacing={3}>
                <Grid className={classes.item} item xs={2}>
                    <img alt="profile" className={classes.posterImage} src={getUpload(conference.posterImage)} />
                </Grid>
                <Grid className={classes.confDetails} item xs={8}>
                    <HighlighteText searchWords={search} textToHighlight={conference.title} style={classes.title} />
                    <div>
                        <LocationOnIcon />
                        <HighlighteText searchWords={search} textToHighlight={cityToDisplay} style={classes.city} />
                    </div>
                    {/*{conference.location.distance && <span>{calculateDistance(conference.location.distance)}</span>}*/}
                </Grid>
                <Grid className={classes.timeDetails} item xs={2}>
                    {isOngoing(conference) && <div className={classes.ongoing}>{I18n.t("ongoing")}</div>}
                    <div className={classes.time}>
                        {moment(convertToDate(conference.startTime)).format(getFormatHr())}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: "0.8rem" }}>
                        {conference.pdfs &&
                            conference.pdfs.length > 0 &&
                            (conference.actuallyEndTime || isConferenceAborted(conference)) && (
                                <div className={classes.audioContainer}>
                                    <img src={FileIcon} alt="pdf" className={classes.contentImage} />
                                    <span className={classes.audioTitle}>{I18n.t("pdf")}</span>
                                </div>
                            )}
                        {conference.recordType && !conference.actuallyEndTime && (
                            <div className={classes.audioContainer} style={{ paddingLeft: "1.4rem" }}>
                                <img
                                    src={conference.recordType === "video" ? VideoIcon : HeadphoneIcon}
                                    alt="audio"
                                    className={cx(
                                        classes.contentImage,
                                        conference.recordType === "video" ? classes.contentImageVideo : null,
                                    )}
                                />
                                <span className={classes.audioTitle}>
                                    {conference.recordType === "video" ? "Video" : I18n.t("audioDownload")}
                                </span>
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </ListItem>
    );
};

const useStyles = makeStyles({
    listItem: {
        cursor: "pointer !important",
    },
    grid: {
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            display: "block",
            margin: "unset",
        },
    },
    item: {
        marginBottom: "0.3rem",
        display: "flex",
        alignItems: "center",
        padding: "0.8rem !important",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            display: "block",
            padding: "unset !important",
            marginBottom: "unset",
            maxWidth: "100%",
        },
    },
    posterImage: {
        width: "100%",
        objectFit: "cover",
        borderRadius: 10,
    },
    confDetails: {
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem 0.9rem !important",
        justifyContent: "space-between",
        "& svg": {
            width: "16px",
            height: "16px",
        },
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            display: "block",
            padding: "unset !important",
            paddingTop: "20px !important",
        },
    },
    timeDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        padding: "1.5rem 0.9rem !important",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            display: "block",
            padding: "unset !important",
            paddingTop: "20px !important",
        },
    },
    ongoing: {
        fontSize: 14,
        color: red,
        fontWeight: 600,
        marginBottom: "0.5rem",
    },
    title: {
        fontWeight: 600,
        fontSize: 16,
    },
    city: {
        fontSize: 14,
    },
    highlight: {
        color: red,
        backgroundColor: "transparent",
    },
    time: {
        fontSize: 12,
        fontWeight: 500,
        whiteSpace: "nowrap",
    },
    audioContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    },
    contentImage: {
        width: "17px",
        height: "15px",
    },
    contentImageVideo: {
        width: "24px",
    },
    audioTitle: {
        margin: "4px 0 0",
        color: red,
        fontSize: "12px",
    },
});

export default SectionItem;

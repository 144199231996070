import { Api } from "./api";

export const userLogin = async (data) => {
    return await Api.post("user/login", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const userValidate = async (data) => {
    return await Api.post(`user/validate`, data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getUser = async (id) => {
    return await Api.get(`user/${id}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getUserProfile = async () => {
    return await Api.get("user/me")
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const updateUser = async (id, data) => {
    return await Api.patch(`user/me`, data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const deleteUser = async (id) => {
    return await Api.delete(`user/${id}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getAllUsers = async () => {
    return await Api.get("user")
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const userLogout = async () => {
    return await Api.get("user/logout")
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const addConferenceToWishlist = async (data) => {
    return await Api.post("user/wishlist", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const removeConferenceFromWishlist = async (data) => {
    return await Api.delete("user/wishlist/deleteCollection", { data: data })
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const addConferenceToLibrary = async (data) => {
    return await Api.post("user/library", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const removeConferenceFromLibrary = async (data) => {
    return await Api.delete("user/library/deleteCollection", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const addMoneyToUser = async (data) => {
    return await Api.post("user/money", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const joinToConference = async (data) => {
    return await Api.post("user/join", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const leftConference = async () => {
    return await Api.post("user/left")
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const muteUser = async (data) => {
    return await Api.post("user/mute", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getSpeakers = async (search) => {
    return await Api.get(`user/ratingUsers/${search}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getSearchSpeakers = async (search) => {
    return await Api.get(`user/search/${search}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

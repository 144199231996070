import { makeStyles } from "@material-ui/core";
import React from "react";

import { linearBackgroud, red } from "../../helpers/colors";

interface IProps {
    onChange: () => void;
    value: boolean;
    htmlFor: string;
}

const CustomCheckbox = ({ onChange, value, htmlFor }: IProps) => {
    const classes = useStyles({ value });
    return (
        <div className={classes.wrapper}>
            <label htmlFor={htmlFor}>
                {value && (
                    <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.53033 2.46967C1.23744 2.17678 0.762563 2.17678 0.46967 2.46967C0.176777 2.76256 0.176777 3.23744 0.46967 3.53033L1.53033 2.46967ZM4 6L3.46967 6.53033C3.62052 6.68118 3.82847 6.76065 4.04148 6.74885C4.25448 6.73705 4.45238 6.63511 4.58565 6.46852L4 6ZM8.58565 1.46852C8.84441 1.14507 8.79197 0.673106 8.46852 0.414348C8.14507 0.155591 7.67311 0.208032 7.41435 0.531479L8.58565 1.46852ZM0.46967 3.53033L3.46967 6.53033L4.53033 5.46967L1.53033 2.46967L0.46967 3.53033ZM4.58565 6.46852L8.58565 1.46852L7.41435 0.531479L3.41435 5.53148L4.58565 6.46852Z"
                            fill="white"
                        />
                    </svg>
                )}
            </label>
            <input checked={value} onChange={onChange} id={htmlFor} type="checkbox" />
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "20px",
        height: "20px",
        "& label": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            borderRadius: "6px",
            border: ({ value }) => (!value ? `1px solid ${red}` : "none"),
            background: ({ value }) => (value ? linearBackgroud : "transparent"),
            cursor: "pointer",
            transition: "background .2s cubic-bezier(0.4, 0, 1, 1)",
        },

        "& input": {
            width: "0px",
            height: "0px",
            opacity: 0,
            visibility: "hidden",
            margin: 0,
        },
    },
});

export default CustomCheckbox;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TkUser } from "@tkbel/common";

const ReviewCardHeaderInstructor = ({ user }: { user: TkUser }) => {
    const classes = useStyles({});

    return (
        <div className={classes.wrap}>
            <div className={classes.avatar}>{user.profileImage && <img src={user.profileImage} alt="avatar" />}</div>
            <div className={classes.instructorInfo}>
                <div className={classes.instructorName}>
                    {user.firstName} {user.lastName}
                </div>
                {/* <div className={classes.instructorLang}>English</div> */}
            </div>
            <button className={classes.button}>Following</button>
        </div>
    );
};

const useStyles = makeStyles({
    wrap: {
        minHeight: "59px",
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        width: "59px",
        height: "59px",
        borderRadius: "50%",
        backgroundColor: "black",
        marginRight: "15px",

        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "50%",
        },
    },
    instructorInfo: {
        textAlign: "left",
        minWidth: "156px",
    },
    instructorName: {
        fontSize: "15px",
        lineHeight: "20px",
        height: "25px",
        color: "#222B45",
        marginTop: "6px",
    },
    instructorLang: {
        fontSize: "13px",
        lineHeight: "16px",
        height: "20px",
        color: "#8F9BB3",
        marginTop: "5px",
    },
    button: {
        background: "transparent linear-gradient(92deg, #9D1767 0%, #E8044F 100%) 0% 0% no-repeat padding-box",
        borderRadius: "10px",
        width: "128px",
        height: "41px",
        cursor: "pointer",
        fontSize: "18px",
        lineHeight: "19px",
        color: "#FFF",
        transition: "0.6s",
        border: "none",
        marginLeft: "auto",

        "&:hover": {
            transform: "scale(1.07)",
        },

        "&:active": {
            background: "linear-gradient(272deg, #9D1767 0%, #E8044F 100%)",
        },
    },
});

export default ReviewCardHeaderInstructor;

import { makeStyles } from "@material-ui/styles";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteChildrenProps } from "react-router";

import FormikInput from "../components/FormikComponents/Input";
import Loader from "../components/Loader";
import PageCardWrapper from "../components/PageCardWrapper";
// import { updateUserByUid } from "../firebase/auth";
import { linearBackgroud, purple, reverseLinearBackgroud } from "../helpers/colors";
import { RootState } from "../redux/store";
import { getProfile } from "../redux/userReducer";

import { I18n } from "@tkbel/common";

const Register: React.FC<RouteChildrenProps> = (props) => {
    const { profile } = useSelector((state: RootState) => state.user);
    const [loading, setLoading] = useState(false);
    const classes = useStyles({});

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfile({ uid: profile.id }));
    }, []); // eslint-disable-line

    const initialValues = {
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        email: profile.email || "",
        roles: {
            speaker: false,
        },
    };

    const submit = async () => {
        setLoading(true);
        // await updateUserByUid(profile.id, values).then(() => {
        //     history.push("/");
        // });
        setLoading(false);
    };

    return (
        <div className={classes.wrapper}>
            <PageCardWrapper {...props}>
                <h2>{I18n.t("fillTheseFields")}</h2>
                <Formik initialValues={initialValues} onSubmit={submit}>
                    <Form translate={""}>
                        <Field
                            name="firstName"
                            label={I18n.t("Fname")}
                            placeholder={I18n.t("Fname")}
                            component={FormikInput}
                        />
                        <Field
                            name="lastName"
                            label={I18n.t("Lname")}
                            placeholder={I18n.t("Lname")}
                            component={FormikInput}
                        />
                        <Field
                            name="email"
                            label={I18n.t("email")}
                            placeholder={I18n.t("email")}
                            component={FormikInput}
                        />
                        <button type="submit">{loading ? <Loader /> : I18n.t("register")}</button>
                    </Form>
                </Formik>
            </PageCardWrapper>
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",

        "& > div": {
            width: "700px",
            padding: "30px",
        },

        "& form": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            "& div": {
                width: "400px",
                marginBottom: "10px",
                "& input": {
                    width: "100%",
                    border: `2px solid ${purple}`,
                    height: "40px",
                    marginBottom: "5px",
                    borderRadius: "6px",
                    padding: "10px",
                },
            },

            "& button": {
                color: "#ffffff",
                background: linearBackgroud,
                border: "none",
                width: "400px",
                height: "30px",
                borderRadius: "6px",
                cursor: "pointer",
                transition: ".2s linear",
                "&:hover": {
                    background: reverseLinearBackgroud,
                },
            },
        },
    },
});

export default Register;

import React from "react";
import { RouteChildrenProps } from "react-router";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

import { linearBackgroud } from "../helpers/colors";
import { RootState } from "../redux/store";
import { Login, Register } from "../components";

const LoginPage = (props: RouteChildrenProps) => {
    const classes = useStyles({});
    const { userRegister } = useSelector((state: RootState) => state.user);

    return <div className={classes.wrapper}>{userRegister ? <Register {...props} /> : <Login {...props} />}</div>;
};

const useStyles = makeStyles({
    wrapper: {
        backgroundImage: linearBackgroud,
        position: "relative",
        height: "60%",
        display: "flex",
        alignItems: "flex-start",
    },
});

export default LoginPage;

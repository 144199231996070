import React from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";

import { HomeIcon, HistoryIcon, LibraryIcon } from "../../assets/images";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { I18n } from "@tkbel/common";

import * as classes from "./LeftMenu.module.scss";
import Logo from "../../assets/images/tkbel-logo.png";

interface HeaderTypes {
    location: {
        hash: string;
    };
    history?: any;
}

const LeftMenu: React.FC<HeaderTypes> = () => {
    const { isSpeaker } = useSelector((state: RootState) => state.user);

    const menuItems = [
        {
            icon: HomeIcon,
            route: "/home",
            hoverText: I18n.t("home"),
        },
        {
            icon: HistoryIcon,
            route: "/history",
            hoverText: I18n.t("history"),
        },
        {
            icon: LibraryIcon,
            route: "/conferences",
            hoverText: I18n.t("library"),
        },
    ];

    return (
        <div className={classes.wrapper}>
            <div className={classes.logo}>
                <NavLink to="/home">
                    <img width={"100%"} src={Logo} alt="logo" />
                </NavLink>
            </div>
            {!isSpeaker && (
                <div className={classes.menu}>
                    {menuItems.map((item, index) => (
                        <NavLink
                            key={`Menu_btn_${index}`}
                            activeClassName={classes.active}
                            to={item.route}
                            className={classes.menuItem}
                        >
                            <SVG src={item.icon} />
                            <div>{item.hoverText}</div>
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LeftMenu;

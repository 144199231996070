import React, { useState, useCallback, useMemo } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "./cropImage";
import { makeStyles } from "@material-ui/styles";
import { Fab, Modal } from "@material-ui/core";
import { Done, Close } from "@material-ui/icons";
import { I18n } from "@tkbel/common";
import SVG from "react-inlinesvg";

import extensions from "../helpers/extensions.json";

import "./ImageUpload.css";
import roundIconPen from "../assets/images/round_icon_pen.svg";
import noPicture from "../assets/images/no_picture.svg";
import getUpload from "../utils/getUrl";

enum StatusEnum {
    UPLOAD,
    CROP,
    DONE,
}

const ImageUpload: React.FC<{
    needCrop?: boolean;
    posterImage?: any;
    multiple: boolean;
    image?: any;
    setImage?: (pic: any) => void;
    keyProp: string;
    style?: any;
    cropType?: any;
    disabled?: boolean;
}> = ({ needCrop, posterImage, image, setImage, keyProp, style = {}, multiple, cropType, disabled }) => {
    const [imageStatus, setImageStatus] = useState(StatusEnum.UPLOAD);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [img, setImg] = useState();
    const [aspect, setAspect] = useState(cropType === "conference" ? 16 / 9 : 1);

    const imgUrl = useMemo(() => (img ? (typeof img !== "string" ? URL.createObjectURL(img) : img) : null), [img]);

    const classes = useStyle({});

    const getFileExtension = (filename) => {
        return filename.split(".").pop();
    };

    const isImage = (img) => {
        const extension = getFileExtension(img.name);
        return extensions.includes(extension.toLowerCase());
    };
    const onImageUpload = (e: React.ChangeEvent<{ files: unknown }>) => {
        if (e.target.files) {
            if (multiple) {
                Object.values(e.target.files).forEach((file) => {
                    isImage(file) && setImage(file as Blob);
                });
            } else {
                if (isImage(e.target.files[0])) {
                    setImg(e.target.files[0] as File);
                    needCrop ? setImageStatus(StatusEnum.CROP) : setImage(e.target.files[0] as File);
                }
            }
        }
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const done = async () => {
        try {
            const croppedImage = (await getCroppedImg(imgUrl, croppedAreaPixels, 0)) as Blob;
            const imgName = img.name;
            setImg(croppedImage);
            setImage(new File([croppedImage as Blob], imgName, { type: croppedImage.type }));
            setZoom(1);
            setCrop({ x: 0, y: 0 });
        } catch (e) {
            console.error(e);
        }
    };

    const onImageLoaded = (mediaSize) => {
        setAspect(mediaSize.width / mediaSize.height);
    };

    const getCropStatus = () => {
        return (
            <div key={keyProp} className={classes.cropWrapper}>
                <div className={classes.cropContainer}>
                    <Cropper
                        image={imgUrl}
                        crop={crop}
                        rotation={0}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        onMediaLoaded={onImageLoaded}
                    />
                </div>
                <div className={classes.controls}>
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom as number)}
                    />
                    <div className={classes.fabsWrapper}>
                        <Fab
                            onClick={() => {
                                setImg(image);
                                setImageStatus(StatusEnum.UPLOAD);
                            }}
                        >
                            <Close />
                        </Fab>
                        <Fab
                            color="primary"
                            onClick={() => {
                                done();
                                setImageStatus(StatusEnum.UPLOAD);
                            }}
                        >
                            <Done />
                        </Fab>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <input
                type="file"
                multiple={multiple}
                name={keyProp}
                id={keyProp}
                className="inputfile"
                onChange={onImageUpload}
                accept="image/*"
                disabled={disabled}
            />
            <label
                htmlFor={keyProp}
                style={{
                    ...style,
                    backgroundImage:
                        posterImage && !img ? "linear-gradient(141deg, gray 0%, #e2e2e2 51%, gray 75%)" : "none",
                }}
            >
                {!disabled && <SVG src={roundIconPen} className={classes.rightIcon} />}
                <div className={classes.imgDiv}>
                    {image ? (
                        <img alt="poster" className={classes.uploadImagePlaceholder} src={getUpload(image)} />
                    ) : (
                        <SVG src={posterImage || noPicture} className={classes.uploadImagePlaceholder} />
                    )}
                    {!image && keyProp === "poster-image" && (
                        <div className={classes.titlePlaceholder}>
                            <p className={classes.placeholder}> {I18n.t("posterPlaceholder")}</p>
                        </div>
                    )}
                </div>
            </label>
            <Modal className={classes.modal} open={imageStatus === StatusEnum.CROP}>
                {getCropStatus()}
            </Modal>
        </>
    );
};

const useStyle = makeStyles({
    cropContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        background: "white",
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    sliderContainer: {
        display: "flex",
        flex: "1",
        alignItems: "center",
    },
    sliderLabel: {},
    slider: {
        padding: "22px 0px",
        marginLeft: 16,
    },
    rightIcon: {
        position: "absolute",
        top: "-1.2rem",
        right: " -1.2rem",
        zIndex: 1,
    },
    uploadImagePlaceholder: {
        width: 200,
        height: 200,
        minHeight: "100%",
        minWidth: "100%",
        objectFit: "scale-down",
    },
    titlePlaceholder: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    placeholder: {
        fontSize: 22,
        color: "white",
        fontFamily: "Roboto",
        fontWeight: "bold",
        textAlign: "center",
        textShadow: "2px 2px 5px #A8A9AA",
        letterSpacing: 0.3,
        whiteSpace: "pre-line",
    },
    cropWrapper: {
        width: "50%",
        height: "60%",
        backgroundColor: "white",
        boxShadow: "0px 3px 36px #00000029",
        padding: "1.5rem 1rem 0",
        borderRadius: "0.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        // eslint-disable-next-line
        ["@media (max-width: 578px)"]: {
            width: "80%",
        },
    },
    fabsWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    imgDiv: {
        position: "relative",
        borderRadius: 10,
        overflow: "hidden",
    },
});

export default ImageUpload;

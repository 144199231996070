import React from "react";
import { TkConference, I18n } from "@tkbel/common";
import { LockerIcon } from "../../assets/images";
import cx from "classnames";

import { makeStyles } from "@material-ui/styles";
import { black, gray, primaryColor, red, white } from "../../assets/colors";
import { getFormatD, getFormatHr } from "../../helpers/dateFormat";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Details: React.FC<{ conference: TkConference }> = ({ conference }) => {
    const classes = useStyles({});
    const { profile } = useSelector((state: RootState) => state.user);
    const startTime = conference.startTime;
    const endTime = conference.endTime;

    const getDate = () => {
        const format = getFormatD();
        // @ts-ignore
        return moment(startTime).format(format);
    };

    const getHours = () => {
        // @ts-ignore
        const hour = new Date(startTime);
        return moment(hour).format(getFormatHr());
    };

    const getDuration = () => {
        // @ts-ignore
        let delta = Math.abs(new Date(startTime).valueOf() - new Date(endTime).valueOf()) / 1000;
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        return `${hours}h${minutes ? " " + minutes : ""}`;
    };

    return (
        <div className={classes.container}>
            {conference.accessCode && (
                <div
                    className={cx(classes.detailsGeneralMargin, { flexDirection: "row", justifyContent: "flex-start" })}
                >
                    <img src={LockerIcon} className={classes.lock} alt="" />
                    <div className={classes.access}>
                        {I18n.t("accessCode")}: <span>{conference.accessCode}</span>{" "}
                    </div>
                </div>
            )}
            <div className={cx(classes.header, classes.detailsGeneralMargin)}>
                <div className={classes.titleHeader}>
                    <div className={classes.title}>{conference.title}</div>
                    <div>
                        <div className={cx(classes.speacker, { color: gray })}>
                            {profile.firstName} {profile.lastName}
                        </div>
                    </div>
                </div>
                <div style={{ maxHeight: 70 }}>
                    <div className={classes.conferenceDate}>{getDate()}</div>
                    <div className={classes.contentTime}>
                        <div className={classes.conferenceTime}>{getHours()}</div>{" "}
                        <div className={classes.durationConferenceTime}>{getDuration()}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        flex: 1,
        backgroundColor: "#ffffff",
        maxWidth: "1350px",
        margin: "0 auto 20px",
        padding: "20px",
    },
    videoIcon: {
        width: 20,
        height: 13,
    },
    iconText: {
        color: primaryColor,
        fontSize: 3,
        textAlign: "center",
        fontFamily: "Roboto-Medium",
    },
    headphone: {
        width: 15,
        height: 13,
    },
    audio: {
        fontSize: 11,
        color: primaryColor,
    },
    detailsGeneralMargin: {
        marginHorizontal: 20,
    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    titleHeader: {
        paddingRight: 10,
        flexShrink: 1,
        marginBottom: 5,
    },
    title: {
        fontSize: 19,
        color: black,
        marginBottom: 2,
    },
    speacker: {
        color: red,
        fontSize: 15,
        marginBottom: 5,
    },
    headerBar: {
        backgroundColor: white,
        borderBottomColor: "#bbb",
        borderBottomWidth: 1,
        marginTop: 5,
        marginBottom: 5,
    },
    conferenceDate: {
        marginBottom: -7,
        fontSize: 15,
        color: black,
        textAlign: "right",
    },
    contentTime: {
        flexDirection: "row",
        justifyContent: "flex-end",
        flex: 1,
        alignItems: "center",
    },
    conferenceTime: {
        fontSize: 15,
        color: black,
    },
    durationConferenceTime: {
        fontSize: 15,
        color: gray,
    },
    location: {
        marginBottom: 20,
    },
    locationName: {
        color: black,
        fontSize: 13,
        marginBottom: 3,
    },
    address: {
        fontSize: 12,
        color: black,
    },
    locationLabel: {
        marginTop: 2,
        color: primaryColor,
        fontFamily: "Roboto-Bold",
        flexShrink: 1,
    },
    distanceIcon: {
        color: primaryColor,
        marginLeft: -5,
        paddingTop: -2,
        fontSize: 18,
    },
    description: {
        fontSize: 13,
        color: black,
        textAlign: "left",
        // paddingBottom: 20,
    },
    lock: {
        width: 28,
        height: 28,
        marginRight: 7,
    },
    access: {
        fontFamily: "Roboto-Bold",
        fontSize: 18,
    },
    mainLocation: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    msg: {
        color: primaryColor,
        textAlign: "center",
        fontSize: 13,
        marginVertical: 10,
    },
});

export default Details;

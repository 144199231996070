import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../components/Loader";
import { RootState } from "../redux/store";
import PageCardWrapper from "../components/PageCardWrapper";
import { PageWrapper, Slider } from "../components";

import {
    AllGroups,
    Arts,
    CareerBusiness,
    Environment,
    Health,
    Language,
    Movements,
    Family,
    Religion,
    Science,
    Social,
    Sports,
    Coaching,
    Technology,
    History,
    Geography,
    Cooking,
    Finance,
} from "../components/CategorySlide/images";
import { onGetCategories, onGetConferences } from "../redux/conferenceReducer";

const backgrounds = {
    "All groups": AllGroups,
    Arts: Arts,
    "Career & Business": CareerBusiness,
    "Environment and Tourism": Environment,
    "Health & Wellness": Health,
    "Language & Culture": Language,
    "Movements & Politics": Movements,
    Family: Family,
    "Religion & Spirituality": Religion,
    "Science & Education": Science,
    "Social activities": Social,
    "Sports & Fitness": Sports,
    Coaching: Coaching,
    Technology: Technology,
    History: History,
    Geography: Geography,
    Cooking: Cooking,
    Finance: Finance,
};

const CategoryPage = (props) => {
    let { id } = useParams();
    const classes = useStyles({});
    const { categories, conferences } = useSelector((state: RootState) => state.conference);
    const [futureCourses, setFutureCourses] = useState([]);
    const [pastCourses, setPastCourses] = useState([]);
    const allCategories = [...categories];
    const [currentCategory, setCurrentCategory] = useState<any>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(onGetCategories());
        dispatch(onGetConferences({ search: "" }));
    }, [dispatch]);

    useEffect(() => {
        if (conferences.length) {
            const categoryCourses = conferences.filter((item) => item.categoryId === parseInt(id));
            setPastCourses(categoryCourses.filter((item) => item.recordAudio && item.status === "Past"));

            setFutureCourses(categoryCourses.filter((item) => item.status !== "Past"));
        }
    }, [conferences]); // eslint-disable-line

    useEffect(() => {
        setCurrentCategory(allCategories.filter((item) => item.id === parseInt(id))[0]);
    }, [allCategories]); // eslint-disable-line

    return (
        <PageWrapper {...props}>
            <PageCardWrapper {...props}>
                {!Object.keys(currentCategory || {}).length ? (
                    <Loader />
                ) : (
                    <div className={classes.wrapper}>
                        <div className={classes.header}>
                            <div className={classes.imgWrapper}>
                                <img src={backgrounds[currentCategory.title]} alt="avatar" />
                            </div>
                        </div>
                        <div className={classes.content}>
                            <div className={classes.categoryName}>{currentCategory.title}</div>
                            {futureCourses.length > 0 && (
                                <Slider
                                    title="Future"
                                    titleSize={18}
                                    slides={futureCourses}
                                    slideWrapper="course"
                                    breakpoints={{
                                        1200: {
                                            slidesPerView: 5,
                                        },
                                        991: {
                                            slidesPerView: 4,
                                        },
                                        670: {
                                            slidesPerView: 3,
                                        },
                                        420: {
                                            slidesPerView: 2,
                                        },
                                        300: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                />
                            )}
                            {pastCourses.length > 0 && (
                                <Slider
                                    title="Past"
                                    titleSize={18}
                                    slides={pastCourses}
                                    slideWrapper="course"
                                    breakpoints={{
                                        1200: {
                                            slidesPerView: 5,
                                        },
                                        991: {
                                            slidesPerView: 4,
                                        },
                                        670: {
                                            slidesPerView: 3,
                                        },
                                        420: {
                                            slidesPerView: 2,
                                        },
                                        300: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
            </PageCardWrapper>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        maxWidth: "1350px",
        minWidth: "700px",
        margin: "0 auto",
        textAlign: "left",
        // eslint-disable-next-line
        ["@media (max-width: 576px)"]: {
            minWidth: "auto",
            maxWidth: "100%",
        },
    },
    header: {
        margin: "0 0 20px",
    },
    imgWrapper: {
        width: "100%",
        height: "300px",
        overflow: "hidden",
        borderRadius: "10px 10px 0 0",
        "& img": {
            objectFit: "cover",
            width: "100%",
            height: "100%",
        },
    },
    categoryName: {
        fontSize: 26,
        margin: "10px 0 0",
    },
    content: {
        padding: "20px 30px 30px",
    },
});

export default CategoryPage;

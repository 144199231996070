import { darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Formik, Form, Field } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { PenIcon } from "../../../assets/images";
import { PageWrapper } from "../../../components";
import PageCardWrapper from "../../../components/PageCardWrapper";
import FormikTeaxtarea from "../../../components/texrarea";
import { linearBackgroud, white } from "../../../helpers/colors";
import { RootState } from "../../../redux/store";
import { speakerGet } from "../../../redux/speakerReducer";
import Loader from "../../../components/Loader";

const SpeakerBoardEdit = ({ props }) => {
    const { profile, isSpeaker } = useSelector((state: RootState) => state.user);
    const { speaker } = useSelector((state: RootState) => state.speaker);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles({});
    const [photo, setPhoto] = useState<{ file: File | null; url: string }>({ file: null, url: "" });
    const [certificates, setCertificates] = useState<Array<{ id: number; name: string }>>([]); // eslint-disable-line
    const [loading, setLoading] = useState(false);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        dispatch(speakerGet({ id, errorCallback: () => {} }));
    }, [id]); // eslint-disable-line

    useEffect(() => {
        if (id) {
            id !== profile.id && history.goBack();
        }
    }, [id]); // eslint-disable-line

    useEffect(() => {
        setPhoto({ file: null, url: profile.profileImage });
    }, []); // eslint-disable-line

    useEffect(() => {
        if (profile.speakerDetails && Object.keys(speaker).length && id === profile.id) {
            setCertificates(speaker.speakerDetails.certificates);
        }
    }, [speaker]); // eslint-disable-line

    const addPhoto = (e: ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();
        const file = e.target.files;
        if (!file || !file[0]) return;
        reader.onloadend = () => {
            setPhoto({ file: file[0], url: reader.result as string });
        };

        reader.readAsDataURL(file[0]);
    };

    // const addCertificate = () => {
    //     if (!certificates.length) return setCertificates([{ id: 1, name: "" }]);
    //
    //     if (!certificates[certificates.length - 1].name) return;
    //
    //     setCertificates([...certificates, { id: certificates[certificates.length - 1].id + 1, name: "" }]);
    // };

    // const deleteCertificate = (id: number) => {
    //     const newArr = certificates.filter((item) => item.id !== id);
    //     setCertificates(newArr);
    // };
    //
    // const changeHandler = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    //     const newArr = [...certificates];
    //     newArr[idx] = { id: newArr[idx].id, name: e.target.value };
    //     setCertificates(newArr);
    // };

    const initialValues = {
        // name: `${profile.lastName} ${profile.firstName}`,
        // specialization:
        //     id === profile.id && Object.keys(speaker).length && profile.roles.speaker
        //         ? speaker.speakerDetails.specialization
        //         : "",
        biography: profile.biography || "",
        // facebook:
        //     id === profile.id && Object.keys(speaker).length && profile.roles.speaker
        //         ? speaker.speakerDetails.facebook
        //         : "",
        // twitter:
        //     id === profile.id && Object.keys(speaker).length && profile.roles.speaker
        //         ? speaker.speakerDetails.twitter
        //         : "",
        // description:
        //     id === profile.id && Object.keys(speaker).length && profile.roles.speaker
        //         ? speaker.speakerDetails.description
        //         : "",
    };

    const submit = async () => {
        // let filePath = "";
        setLoading(true);
        // if (photo.file) {
        //     await uploadProfileImage(photo.file, profile.id);
        //     const path = await getProfileImage(profile.id);
        //
        //     filePath = path;
        // } else filePath = photo.url;

        // const body = {
        //     roles: {
        //         speaker: true,
        //     },
        //     profileImage: filePath,
        //     ...values,
        // };
        // updateUserByUid(profile.id, body)
        //     .then(() => history.goBack())
        //     .finally(() => setLoading(false));
    };

    return (
        <PageWrapper {...props}>
            <PageCardWrapper {...props}>
                {isSpeaker && id && !Object.keys(speaker).length ? (
                    <Loader />
                ) : (
                    <div className={classes.wrapper}>
                        <Formik initialValues={initialValues} onSubmit={submit}>
                            <Form translate={""}>
                                <div className={classes.header}>
                                    <div className={classes.imgWrapper}>
                                        <label htmlFor="file">
                                            {photo.url && <img src={photo.url} alt="speakerBoardImg" />}
                                            {!photo.url && <PenIcon />}
                                        </label>
                                        <input id="file" type="file" onChange={addPhoto} />
                                    </div>
                                </div>
                                <div className={classes.content}>
                                    {/* <div className={classes.headerFieald}>
                                        <Field
                                            placeholder="Name"
                                            label="Name"
                                            name="name"
                                            className={classes.name}
                                            component={FormikInput}
                                        />
                                        <Field
                                            placeholder="Specialization"
                                            label="Specialization"
                                            name="specialization"
                                            className={classes.spec}
                                            component={FormikInput}
                                        />
                                    </div> */}
                                    <Field
                                        placeholder="Biography"
                                        label="Biography"
                                        name="biography"
                                        className={classes.biography}
                                        component={FormikTeaxtarea}
                                    />
                                    {/* <Field
                                        placeholder="Description"
                                        label="Description"
                                        name="description"
                                        className={classes.biography}
                                        component={FormikTeaxtarea}
                                    />
                                    <div className={classes.contacts}>
                                        <p>Contacts</p>
                                        <Field
                                            placeholder="Facebook"
                                            label="Facebook"
                                            name="facebook"
                                            component={FormikInput}
                                        />
                                        <Field
                                            placeholder="Twitter"
                                            label="Twitter"
                                            name="twitter"
                                            component={FormikInput}
                                        />
                                    </div>
                                    <div className={classes.certificats}>
                                        <p>Certificats</p>
                                        {certificates.map((item, index) => (
                                            <div className={classes.certificateWrapper} key={item.id}>
                                                <input
                                                    placeholder="Certificat name"
                                                    type="text"
                                                    value={item.name}
                                                    onChange={(e) => changeHandler(e, index)}
                                                />
                                                {item.name && <DeleteIcon onClick={() => deleteCertificate(item.id)} />}
                                            </div>
                                        ))}
                                        <button type="button" onClick={addCertificate}>
                                            <PlusIcon /> Add certificate
                                        </button>
                                    </div> */}
                                    <div className={classes.buttons}>
                                        <button
                                            onClick={() => history.goBack()}
                                            className={classes.submit}
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                        <button className={classes.submit} type="submit">
                                            {loading ? <Loader /> : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                )}
            </PageCardWrapper>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "700px",
        // padding: '20px',
    },
    header: {
        position: "relative",
        width: "100%",
        height: "500px",
    },
    imgWrapper: {
        width: "100%",
        height: "100%",
        "& label": {
            display: "flex",
            width: "100%",
            height: "100%",
            borderRadius: "12px 12px 0 0",
            background: darken("#ffffff", 0.1),
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",

            "& img": {
                width: "100%",
                height: "100%",
                borderRadius: "12px 12px 0 0",
            },
        },

        "& input": {
            width: "1px",
            height: "1px",
            visability: "hidden",
        },
    },
    headerFieald: {
        display: "flex",
        gap: "10px",
        // position: "absolute",
        // bottom: "10px",
        // left: "10px",

        "& input": {
            // background: darken("#ffffff", 0.1),
            border: `1px solid ${darken("#ffffff", 0.3)}`,
            borderRadius: "4px",
            padding: "5px",
            width: "100%",
            marginBottom: "5px",
        },
    },
    name: {},
    spec: {},
    biography: {
        marginBottom: "10px",
        height: "200px",
    },
    content: {
        position: "relative",
        top: "-80px",
        boxShadow: " -2px -5px 10px -5px rgba(0, 0, 0, 0.6)",
        borderRadius: "25px 25px 0 0",
        width: "100%",
        padding: "20px",
        background: white,

        "& textarea": {
            border: `1px solid ${darken("#ffffff", 0.3)}`,
            borderRadius: "4px",
            padding: "5px",
        },
    },
    certificats: {
        width: "100%",
        marginTop: "20px",
        "& p": {
            fontSize: "18px",
            fontWeight: 700,
        },
        "& input": {
            border: `1px solid ${darken("#ffffff", 0.3)}`,
            borderRadius: "4px",
            padding: "5px",
            marginBottom: "5px",
            width: "90%",
        },

        "& button": {
            display: "flex",
            alignItems: "center",
            background: darken("#ffffff", 0.05),
            border: "none",
            padding: "10px",
            borderRadius: "6px",
            marginTop: "10px",
            cursor: "pointer",
            fontWeight: 600,
            fontSize: "16px",
            "& svg": {
                width: "25px",
                height: "25px",
                marginRight: "10px",
            },
        },
    },
    contacts: {
        width: "100%",
        marginTop: "20px",
        "& p": {
            fontSize: "18px",
            fontWeight: 700,
        },
        "& input": {
            border: `1px solid ${darken("#ffffff", 0.3)}`,
            borderRadius: "4px",
            padding: "5px",
            marginBottom: "5px",
            width: "100%",
        },
    },
    certificateWrapper: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: "30px",
            height: "30px",

            "& svg, & svg path": {
                fill: linearBackgroud,
            },
        },
    },
    submit: {
        width: "50%",
        height: "40px",
        background: linearBackgroud,
        borderRadius: "6px",
        border: "none",
        color: white,
        fontSize: "16px",
        fontWeight: 500,
        cursor: "pointer",
        margin: "20px 10px 0 0",
    },
    buttons: {
        display: "flex",
    },
});

export default SpeakerBoardEdit;

import React, { useEffect, useCallback, useState, useMemo } from "react";
import { List, ListItem } from "@material-ui/core";
import { I18n } from "@tkbel/common";
import { makeStyles } from "@material-ui/styles";

import img from "../assets/images/powered_by_google_on_white.png";
import { GooglePlacesAPI } from "../helpers/GooglePlacesApi";

const GooglePlacesSearch: React.FC<{
    onChoose: (location: any) => void;
    top: string;
    searchText: string;
}> = ({ onChoose, top, searchText }) => {
    const classes = useStyle({});
    const [pickerItems, updatePickerItems] = useState<Array<any>>([]);
    const lang = navigator.language.split("-")[0];

    const autocompleteService = useMemo(
        () => new GooglePlacesAPI(process.env.REACT_APP_GOOGLE_API_KEY, lang === "fr" ? "fr" : "en"),
        [], // eslint-disable-line
    );

    const onUpdateSearch = useCallback(
        async (search) => {
            await autocompleteService.search(search).then((result: any) => updatePickerItems(result));
        },
        [autocompleteService],
    );

    const onChooseAddress = useCallback(
        async (address) => {
            await autocompleteService.placeDetails(address).then((result: any) => {
                const { formatted_address, geometry, address_components } = result;
                const city = address_components.find((item) => item.types.includes("locality"));
                onChoose({
                    address: formatted_address,
                    country: address_components.find((item) => item.types.includes("country")).long_name || "",
                    city: city ? city.long_name : "",
                    latitude: geometry.location.lat(),
                    longitude: geometry.location.lng(),
                });
            });
        },
        [autocompleteService, onChoose],
    );

    useEffect(() => {
        onUpdateSearch(searchText);
    }, [onUpdateSearch, searchText]);

    return (
        <List className={classes.list} style={{ top }}>
            {pickerItems ? (
                pickerItems.map((item) => {
                    return (
                        <ListItem
                            key={item.place_id}
                            className={classes.listItem}
                            button
                            onClick={() => onChooseAddress(item.place_id)}
                        >
                            <div className={classes.name}>{item.structured_formatting.main_text}</div>
                            <div className={classes.formatAddress}>{item.structured_formatting.secondary_text}</div>
                        </ListItem>
                    );
                })
            ) : (
                <ListItem>{I18n.t("noResults")}</ListItem>
            )}
            <ListItem className={classes.googleImg}>
                <img alt="powered by google" src={img} />
            </ListItem>
        </List>
    );
};

const useStyle = makeStyles({
    list: {
        position: "absolute",
        zIndex: 2,
        backgroundColor: "#FFFFFF",
        boxShadow: "0 3px 6px #00000085",
        borderRadius: "0.25rem",
        width: "100%",
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    name: {
        fontSize: "1rem",
    },
    formatAddress: {
        fontSize: "0.8rem",
        color: "#8f9192",
    },
    googleImg: { display: "flex", justifyContent: "flex-end" },
});

export default GooglePlacesSearch;

import React, { useState, useEffect, useMemo } from "react";
import { TkConference } from "@tkbel/common";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface ContextType {
    currentPlayingConference: TkConference;
    setCurrentPlayingConference: (val: TkConference) => void;
    currentConferenceId: string;
    setCurrentConferenceId: (val: string) => void;
    minimize?: boolean;
    setMinimize: (val: boolean) => void;
    audioFile: any;
    setAudioFile: (val: any) => void;
    leaveConference: () => void;
    playing: boolean;
    mute: boolean;
    setMute: (val: boolean) => void;
    currentTime: number;
    setCurrentTime: (val: number) => void;
    updateAudioCurrentTime: () => void;
    togglePlay: () => void;
    duration: number;
    setAudioTime: (number) => void;
}

const defaultValue: ContextType = {
    currentPlayingConference: null,
    setCurrentPlayingConference: null,
    currentConferenceId: null,
    setCurrentConferenceId: null,
    minimize: false,
    setMinimize: null,
    audioFile: null,
    setAudioFile: null,
    leaveConference: null,
    playing: false,
    mute: false,
    setMute: null,
    currentTime: 0,
    setCurrentTime: null,
    updateAudioCurrentTime: null,
    togglePlay: null,
    duration: null,
    setAudioTime: null,
};

const PlayingConferenceContext = React.createContext<ContextType>(defaultValue);

export const PlayingConferenceContextProvider: React.FC = (props) => {
    const [currentPlayingConference, setCurrentPlayingConference] = useState<any>(null);
    const [currentConferenceId, setCurrentConferenceId] = React.useState<string>(null);
    const { currentConference } = useSelector((state: RootState) => state.conference, shallowEqual);

    const [minimize, setMinimize] = useState(false);
    const [audioFile, setAudioFile] = useState(null);
    const [playing, setPlaying] = useState(true);
    const [mute, setMute] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);

    let recordingFile = useMemo(() => audioFile && window.URL.createObjectURL(audioFile), [audioFile]);
    let audio = useMemo(() => audioFile && new Audio(recordingFile), [recordingFile]); // eslint-disable-line
    const [duration, setDuration] = useState(0);
    useEffect(() => {
        if (!currentConferenceId) {
            setCurrentPlayingConference(null);
            return;
        }

        // switched to another conference -> maximize
        setMinimize(false);
        setPlaying(true);
        const unsubscribe = async () => {
            // console.debug("PlayConferenceContext: conf updated", snap.id, snap.data());
            setCurrentPlayingConference(currentConference);
            setCurrentConferenceId(currentConference.id);
        };
        return () => {
            unsubscribe();
        };
    }, [currentConference]); // eslint-disable-line

    useEffect(() => {
        if (recordingFile) {
            audio.play();
            audio.addEventListener("timeupdate", () => {
                setCurrentTime(audio.currentTime);
            });

            audio.addEventListener("ended", () => setPlaying(false));
            return () => {
                audio.removeEventListener("ended", () => setPlaying(false));
            };
        }
    }, [audio]); // eslint-disable-line

    useEffect(() => {
        if (audio) {
            audio.onloadedmetadata = function () {
                setDuration(audio.duration);
            };
        }
    }, [audioFile]); // eslint-disable-line

    useEffect(() => {
        if (audio) {
            mute ? (audio.volume = 0) : (audio.volume = 1);
        }
    }, [mute]); // eslint-disable-line

    const leaveConference = () => {
        if (audio) {
            audio.src = null;
            mute && setMute(false);
            setDuration(0);
            audio.pause();
            audio.load();
        }

        setCurrentConferenceId(null);
        setCurrentPlayingConference(null);
    };

    const setAudioTime = (value) => {
        audio.currentTime = audio.currentTime + value;
    };

    const updateAudioCurrentTime = () => {
        audio.currentTime = currentTime;
    };

    const togglePlay = () => {
        if (audio) {
            playing ? audio.pause() : audio.play();
            setPlaying(!playing);
        }
    };

    return (
        <PlayingConferenceContext.Provider
            value={{
                currentPlayingConference,
                setCurrentPlayingConference,
                currentConferenceId,
                setCurrentConferenceId,
                minimize,
                setMinimize,
                audioFile,
                setAudioFile,
                leaveConference,
                playing,
                mute,
                setMute,
                currentTime,
                setCurrentTime,
                updateAudioCurrentTime,
                togglePlay,
                duration: duration,
                setAudioTime,
            }}
        >
            {props.children}
        </PlayingConferenceContext.Provider>
    );
};
export const usePlayConferenceContext = () => React.useContext(PlayingConferenceContext);

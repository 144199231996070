import React from "react";
import { RouteChildrenProps } from "react-router";
import { makeStyles } from "@material-ui/styles";

import { PageWrapper, Video } from "../components";

export interface tokenData {
    token: string;
}

const CoursePage: React.FC<RouteChildrenProps> = (props) => {
    const classes = useStyles({});

    return (
        <PageWrapper {...props}>
            <div className={classes.content}>
                <Video />
            </div>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    content: {
        maxWidth: "1150px",
        padding: "60px 100px",
        borderRadius: "15px",
        boxShadow: "0 3px 30px rgba(0, 0, 0, 0.03)",
    },
    lastItem: {
        paddingBottom: 0,
    },
    title: {
        marginTop: "9rem",
        marginLeft: "18rem",
    },
    page: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fafafb",
    },
});

export default CoursePage;

import axios from "axios";

export const startRecording = (data) => {
    const recordData = { ...data, action: "start" };
    axios
        .post(`${process.env.REACT_APP_TKBEL_API}/conferences/record/`, { ...recordData })
        .then(() => {})
        .catch((error) => {
            console.error(error);
        });
};

export const stopRecording = (data) => {
    const recordData = { ...data, action: "stop" };
    axios
        .post(`${process.env.REACT_APP_TKBEL_API}/conferences/record/`, { ...recordData })
        .then(() => {})
        .catch((error) => {
            console.error(error);
        });
};

export const getRecording = (id, type = "audio") => {
    axios
        .get(`${process.env.REACT_APP_TKBEL_API}/recordings/${id}${type === "audio" ? ".mp3" : ".mp4"}/`)
        .then(() => {})
        .catch((error) => {
            console.error(error);
        });
};

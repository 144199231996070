import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { TkConference } from "@tkbel/common";
import { useDispatch, useSelector } from "react-redux";

import { PageWrapper, CourseSlide } from "../../components";
import { filter } from "../../redux/coursesReducer";
import { RootState } from "../../redux/store";
import SearchItem from "./searchItems";
import { onGetConferences } from "../../redux/conferenceReducer";

const Search = (props) => {
    const { filtratingCourses } = useSelector((state: RootState) => state.courses);
    const { conferences, categories } = useSelector((state: RootState) => state.conference);
    // const filtratingCourses = conferences;
    const classes = useStyles({});
    const searchParams = JSON.parse(localStorage.getItem("search"));
    const [searchOptions, setSearchOptions] = useState<string>("");
    const [filteringConf, setFilteringConf] = useState<any>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(onGetConferences());
    }, [dispatch]);

    useEffect(() => {
        const filters = JSON.parse(localStorage.getItem("search"));
        const countries = filters.country.filter((c) => c.value).map((c) => c.name);
        const languages = filters.language.filter((l) => l.value).map((l) => l.name);
        const categories = filters.category.filter((c) => c.value).map((c) => c.id);

        const filterData = conferences.filter((conf) => {
            return (
                countries.includes(conf.country) ||
                languages.includes(conf.language) ||
                categories.includes(conf.categoryId)
            );
        });
        setFilteringConf(filterData);
    }, [conferences]);

    const onUpdateSearchOptions = useCallback((options) => {
        const category = options.category.filter((item) => item.value).map((item) => item.name);
        const country = options.country.filter((item) => item.value).map((item) => item.name);
        const language = options.language.filter((item) => item.value).map((item) => item.name);

        return `${category.length ? `Categories: ${category.join(", ")}` : ""}`;
    }, []);

    useEffect(() => {
        setSearchOptions(onUpdateSearchOptions(searchParams));
    }, [searchParams]); // eslint-disable-line

    return (
        <PageWrapper {...props}>
            <div className={classes.wrapper}>
                <div className={classes.searchParams}>
                    Searched for:
                    <p>{searchOptions}</p>
                </div>
                <div className={classes.conferencesContainer}>
                    {filteringConf.map((item, index) => (
                        <CourseSlide slide={item} />
                    ))}
                </div>
            </div>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
    },
    searchParams: {
        fontSize: 18,
        background: "#ffffff",
        padding: "20px",
        width: "100%",
        textAlign: "left",
        borderRadius: 10,
        "$ > p": {
            whiteSpace: "pre",
        },
    },
    conferencesContainer: {
        display: "flex",
        padding: "0 20px",
        flexWrap: "wrap",
        "& > div": {
            width: "23%",
            margin: "10px 1%",
            height: "fit-content",
            "@media (max-width: 820px)": {
                width: "46%",
            },
            // eslint-disable-next-line
            ["@media (max-width: 480px)"]: {
                width: "100%",
            },
        },
    },
});

export default Search;

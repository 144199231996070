import React, { useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router";
import { IconButton } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

import Loader from "../components/Loader";
import PDFDexieDB from "../helpers/PDFDexieDB";
import BasePage from "../components/BasePage";

interface Props {
    id: string;
}

const PDFView: React.FC<RouteChildrenProps<Props>> = (props) => {
    const classes = useStyle({});
    const [pdfId] = useState(props.match.params.id);
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfName, setPdfName] = useState(null);
    const [fileSrc, setFileSrc] = useState(null);
    useEffect(() => {
        let db = new PDFDexieDB();
        db.pdf
            .get(pdfId)
            .then((result) => {
                if (result) {
                    setPdfFile(result.pdfFile);
                    setPdfName(result.pdfName ? result.pdfName : null);
                    setFileSrc(`${window.URL.createObjectURL(result.pdfFile)}#toolbar=0`);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, []); // eslint-disable-line

    return pdfFile && pdfName && fileSrc ? (
        <BasePage title={pdfName}>
            <div id="pdf" style={{ marginTop: "9rem" }}>
                <IconButton
                    style={{ float: "right", zIndex: 100 }}
                    onClick={() => {
                        (document.getElementById("pdf-frame") as HTMLIFrameElement).contentWindow.print();
                    }}
                >
                    <Print />
                </IconButton>
                <div className={classes.videoWrapper}>
                    <iframe
                        title="pdf"
                        className={classes.videoWrapper_iframe}
                        src={fileSrc}
                        itemType="application/pdf"
                        style={{ overflow: "auto" }}
                        id="pdf-frame"
                    />
                </div>
                {/* <embed id="pdf-frame" src={fileSrc} itemType="application/pdf" style={{ width: "100%", height: "52rem" }}></embed> */}
            </div>
        </BasePage>
    ) : (
        <Loader />
    );
};

const useStyle = makeStyles({
    videoWrapper: {
        position: "relative",
        paddingBottom: " 49rem",
        height: 0,
    },
    videoWrapper_iframe: {
        position: "absolute",
        top: "3rem",
        left: 0,
        width: " 100%",
        height: "100%",
    },
});
export default PDFView;

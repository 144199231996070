import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "@tkbel/common";
import { CourseSlide } from "../index";
import { onGetConferences } from "../../redux/conferenceReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Library = (props) => {
    const [pastConferences, setPastConferences] = useState([]);
    const { conferences } = useSelector((state: RootState) => state.conference);
    const dispatch = useDispatch();
    const classes = useStyles({});

    useEffect(() => {
        if (conferences.length) {
            const filterConf = conferences
                // @ts-ignore
                .filter((conf) => new Date(conf.endTime) <= Date.now() && conf.recordFiles)
                .sort((a: any, b: any) => new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf())
                .reverse();

            console.log("DISP", conferences, filterConf);
            setPastConferences(filterConf);
        }
    }, [conferences]);

    useEffect(() => {
        dispatch(onGetConferences());
    }, [dispatch]);

    return (
        <div className={classes.container}>
            <h1 className={classes.pageHeader}>{I18n.t("myLibrary")}</h1>
            {pastConferences.length ? (
                <div className={classes.conferencesContainer}>
                    {pastConferences.map((conference) => (
                        <CourseSlide slide={conference} history={props.history} />
                    ))}
                </div>
            ) : (
                <div className={classes.notFound}>{I18n.t("conferenceNotFound")}</div>
            )}
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        minHeight: "815px",
        width: "100%",
        boxShadow: "0px 3px 20px #00000008",
        margin: "22px auto 99px",
        borderRadius: "12px",
        backgroundColor: "#fff",
    },
    pageHeader: {
        marginTop: "36px",
        letterSpacing: "1.88px",
        color: "#1E2022",
        fontWeight: 400,
        fontSize: "30px",
    },
    library: {
        marginTop: "49px",
    },
    conferencesContainer: {
        display: "flex",
        padding: "0 20px",
        flexWrap: "wrap",
        "& > div": {
            width: "23%",
            margin: "10px 1%",
            "@media (max-width: 820px)": {
                width: "46%",
            },
            // eslint-disable-next-line
            ["@media (max-width: 480px)"]: {
                width: "100%",
            },
        },
    },
    notFound: {
        fontSize: 18,
        margin: "60px auto",
    },
});

export default Library;

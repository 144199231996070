import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    getUser,
    userLogin,
    userValidate,
    userLogout,
    updateUser,
    getUserProfile,
    joinToConference,
    muteUser,
    leftConference,
    addConferenceToWishlist,
    removeConferenceFromWishlist,
} from "../utils/api/user";
import { uploadMedia } from "../utils/api/upload";
import { onStopCall } from "./conferenceReducer";

const initialState = {
    id: "",
    isLogged: false,
    profile: {},
    speaker: false,
    language: "en",
    userRegister: false,
    oneTimePassword: false,
    isSpeaker: false,
    mediaUrl: "",
    validateNumber: 1111,
    mutedConference: false,
};

interface UserLogin {
    firstName: string;
    lastName: string;
    phone: string;
}

interface UserFields {
    userId: string;
    speaker: boolean;
    language: string;
    register: boolean;
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    money: number;
    agreedToToS: string;
    email: string;
    country: string;
    city: string;
    address: string;
    biography: string;
    profileImage: string;
    currentConferenceId: number;
    emailVerified: boolean;
    role: string;
    muted: boolean;
    disable: boolean;
    booksAndArticles: string;
    conferences: any;
    speakingConferences: any;
    library: any;
    wishList: any;
}

interface MediaFields {
    file: any;
    type: string;
    name?: string;
}

export const login = createAsyncThunk<object, UserLogin>("user/login", async (payload, { dispatch }) => {
    try {
        const user = await userLogin(payload);
        if (user) {
            dispatch(toggleValidation(true));
        }
    } catch (e) {}
});

export const logout = createAsyncThunk<object>("user/logout", async (payload, { dispatch }) => {
    try {
        await userLogout().then(() => dispatch(onLogout()));
    } catch (e) {}
});

export const validateUser = createAsyncThunk<object, UserLogin>("user/validate", async (payload, { dispatch }) => {
    try {
        const { phone } = payload;
        const data = await userValidate({ phone, validateNumber: initialState.validateNumber });
        if (data) {
            dispatch(setLogged(true));
            dispatch(toggleValidation(false));
        }
    } catch (e) {}
});

export const getProfile = createAsyncThunk<object, { id: string }>("user/getProfile", async (data) => {
    try {
        return await getUser(data.id).then((result: any) => result.data);
    } catch (e) {}
});

export const getMyProfile = createAsyncThunk<object>("user/getUserProfile", async (payload, { dispatch }) => {
    try {
        await getUserProfile()
            .then((result: any) => dispatch(setProfile(result.data.user)))
            .catch(() => dispatch(logout()));
    } catch (e) {}
});

export const updateUserRole = createAsyncThunk<object, UserFields>("user/updateRole", async (payload, { dispatch }) => {
    const { id, role } = payload;
    try {
        await updateUser(id, { role: role === "customer" ? "speaker" : "customer" });
        await dispatch(getMyProfile());
    } catch (error) {}
});

export const updateUserFields = createAsyncThunk<object, any>(
    "user/updateUserFields",
    async (payload, { dispatch }) => {
        const { id, ...fields } = payload;
        try {
            await updateUser(id, { ...fields }).then((result: any) => dispatch(setProfile(result.data)));
        } catch (error) {}
    },
);

export const changeLanguage = createAsyncThunk<object, UserFields>(
    "user/changeLanguage",
    async (payload, { dispatch }) => {
        const { language } = payload;
        dispatch(setLanguage(language));
    },
);

export const onUploadMedia = createAsyncThunk<object, MediaFields>("user/uploadMedia", async (payload) => {
    return await uploadMedia(payload).then((result: any) => result.data.url);
});

export const onJoinCurrentConference = createAsyncThunk<object, { id: number }>(
    "user/joinCurrentConference",
    async (payload, { dispatch }) => {
        const { id } = payload;
        try {
            await joinToConference({ conferenceId: id }).then((result: any) => {
                dispatch(setProfile(result.data));
            });
        } catch (error) {}
    },
);

export const onMuteConference = createAsyncThunk<object, { id: number }>(
    "user/muteConference",
    async (payload, { dispatch }) => {
        const { id } = payload;
        try {
            await muteUser({ conferenceId: id }).then((result: any) => {
                dispatch(setMutedConference(result.data));
            });
        } catch (error) {}
    },
);

export const onLeaveConference = createAsyncThunk<object>("user/leaveConference", async (payload, { dispatch }) => {
    try {
        await leftConference()
            .then((result: any) => dispatch(setProfile(result.data)))
            .then(() => dispatch(onStopCall()));
    } catch (error) {}
});

export const onAddToWishlist = createAsyncThunk<object, { id: number }>(
    "user/addToWishlist",
    async (id, { dispatch }) => {
        try {
            await addConferenceToWishlist({ conferenceId: id }).then((result: any) =>
                dispatch(setProfile(result.data)),
            );
        } catch (error) {}
    },
);

export const onRemoveFromWishlist = createAsyncThunk<object, { id: number }>(
    "user/removeFromWishlist",
    async (id, { dispatch }) => {
        try {
            await removeConferenceFromWishlist({ conferenceId: id }).then((result: any) =>
                dispatch(setProfile(result.data)),
            );
        } catch (error) {}
    },
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        toggleValidation(state, action: PayloadAction<boolean>) {
            state.oneTimePassword = action.payload;
        },
        setProfile(state, action: PayloadAction<object>) {
            state.profile = action.payload;
            state.isSpeaker = action.payload.role === "speaker";
        },
        setLogged(state, action: PayloadAction<boolean>) {
            state.isLogged = action.payload;
        },
        setRole(state, action: PayloadAction<boolean>) {
            state.speaker = action.payload;
        },
        onLogout(state) {
            state.profile = {};
            state.isLogged = false;
            state.userRegister = false;
        },
        setMediaUrl(state, action: PayloadAction<string>) {
            state.mediaUrl = action.payload;
        },
        setMutedConference(state, action: PayloadAction<boolean>) {
            state.mutedConference = action.payload;
        },
        addToWishlistLocal(state, action: PayloadAction<any>) {
            // if (state.profile.wishlist) {
            //     const newBody = state.profile;
            //     if (newBody.wishlist.find((item) => item.id === action.payload.id)) {
            //         newBody.wishlist = newBody.wishlist.filter((item) => item.id !== action.payload.id);
            //         state.profile = newBody;
            //         return;
            //     }
            //     newBody.wishlist = [...newBody.wishlist, action.payload];
            //     state.profile = newBody;
            // }
        },
        setLanguage(state, action: PayloadAction<string>) {
            state.language = action.payload;
        },
        setUserRegister(state, action: PayloadAction<boolean>) {
            state.userRegister = action.payload;
        },
    },
});

const {
    setProfile,
    setLogged,
    setRole,
    onLogout,
    addToWishlistLocal,
    setLanguage,
    setUserRegister,
    toggleValidation,
    setMediaUrl,
    setMutedConference,
} = userSlice.actions;

export {
    setProfile,
    setLogged,
    setRole,
    onLogout,
    addToWishlistLocal,
    setLanguage,
    setUserRegister,
    toggleValidation,
    setMediaUrl,
    setMutedConference,
};

export default userSlice.reducer;

import React, { useEffect, useState, useRef } from "react";
import SVG from "react-inlinesvg";
import { TkConference } from "@tkbel/common";
import { useDispatch, useSelector } from "react-redux";

import { SearchBar } from "../../index";
import { RootState } from "../../../redux/store";
import { searchSpeakers } from "../../../redux/searchReducer";
import { searchCourses } from "../../../redux/coursesReducer";
import LanguageSelector from "./../components/LanguageSelector";
import { SearchIcon, Arrows } from "../../../assets/images";
import HeaderMenu from "../components/HeaderMenu";

import * as classes from "../Header.module.scss";

interface HeaderTypes {}

const HeaderMobile: React.FC<HeaderTypes> = () => {
    const { filtratingCourses } = useSelector((state: RootState) => state.courses);
    const dispatch = useDispatch();
    const searchWrapper = useRef(null);
    const [searchInput, setSearchInput] = useState("");
    const { speakersFilter } = useSelector((state: RootState) => state.search); // eslint-disable-line
    const [mobileSearchActive, setMobileSearchActive] = useState(false);

    useEffect(() => {
        dispatch(searchCourses(searchInput));
        dispatch(searchSpeakers(searchInput));
    }, [searchInput, dispatch]);

    const handleToggleMobileSearch = () => {
        searchWrapper.current.classList.toggle(classes["search-active"]);
        setMobileSearchActive(!mobileSearchActive);
    };

    // @ts-ignore
    return (
        <div className={classes.wrapper}>
            <div className={classes.search} ref={searchWrapper} onClick={handleToggleMobileSearch}>
                {!mobileSearchActive ? <SVG src={SearchIcon} /> : <Arrows style={{ transform: "rotate(180deg)" }} />}
            </div>
            {mobileSearchActive && (
                <SearchBar
                    courses={filtratingCourses.map((item) => item.data() as TkConference)}
                    speakers={speakersFilter}
                    search={searchInput}
                    setSearch={setSearchInput}
                />
            )}
            {!mobileSearchActive && (
                <>
                    <LanguageSelector />
                    <HeaderMenu />
                </>
            )}
        </div>
    );
};

export default HeaderMobile;

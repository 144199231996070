import React, { useEffect, useState } from "react";
import { CategorySlide, PageWrapper, SearchBar, Slider, PageBanner } from "../components";
import { I18n } from "@tkbel/common";
import { makeStyles } from "@material-ui/styles";

import { linearBackgroud } from "../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { onGetCategories } from "../redux/conferenceReducer";
import { onGetAllSearchCategories } from "../redux/searchReducer";

const AllCategories = (props) => {
    const classes = useStyles({});
    const { categories } = useSelector((state: RootState) => state.conference);
    const [search, serSearch] = useState("");
    const allCategories = categories;
    const [currentCategories, setCurrentCategories] = useState<any[]>([]);
    const { searchCategories } = useSelector((state: RootState) => state.search);

    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentCategories(
            allCategories.filter((item) => {
                let name = item.title.toLowerCase();
                return name.includes(search.toLowerCase());
            }),
        );
    }, [search]); // eslint-disable-line

    useEffect(() => {
        dispatch(onGetCategories());
        dispatch(onGetAllSearchCategories({ search: search }));
    }, [search, dispatch]);

    return (
        <PageWrapper {...props}>
            <PageBanner title={I18n.t("searchForCategories")}>
                <>
                    <SearchBar
                        categories={searchCategories}
                        search={search}
                        setSearch={serSearch}
                        searchFor="category"
                    />
                </>
            </PageBanner>
            <Slider
                title={I18n.t("topCategories")}
                slides={categories}
                slideWrapper="category"
                breakpoints={{
                    1200: {
                        slidesPerView: 4,
                    },
                    991: {
                        slidesPerView: 3,
                    },
                    670: {
                        slidesPerView: 2,
                    },
                    420: {
                        slidesPerView: 2,
                    },
                    300: {
                        slidesPerView: 2,
                    },
                }}
                showImages
            />
            <div className={classes.allCategory}>
                <p>{I18n.t("allCategories")}</p>
                <div className={classes.allCategoryContent}>
                    {categories.map((item, index) => (
                        <CategorySlide key={index} slide={item} showImages={true} />
                    ))}
                </div>
            </div>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    lastItem: {
        paddingBottom: 0,
    },
    title: {
        marginTop: "9rem",
        marginLeft: "18rem",
    },
    page: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fafafb",
    },
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "1350px",
        padding: "36px",
        margin: "0 auto",
    },
    contentBanner: {
        backgroundColor: "#f0f0f8",
        borderRadius: "10px",
        padding: "60px 30px",
        width: "100%",
    },
    bannerContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "660px",
        margin: "0 auto",
    },
    bannerTitle: {
        color: "#222b45",
        fontSize: "35px",
        lineHeight: "41px",
        margin: "0 0 6px",
    },
    bannerSubtitle: {
        color: "#222b45",
        fontSize: "20px",
        lineHeight: "28px",
        margin: "0 0 6px",
    },
    searchBlock: {
        height: "50px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: "15px 0 0",
    },
    location: {
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        minWidth: "50px",
        height: "100%",
        margin: "0 0 0 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    filter: {
        background: linearBackgroud,
        borderRadius: "4px",
        minWidth: "50px",
        height: "100%",
        margin: "0 0 0 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    allCategory: {
        width: "100%",
        marginTop: "30px",
        "& p": {
            fontSize: "35px",
        },
    },
    allCategoryContent: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 287px)",
        marginTop: "30px",
        gap: "25px",
        // eslint-disable-next-line
        ["@media (max-width: 576px)"]: {
            gridTemplateColumns: "repeat(auto-fill, 46%)",
        },
    },
});

export default AllCategories;

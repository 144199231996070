import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSpeakers, getUser } from "../utils/api/user";

interface IPayload {
    id: string;
    errorCallback: () => void;
}

const initialState = {
    speaker: {},
    speakers: [],
};

export const speakerGet = createAsyncThunk<object, IPayload>("speaker/getSpeaker", async (payload, { dispatch }) => {
    const { id } = payload;

    try {
        await getUser(id).then((result: any) => dispatch(setSpeaker({ ...result.data, id })));
    } catch (e) {}
});

export const getAllSpeakers = createAsyncThunk<object>("user/getAllSpeakers", async (payload, { dispatch }) => {
    try {
        await getSpeakers("")
            .then((result: any) => dispatch(setSpeakers(result.data)))
            .catch(() => {});
    } catch (e) {}
});

const speakerSlice = createSlice({
    name: "speaker",
    initialState,
    reducers: {
        setSpeaker(state, action: PayloadAction<any>) {
            state.speaker = action.payload;
        },
        setSpeakers(state, action: PayloadAction<any>) {
            state.speakers = action.payload;
        },
    },
});

const { setSpeaker, setSpeakers } = speakerSlice.actions;

export { setSpeaker, setSpeakers };

export default speakerSlice.reducer;

import React from "react";
import { RouteProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import { I18n } from "@tkbel/common";

import { verylightGray, red, white } from "../helpers/colors";

import blackLogo from "../assets/images/black_logo.svg";
import validIcon from "../assets/images/valid_icon.svg";
import errorIcon from "../assets/images/error_Icon.svg";

const EmailValidated: React.FC<RouteProps> = (props) => {
    const params = new URLSearchParams(props.location.search);
    const email = params.get("email");
    const status = params.get("status") === "OK";
    const classes = useStyles({});
    return (
        <div className={classes.main}>
            <div className={classes.logo}>
                <SVG src={blackLogo} />
                <span className={classes.span}>{I18n.t("logoTitle")}</span>
            </div>
            {status ? (
                <>
                    <SVG src={validIcon} className={classes.check} />
                    <div className={classes.email}> {email}</div>
                    <div className={classes.validated}> {I18n.t("emailValidated")}</div>
                </>
            ) : (
                <>
                    <SVG src={errorIcon} className={classes.x} />
                    <div className={classes.error}> {I18n.t("errorValidating")}</div>
                </>
            )}
        </div>
    );
};
const useStyles = makeStyles({
    main: {
        backgroundColor: verylightGray,
        width: " 100%",
        height: "100%",
        textAlign: "center",
    },
    check: {
        marginTop: "15.3125rem",
        width: "7.06rem",
        height: "7.06rem",
    },
    x: {
        marginTop: "17.25rem",
        width: "14.0625rem",
        height: "14.0625em",
    },
    email: {
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: "1.875rem",
        marginTop: "1.875rem",
    },
    validated: {
        fontFamily: "Roboto",
        fontSize: "1.125rem",
        fontWeight: 300,
        marginTop: "0.5625rem",
    },
    error: {
        fontFamily: "Roboto",
        fontSize: "1.9375rem",
        fontWeight: 500,
        marginTop: "0.5625rem",
        color: red,
    },
    logo: {
        backgroundColor: white,
        display: "flex",
        padding: "1.5625rem",
        flex: 1,
        height: "4.6875rem",
    },
    span: {
        fontSize: "1rem",
        fontWeight: 300,
        marginLeft: "1.5rem",
    },
    img: {
        width: "10.33rem",
        height: "1.6875rem",
    },
});

export default EmailValidated;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TkUser } from "@tkbel/common";
import { getAllSearchConferences, getSpeakerSearchConferences } from "../utils/api/conference";
import { getSearchSpeakers } from "../utils/api/user";
import { getAllSearchCategories } from "../utils/api/category";

const initialState = {
    loading: false,
    speakers: [] as TkUser[],
    speakersFilter: [] as TkUser[],
    searchConferences: [],
    searchSpeakers: [],
    searchCategories: [],
};

export const searchInstructors = createAsyncThunk<object, {}>("search/searchInstructors", async () => {
    try {
        // const speakers = await getSpeakers();
        // dispatch(setSpeakers(speakers));
    } catch (error) {}
});

export const onGetSearchConferences = createAsyncThunk<object, { search: any }>(
    "conference/getAllSearchConferences",
    async (payload, { dispatch }) => {
        const { search } = payload;
        try {
            await getAllSearchConferences(search).then((result: any) => dispatch(setSearchConferences(result.data)));
        } catch (error) {}
    },
);

export const onGetSpeakerSearchConferences = createAsyncThunk<object, { speakerId: any; search: any }>(
    "conference/getSpeakerSearchConferences",
    async (payload, { dispatch }) => {
        const { speakerId, search } = payload;
        try {
            await getSpeakerSearchConferences(speakerId, search).then((result: any) =>
                dispatch(setSearchConferences(result.data.length ? result.data : result.data)),
            );
        } catch (error) {}
    },
);

export const onGetAllSearchSpeakers = createAsyncThunk<object, { search: any }>(
    "user/getAllSearchSpeakers",
    async (payload, { dispatch }) => {
        const { search } = payload;
        try {
            await getSearchSpeakers(search)
                .then((result: any) => dispatch(setSearchSpeakers(result.data)))
                .catch(() => {});
        } catch (e) {}
    },
);

export const onGetAllSearchCategories = createAsyncThunk<object, { search: any }>(
    "category/getAllSearchCategories",
    async (payload, { dispatch }) => {
        const { search } = payload;
        try {
            await getAllSearchCategories(search)
                .then((result: any) => dispatch(setSearchCategories(result.data)))
                .catch(() => {});
        } catch (e) {}
    },
);

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        searchSpeakers: (state, action: PayloadAction<string>) => {
            state.speakersFilter =
                state.speakers.length &&
                state.speakers.filter(
                    (item) =>
                        (item.firstName && item.firstName.toLowerCase().includes(action.payload.toLowerCase())) ||
                        (item.lastName && item.lastName.toLowerCase().includes(action.payload.toLocaleLowerCase())),
                );
        },
        setSearchConferences: (state, action: PayloadAction<any>) => {
            state.searchConferences = action.payload;
        },
        setSearchSpeakers: (state, action: PayloadAction<any>) => {
            state.searchSpeakers = action.payload;
        },
        setSearchCategories: (state, action: PayloadAction<any>) => {
            state.searchCategories = action.payload;
        },
    },
});

const { setLoading, searchSpeakers, setSearchConferences, setSearchSpeakers, setSearchCategories } =
    searchSlice.actions;

export { setLoading, searchSpeakers, setSearchConferences, setSearchSpeakers, setSearchCategories };

export default searchSlice.reducer;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";

import { PlayIcon, PlayNextIcon, PlayPrevIcon } from "../../assets/images";
import VideoPreview from "../../assets/images/videoPreview.jpg";

interface VideoTypes {}

const Video: React.FC<VideoTypes> = () => {
    const classes = useStyles({});

    return (
        <div className={classes.videoContainer}>
            <div className={classes.video}>
                <img src={VideoPreview} alt="" />
            </div>
            <div className={classes.controls}>
                <div className={classes.controlBtn}>
                    <SVG src={PlayPrevIcon} />
                </div>
                <div className={classes.playBtn}>
                    <SVG src={PlayIcon} />
                </div>
                <div className={classes.controlBtn}>
                    <SVG src={PlayNextIcon} />
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        margin: "35px 0",
        textAlign: "left",
    },
    videoContainer: {
        position: "relative",
    },
    video: {},
    controls: {
        position: "absolute",
        maxWidth: "470px",
        margin: "0 auto",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        left: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    controlBtn: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
        "& svg": {
            fill: "#ffffff",
        },
    },
    playBtn: {
        width: "65px",
        cursor: "pointer",
    },
});

export default Video;

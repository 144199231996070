import React from "react";
import { makeStyles } from "@material-ui/styles";

interface BannerProps {
    title: string;
    subtitle?: string;
    children?: React.ReactChild | React.ReactNode;
}

const PageBanner: React.FC<BannerProps> = (props) => {
    const classes = useStyle({});

    return (
        <div className={classes.contentBanner}>
            <div className={classes.bannerContainer}>
                <div className={classes.bannerTitle}>{props.title}</div>
                <div className={classes.bannerSubtitle}>{props.subtitle}</div>
                <div className={classes.searchBlock}>{props.children}</div>
            </div>
        </div>
    );
};
const useStyle = makeStyles({
    contentBanner: {
        backgroundColor: "#f0f0f8",
        borderRadius: "10px",
        padding: 20,
        width: "100%",
    },
    bannerContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "660px",
        margin: "0 auto",
    },
    bannerTitle: {
        color: "#222b45",
        fontSize: 26,
        lineHeight: "28px",
        margin: "0 0 6px",
    },
    bannerSubtitle: {
        color: "#222b45",
        fontSize: 16,
        lineHeight: "18px",
        margin: "0 0 6px",
    },
    searchBlock: {
        height: "50px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: "15px 0 0",
    },
});

export default PageBanner;

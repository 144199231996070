import React, { ChangeEvent, useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import { darken } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { Field, Form, Formik, useFormikContext, FormikProps } from "formik";
import { Moment } from "moment";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { I18n } from "@tkbel/common";

import { useAppDispatch } from "../redux/hooks";
import { PhotoApparat, UserAvatar } from "../assets/images";
import { PageWrapper } from "../components";
import FormikInput from "../components/FormikComponents/Input";
import PageCardWrapper from "../components/PageCardWrapper";
import { linearBackgroud, white } from "../helpers/colors";
import { RootState } from "../redux/store";
import Loader from "../components/Loader";
import { onUploadMedia, updateUserFields } from "../redux/userReducer";
import getUpload from "../utils/getUrl";

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    biography: string;
    booksAndArticles: string;
    titles: string;
    birthday: string;
    profileImage: string;
}

const ProfileDetail = (props) => {
    const { profile } = useSelector((state: RootState) => state.user);
    const [photo, setPhoto] = useState<string>("");
    const classes = useStyles({});
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const FormikContext = () => {
        const { setValues } = useFormikContext();
        useEffect(() => {
            if (profile) {
                setValues({
                    firstName: profile.firstName || "",
                    lastName: profile.lastName || "",
                    email: profile.email || "",
                    address: profile.address || "",
                    biography: profile.biography || "",
                    booksAndArticles: profile.booksAndArticles || "",
                    titles: profile.titles || "",
                    birthday: profile.birthday || new Date(),
                    profileImage: profile.profileImage || "",
                });
            }
        }, [profile]); // eslint-disable-line
        return null;
    };

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        birthday: "",
        biography: "",
        booksAndArticles: "",
        titles: "",
        profileImage: "",
    };

    const addPhoto = (e: ChangeEvent<HTMLInputElement>) => {
        const reader = new FileReader();
        const file = e.target.files;
        if (!file || !file[0]) return;
        reader.onloadend = async () => {
            await dispatch(onUploadMedia({ file: file[0], type: `user/${profile.id}` }))
                .unwrap()
                .then((data: any) => setPhoto(data));
        };

        reader.readAsDataURL(file[0]);
    };

    const submit = async (values: typeof initialValues) => {
        const profileImage = photo?.length > 0 ? photo : values.profileImage;
        const allValues = { ...values, profileImage, birthday: new Date(values.birthday).toISOString() };
        setLoading(true);

        await dispatch(updateUserFields({ id: profile.id, ...allValues }))
            .unwrap()
            .then(async () => {
                history.goBack();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <PageWrapper {...props}>
            <PageCardWrapper {...props}>
                <div className={classes.profileDetailsWrapper}>
                    <div className={classes.avatarWrapper}>
                        {photo ? (
                            <img src={getUpload(photo)} alt="avatar" />
                        ) : profile.profileImage !== "" && profile.profileImage !== null ? (
                            <img src={getUpload(profile.profileImage)} alt="avatar" />
                        ) : (
                            <UserAvatar />
                        )}
                        <label htmlFor="file">
                            <PhotoApparat />
                        </label>
                        <input onChange={addPhoto} id="file" type="file" />
                    </div>
                    <div className={classes.formWrapper}>
                        <Formik initialValues={initialValues} onSubmit={submit}>
                            {({ setFieldValue, values }: FormikProps<FormValues>) => (
                                <Form translate={""}>
                                    <div className={classes.nameWrapper}>
                                        <Field
                                            placeholder={I18n.t("Fname")}
                                            label={I18n.t("Fname")}
                                            name="firstName"
                                            component={FormikInput}
                                        />
                                        <Field
                                            placeholder={I18n.t("Lname")}
                                            label={I18n.t("Lname")}
                                            name="lastName"
                                            component={FormikInput}
                                        />
                                    </div>
                                    <Field
                                        placeholder={I18n.t("email")}
                                        label={I18n.t("email")}
                                        name="email"
                                        component={FormikInput}
                                    />
                                    <Field
                                        placeholder={I18n.t("adresse")}
                                        label={I18n.t("adresse")}
                                        name="address"
                                        component={FormikInput}
                                    />
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <div className={classes.birthday}>
                                            <label htmlFor="date-picker-inline">{I18n.t("birthday")}</label>
                                            <KeyboardDatePicker
                                                format="DD.MM.yyyy"
                                                margin="normal"
                                                id="date-picker-normal"
                                                value={values.birthday}
                                                onChange={(date: Moment) => setFieldValue("birthday", date)}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                    <Field
                                        placeholder={I18n.t("biography")}
                                        label={I18n.t("biography")}
                                        name="biography"
                                        component={FormikInput}
                                    />
                                    <Field
                                        placeholder={I18n.t("booksAndArticles")}
                                        label={I18n.t("booksAndArticles")}
                                        name="booksAndArticles"
                                        component={FormikInput}
                                    />
                                    <Field
                                        placeholder={I18n.t("titles")}
                                        label={I18n.t("titles")}
                                        name="titles"
                                        component={FormikInput}
                                    />
                                    <div className={classes.buttonWrapper}>
                                        <button onClick={() => history.goBack()} type="button">
                                            Cancel
                                        </button>
                                        <button type="submit">{loading ? <Loader /> : I18n.t("save")}</button>
                                    </div>
                                    <FormikContext />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </PageCardWrapper>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    profileDetailsWrapper: {
        width: "100%",
        padding: "30px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },

    avatarWrapper: {
        position: "relative",
        width: "250px",
        height: "250px",
        borderRadius: "50%",
        background: darken("#ffffff", 0),
        boxShadow: "0px 0px 18px 0px rgba(34, 60, 80, 0.1);",
        padding: "5px",

        "& svg": {
            width: "100%",
            height: "100%",
        },

        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "50%",
        },

        "& input": {
            visability: "hidden",
            width: "1px",
            height: "1px",
            opacity: 0,
        },

        "& label": {
            position: "absolute",
            bottom: "10px",
            right: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            background: linearBackgroud,
            borderRadius: "50%",

            "& svg": {
                width: "50%",
                height: "50%",

                "& path, & circle": {
                    fill: "#ffffff",
                },
            },
        },
    },

    formWrapper: {
        width: "100%",
        marginTop: "30px",
        "& input, & textarea": {
            border: `2px solid ${darken("#ffffff", 0.2)}`,
            padding: "5px 10px",
            borderRadius: "6px",
            width: "100%",
        },

        "& label": {
            marginTop: "10px",
        },
        "& form": {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
        },
    },

    nameWrapper: {
        display: "flex",
        width: "100%",
        gap: "10px",
        "-webkit-gap": "10px",
    },

    buttonWrapper: {
        width: "100%",
        display: "flex",
        gap: "10px",
        "-webkit-gap": "10px",
        marginTop: "20px",
        "& button": {
            width: "50%",
            height: "40px",
            background: linearBackgroud,
            borderRadius: "6px",
            border: "none",
            color: white,
            fontSize: "16px",
            fontWeight: 500,
            cursor: "pointer",
        },
    },
    birthday: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",

        "& div": {
            width: "100%",
            margin: "0",
        },

        "& label": {
            fontWeight: 600,
            margin: "0 0 5px 1px!important",
        },

        "& input": {
            padding: "10px",
        },
    },
});

export default ProfileDetail;

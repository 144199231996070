export class GooglePlacesAPI {
    private sessionToken?: string;
    private loadScript: Promise<void>;

    constructor(private googleApiKey: string, private language: string) {
        this.loadScript = new Promise<void>((resolve, reject) => {
            const ID = "tkbel-google-places";

            if ((window as any).google && (window as any).google.maps && (window as any).google.maps.places) {
                resolve();
            }

            const script: HTMLScriptElement = document.createElement("script");
            script.setAttribute("async", "");
            script.setAttribute("id", ID);
            script.onload = () => {
                resolve();
            };
            script.onerror = () => {
                reject();
            };
            script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
            document.head.appendChild(script);
        });
    }

    async search(text: string) {
        await this.loadScript;

        if (text.length < 3) {
            this.sessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
            return [];
        }
        const params: any = {
            input: text,
            sessionToken: this.sessionToken,
            language: this.language,
        };
        try {
            params.location = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (location) => {
                        resolve(
                            new (window as any).google.maps.LatLng(location.coords.latitude, location.coords.longitude),
                        );
                    },
                    (err) => reject(err),
                );
            });
            params.radius = 10000;
        } catch (err) {
            console.warn("no location", err);
        }

        let autocompleteService = new (window as any).google.maps.places.AutocompleteService();

        return new Promise<any[]>((resolve, reject) => {
            autocompleteService.getPlacePredictions(params, (predictions, status) => {
                if (status !== (window as any).google.maps.places.PlacesServiceStatus.OK) {
                    reject(status);
                } else {
                    resolve(predictions);
                }
            });
        });
    }

    async placeDetails(placeID: string) {
        await this.loadScript;
        const params = {
            placeId: placeID,
            sessionToken: this.sessionToken,
            fields: ["formatted_address", "geometry", "address_components"],
            language: this.language,
        };
        this.sessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();

        return new Promise((resolve, reject) => {
            const divElement = document.createElement("div");
            const service = new (window as any).google.maps.places.PlacesService(divElement);
            service.getDetails(params, (place, status) => {
                if (status === (window as any).google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject(status);
                }
            });
        });
    }
}

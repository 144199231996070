import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "@tkbel/common";

import { linearBackgroud } from "../helpers/colors";

// interface props {
//     title?: string;
// }

const SpeakerColoredHeader = (props) => {
    const classes = useStyle({});
    return <div className={classes.div}>{props.title ? props.title : I18n.t("speakerTitle")}</div>;
};

const useStyle = makeStyles({
    div: {
        width: "100%",
        height: "60px",
        backgroundImage: linearBackgroud,
        color: "white",
        fontSize: "16px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "4.625rem",
        zIndex: 3,
    },
});

export default SpeakerColoredHeader;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

import Loader from "../components/Loader";
import { PageWrapper } from "../components";
import { RootState } from "../redux/store";
import { CourseSlide } from "../components";

import { I18n } from "@tkbel/common";

const AllConferences = (props) => {
    const classes = useStyles({});
    const { conferences } = useSelector((state: RootState) => state.conference);
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [conferencesToRender, setConferencesToRender] = useState([]);
    const type = props.match.params.type;
    type[0].toUpperCase();
    const title = type === "Past" ? I18n.t("past") : I18n.t("futureOngoing");

    useEffect(() => {
        setConferencesToRender(conferences.filter((item) => item.status === type));
    }, [conferences]); // eslint-disable-line

    useEffect(() => {
        setLoading(false);
    }, [conferencesToRender]);

    return loading ? (
        <Loader />
    ) : (
        <PageWrapper {...props}>
            <div className={classes.container}>
                <h1 className={classes.pageHeader}>{title} conferences</h1>
                {conferences.length ? (
                    <div className={classes.conferencesContainer}>
                        {conferences.map((conference) => (
                            <CourseSlide slide={conference} history={props.history} />
                        ))}
                    </div>
                ) : (
                    <div>{I18n.t("conferenceNotFound")}</div>
                )}
            </div>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    wrapper: {
        backgroundColor: "white",
    },
    container: {
        minHeight: "815px",
        width: "100%",
        boxShadow: "0px 3px 20px #00000008",
        margin: "22px auto 99px",
        borderRadius: "12px",
        backgroundColor: "#fff",
    },
    pageHeader: {
        marginTop: "36px",
        letterSpacing: "1.88px",
        color: "#1E2022",
        fontWeight: 400,
        fontSize: "30px",
    },
    conferencesContainer: {
        display: "flex",
        padding: "0 20px",
        flexWrap: "wrap",
        "& > div": {
            width: "23%",
            margin: "10px 1%",
            "@media (max-width: 820px)": {
                width: "46%",
            },
            // eslint-disable-next-line
            ["@media (max-width: 480px)"]: {
                width: "100%",
            },
        },
    },
});

export default AllConferences;

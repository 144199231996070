import React from "react";
import { Modal, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const CustomCircularProgress = withStyles({
    root: {
        "&:focus": {
            outline: "none !important",
        },
    },
})(CircularProgress);

const Loader: React.FC<{
    background?: string;
}> = ({ background }) => {
    return (
        <Modal
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            BackdropProps={{ style: { backgroundColor: background ? background : "white" } }}
            disableAutoFocus={true}
            disableBackdropClick={true}
            open={true}
        >
            <CustomCircularProgress />
        </Modal>
    );
};

export default Loader;

import moment from "moment";

export const FUTURE = "Future";
export const ONGOING = "OnGoing";
export const PAST = "Past";

export const getStatus = (start, end) => {
    const currentTime = moment();
    return currentTime > end ? PAST : currentTime > start && currentTime < end ? ONGOING : FUTURE;
};

import React from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { veryLightGray, white, black, gray } from "../helpers/colors";

const SelectField: React.FC<{
    placeholder: boolean;
    placeholderText: string;
    menuItems: Array<any>;
    onChange: (val: string) => void;
    value: string;
    disabled?: boolean;
}> = (props) => {
    const classes = useStyle({});

    const renderItems = () => {
        return props.menuItems.map((item, i) => (
            <MenuItem key={`h-${i}`} value={item}>
                {item}
            </MenuItem>
        ));
    };

    return (
        <FormControl fullWidth variant="filled">
            <Select
                fullWidth
                className={classes.select}
                classes={{ root: classes.selectInput }}
                id="demo-simple-select-filled"
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => props.onChange(e.target.value as string)}
                value={props.value}
                style={{
                    color: props.placeholder ? gray : black,
                    backgroundColor: !props.disabled ? veryLightGray : white,
                }}
                disabled={props.disabled}
            >
                <MenuItem disabled value={props.placeholderText}>
                    {props.placeholderText}
                </MenuItem>
                {renderItems()}
            </Select>
        </FormControl>
    );
};

const useStyle = makeStyles({
    select: {
        // backgroundColor: "#FAFAFA !important",
        fontSize: "1rem",
        borderRadius: "0.25rem",
        height: "4rem",
        display: "flex",
        alignItems: "center",
        "&:focus": {
            outline: "none",
        },
        "&:hover": {
            backgroundColor: "#FAFAFA",
        },
        padding: " 0 !important",
    },
    selectInput: {
        padding: 0,
        "&:focus": {
            backgroundColor: "transparent",
        },
    },
});

export default SelectField;

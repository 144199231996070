import React from "react";
import { makeStyles } from "@material-ui/styles";

export const BaseButton = ({ children, type, className, onClick }) => {
    const classes = useStyles({});

    const buttonClassName = `${classes.baseButton} ${classes["button_" + type]} ${className}`;

    return (
        <div className={buttonClassName} onClick={onClick}>
            {children}
        </div>
    );
};

const useStyles = makeStyles({
    baseButton: {
        background: "white",
        color: "#404040",
        boxShadow: "0 4px 5px 1px #D6D8DA",
        borderRadius: "14px",
        padding: "14px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        transition: "all .3s linear",
        "&:active": {
            boxShadow: "none",
        },
        "& svg": {
            maxHeight: "40px",
            maxWidth: "40px",
        },
    },
    button_vertical: {
        flexDirection: "column",
        flexGrow: 1,
        width: "100%",
    },
    button_horizontal: {
        flexDirection: "row",
        width: "100%",
    },
});

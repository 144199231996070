import React from "react";
import { darken, makeStyles } from "@material-ui/core/styles";
import { white } from "../../../helpers/colors";
import { useHistory } from "react-router";

const CategoriesResult: React.FC<{
    categories: any[];
    hideResults: () => void;
}> = ({ categories, hideResults }) => {
    const classes = useStyles({});
    const history = useHistory();

    return (
        <>
            <span>Categories :</span>
            {categories.length > 0
                ? categories.map((category, index) => (
                      <div
                          key={index}
                          onClick={() => {
                              history.push(`/category/${category.id}`);
                              hideResults();
                          }}
                          className={classes.listItem}
                      >
                          <div className={classes.info}>
                              <div>
                                  <p className={classes.title}>{category.title}</p>
                              </div>
                          </div>
                      </div>
                  ))
                : ""}
        </>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        height: "100%",
    },
    grayWrapper: {
        border: "1px solid #eaebec",
        backgroundColor: "#fafafb",
        display: "flex",
        borderRadius: "4px",
        width: "100%",
        height: "100%",
        alignItems: "center",
        padding: "8px 15px",
        boxSizing: "border-box",
    },
    form: {
        display: "flex",
        alignItems: "center",
        flex: 1,
    },
    input: {
        fontSize: "15px",
        marginLeft: "20px",
        flex: 1,
    },
    cancelIcon: {
        marginLeft: "auto",
        cursor: "pointer",
    },
    autoComplite: {
        position: "relative",
        width: "100%",
        background: white,
        borderRadius: "0 0 12px 12px",
        zIndex: 999,
        boxShadow: "0px 0px 67px 0px rgba(34, 60, 80, 0.2)",
        textAlign: "left",
        padding: "10px 0 0 0",
        "& span": {
            fontSize: "18px",
            fontWeight: "600",
            marginLeft: "10px",
        },
    },
    listItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "10px 10px",
        textAlign: "left",
        cursor: "pointer",
        transition: "background .2s linear",

        "&:hover": {
            background: darken(white, 0.02),
        },
    },

    info: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    imgWrapper: {
        width: "50px",
        height: "50px",
        borderRadius: "12px",
        background: "gray",

        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            objectFit: "fill",
        },
    },
    title: {
        fontWeight: 600,
        fontSize: "16px",
    },
    descr: {
        color: "#9b9b9b",
        fontWeight: 600,
        fontSize: "14px",
        width: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    reviews: {
        display: "flex",
        alignItems: "center",
    },
});

export default CategoriesResult;

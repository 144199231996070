import React from "react";
import { findAll } from "highlight-words-core";
import { translateString } from "@tkbel/common";

import { red } from "../helpers/colors";

// Taken from:
// https://github.com/clauderic/react-native-highlight-words/blob/master/index.js
export default function HighlighteText({ searchWords, textToHighlight, style, ...props }) {
    const tmpTextToHighlight = translateString(textToHighlight);
    const tmpSearchWords = translateString(searchWords);

    const chunks =
        searchWords.length > 1
            ? findAll({
                  textToHighlight: tmpTextToHighlight,
                  searchWords: [tmpSearchWords],
                  autoEscape: false,
              })
            : null;

    return searchWords.length > 1 ? (
        <div className={style} {...props}>
            {chunks.map((chunk, index) => {
                const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);

                return !chunk.highlight ? (
                    text
                ) : (
                    <span key={index} style={{ color: red }}>
                        {text}
                    </span>
                );
            })}
        </div>
    ) : (
        <span className={style} {...props}>
            {textToHighlight}
        </span>
    );
}

import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router";
import { MenuItem, Menu, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { I18n } from "@tkbel/common";

import { RootState } from "../../../redux/store";
import { HeartIcon } from "../../../assets/images";
import getUpload from "../../../utils/getUrl";
import { logout, updateUserRole } from "../../../redux/userReducer";

import * as classes from "../Header.module.scss";

interface HeaderMenuTypes {}

const HeaderMenu: React.FC<HeaderMenuTypes> = () => {
    const { profile, isSpeaker } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const [profileMenu, setProfileMenu] = useState<null | HTMLElement>(null);
    const open = Boolean(profileMenu);

    const openAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenu(event.currentTarget);
    };

    const closeAccountMenu = () => {
        setProfileMenu(null);
    };

    const handleLogout = () => {
        dispatch(logout());
        history.push("/login");
    };

    const onUpdateUserRole = async () => {
        await dispatch(updateUserRole({ id: profile.id, role: profile.role }));
        history.push(!isSpeaker ? "/conferences" : "/home");
    };
    // useEffect(() => {
    //     if (profile) {
    //         dispatch(getProfile(profile));
    //     }
    // }, [profile]); // eslint-disable-line

    return (
        <div className={classes.rightSide}>
            {profile.role === "customer" ? (
                <Link to="/wishlist" className={classes.heart}>
                    <SVG src={HeartIcon} />
                </Link>
            ) : (
                ""
            )}
            {/*<div onClick={() => setShowNotifications(!showNotifications)} className={classes.notification}>*/}
            {/*    <SVG src={BellIcon} />*/}
            {/*    {showNotifications && <Notification close={() => setShowNotifications(false)} />}*/}
            {/*</div>*/}
            <div className={classes.user} onClick={(e) => openAccountMenu(e)}>
                <div className={classes.userAvatar}>
                    {profile.profileImage && <img src={getUpload(profile.profileImage)} alt="avatar" />}
                </div>
                <div className={classes.userName}>{`${profile.firstName || ""} ${profile.lastName || ""}`}</div>
            </div>
            <Menu
                anchorEl={profileMenu}
                open={open}
                onClose={closeAccountMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                classes={{ paper: classes.profileMenu }}
            >
                <MenuItem>
                    <Button
                        style={{
                            marginBottom: "10px",
                            fontSize: "14px",
                            backgroundColor: "#ffffff",
                        }}
                        variant="contained"
                        fullWidth
                        onClick={() => history.push("/profile/details")}
                    >
                        {I18n.t("editProfile")}
                    </Button>
                </MenuItem>
                {isSpeaker && (
                    <MenuItem>
                        <Button
                            style={{
                                marginBottom: "10px",
                                fontSize: "14px",
                                backgroundColor: "#ffffff",
                            }}
                            variant="contained"
                            fullWidth
                            onClick={() => history.push(`/speakerBoard/get/${profile.id}`)}
                        >
                            {I18n.t("speakerProfile")} Preview
                        </Button>
                    </MenuItem>
                )}
                <MenuItem>
                    <Button
                        style={{
                            marginBottom: "10px",
                            fontSize: "14px",
                            backgroundColor: "#ffffff",
                        }}
                        variant="contained"
                        fullWidth
                        onClick={onUpdateUserRole}
                    >
                        {isSpeaker ? I18n.t("switchToUser") : I18n.t("becomeSpeaker")}
                    </Button>
                </MenuItem>
                {/*<MenuItem>*/}
                {/*    <Button*/}
                {/*        style={{*/}
                {/*            marginBottom: "10px",*/}
                {/*            fontSize: "14px",*/}
                {/*        }}*/}
                {/*        variant="contained"*/}
                {/*        fullWidth*/}
                {/*        onClick={() => history.push("/settings")}*/}
                {/*    >*/}
                {/*        {I18n.t("settings")}*/}
                {/*    </Button>*/}
                {/*</MenuItem>*/}
                <MenuItem>
                    <Button
                        style={{
                            fontSize: "14px",
                            backgroundColor: "#ffffff",
                        }}
                        variant="contained"
                        fullWidth
                        onClick={handleLogout}
                    >
                        {I18n.t("logout")}
                    </Button>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default HeaderMenu;

import { makeStyles } from "@material-ui/styles";
import React from "react";
import { RouteChildrenProps } from "react-router";

const PageCardWrapper: React.FC<RouteChildrenProps> = (props) => {
    const classes = useStyles({});
    return <div className={classes.wrapper}>{props.children}</div>;
};

const useStyles = makeStyles({
    wrapper: {
        background: "#ffffff",
        borderRadius: "15px",
        boxShadow: "-2px -1px 27px 4px rgba(34, 60, 80, 0.1)",
        width: "100%",
    },
});

export default PageCardWrapper;

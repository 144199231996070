import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Rating } from "@material-ui/lab";
import { StarIcon } from "../../assets/images";
import { linearBackgroud, white } from "../../helpers/colors";
import { TkConference, TkUser } from "@tkbel/common";
import { CustomCircularProgress } from "../Loader";

interface IProps {
    reviewOn: "Course" | "Instructors";
    children: JSX.Element;
    textareaPlaceholder: string;
    title: string;
    ratingValue?: number;
    setRatingValue: React.Dispatch<React.SetStateAction<number>>;
    textarea: string;
    setTextarea: React.Dispatch<React.SetStateAction<string>>;
    course?: TkConference;
    instructor?: TkUser & { id: string };
    close: () => void;
    successCallback: () => void;
}

const ReviewCard = ({
    reviewOn,
    children,
    textareaPlaceholder,
    title,
    ratingValue,
    setRatingValue,
    textarea,
    setTextarea,
    close,
    successCallback,
}: IProps) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles({});

    const handleChangeTextarea = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextarea(event.target.value);
    };

    const handleChangeRatingChange = (event, newValue) => {
        setRatingValue(newValue);
    };

    const submit = async () => {
        setLoading(true);
        // course && (await addReview({ userId: id, courseId: course.id, rating: ratingValue, review: textarea }));
        // instructor &&
        //     (await addReviewForUser({ userId: id, speakerId: instructor.id, rating: ratingValue, review: textarea }));
        setLoading(false);
        close();
        successCallback();
    };

    return (
        <div className={classes.card}>
            <div className={classes.reviewOn}>{reviewOn}</div>
            <div className={classes.cardHeader}>{children}</div>
            <div className={classes.divider} />
            <div className={classes.titleWrap}>
                <div className={classes.title}>{title}</div>
            </div>
            <div className={classes.hint}>Your reviews help us to be better</div>
            <Rating
                value={ratingValue}
                onChange={handleChangeRatingChange}
                icon={<StarIcon width="36px" height="40px" />}
                classes={{ iconEmpty: classes.iconEmpty }}
            />
            <textarea
                className={classes.textarea}
                value={textarea}
                onChange={handleChangeTextarea}
                placeholder={textareaPlaceholder}
            />
            <button className={classes.submit} onClick={submit}>
                {loading ? <CustomCircularProgress /> : "Submit"}
            </button>
        </div>
    );
};

const useStyles = makeStyles({
    card: {
        width: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minHeight: "506px",
        background: white,
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px",
    },
    submit: {
        background: linearBackgroud,
        width: "408px",
        height: "45px",
        borderRadius: "6px",
        border: "none",
        color: white,
        marginTop: "10px",
        fontSize: "16px",
        cursor: "pointer",

        "& svg circle": {
            stroke: white,
        },
    },
    reviewOn: {
        height: "27px",
        fontSize: "17px",
        lineHeight: "21px",
        color: "#222B45",
        textAlign: "left",
    },
    cardHeader: {
        width: "408px",
        margin: "32px auto 0",
    },
    divider: {
        margin: "13px 0 12px",
        borderTop: "1px solid #EDF1F7",
    },
    titleWrap: {
        width: "330px",
        minHeight: "63px",
        margin: "54px auto 18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontSize: "25px",
        lineHeight: "27px",
        color: "#222B45",
    },
    hint: {
        width: "282px",
        minHeight: "27px",
        fontSize: "15px",
        lineHeight: "21px",
        color: "#8F9BB3",
        letterSpacing: "0",
        margin: "0 auto 61px",
    },
    iconEmpty: {
        filter: "invert(0%) sepia(1%) saturate(5%) hue-rotate(2deg) brightness(100%) contrast(220%)",
    },
    textarea: {
        display: "block",
        backgroundColor: "#F4F4F4",
        borderRadius: "8px",
        width: "408px",
        height: "94px",
        border: "none",
        outline: "none",
        margin: "51px auto 12px",
        resize: "none",
        padding: "14px 12px",
        color: "#222B45",
        fontSize: "15px",
        lineHeight: "20px",
        letterSpacing: 0,
    },
});

export default ReviewCard;

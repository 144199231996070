import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

import { white } from "../helpers/colors";
import { usePlayConferenceContext } from "../context/conferenceContext";
import { makeStyles } from "@material-ui/styles";

// interface TooltipProps {
//     children: React.ReactElement;
//     open: boolean;
//     value: number;
// }

// const SlideTooltip = withStyles((theme: Theme) => ({
//     tooltip: {
//         backgroundColor: "transparent",
//         color: white,
//         fontSize: "1.2rem",
//         fontWeight: 400,
//         marginTop: "0.5rem",
//     },
// }))(Tooltip);

const getTimeBySeconds = (fullSeconds: number) => {
    let sec_num = parseInt(fullSeconds + "", 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    let displayHours = hours + "";
    if (hours < 10) {
        displayHours = "0" + hours;
    }
    let displayMinuts = minutes + "";
    if (minutes < 10) {
        displayMinuts = "0" + minutes;
    }
    let displaySconds = seconds + "";
    if (seconds < 10) {
        displaySconds = "0" + seconds;
    }

    return displayHours + ":" + displayMinuts + ":" + displaySconds;
};

// const ValueLabelComponent = (props: TooltipProps) => {
//     const { children, open, value } = props;
//
//     const fullTime = getTimeBySeconds(value);
//     return (
//         <SlideTooltip open={open} enterTouchDelay={0} placement="bottom" title={fullTime || 0}>
//             {children}
//         </SlideTooltip>
//     );
// };

const PrettoSlider = withStyles({
    root: {
        color: "#fff",
        height: "0.3rem",
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        marginTop: -10,
        marginLeft: -12,
        "&:focus,&:hover,&$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: "0.3rem",
        borderRadius: 4,
    },
    rail: {
        height: "0.3rem",
        borderRadius: 4,
    },
})(Slider);

const AudioSlider: React.FC<{
    setTimeUpdated: (boolean) => void;
}> = ({ setTimeUpdated }) => {
    const classes = useStyles({});
    const playingConferenceContext = usePlayConferenceContext();

    return (
        <div className={classes.audioSlider}>
            <PrettoSlider
                aria-label="pretto slider"
                value={playingConferenceContext.currentTime}
                max={playingConferenceContext.duration}
                min={0}
                onChange={(event, value) => {
                    playingConferenceContext.setCurrentTime(value as number);
                    setTimeUpdated(true);
                }}
                // ValueLabelComponent={ValueLabelComponent}
                valueLabelDisplay="off"
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span className={classes.audioTime}>{getTimeBySeconds(playingConferenceContext.currentTime)}</span>
                <span className={classes.audioTime}>{getTimeBySeconds(playingConferenceContext.duration)}</span>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    audioSlider: {
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            marginLeft: "1rem",
        },
    },
    audioTime: {
        color: white,
        fontSize: "1.2rem",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            fontSize: "1rem",
        },
    },
});

export default AudioSlider;

import React, { useEffect, useRef, useState } from "react";
import { RouteChildrenProps } from "react-router";
import { makeStyles } from "@material-ui/styles";
import { Formik, Form, Field } from "formik";
import { ChangeEvent } from "react-transition-group/node_modules/@types/react";
import { I18n } from "@tkbel/common";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import { linearBackgroud, purple, red, reverseLinearBackgroud, white } from "../helpers/colors";
import FormikInput from "../components/FormikComponents/Input";
import { RootState } from "../redux/store";

import path from "../assets/images/logo.svg";
import { login, validateUser } from "../redux/userReducer";

const Login = (props: RouteChildrenProps) => {
    const classes = useStyles({});
    const { isLogged, userRegister, oneTimePassword } = useSelector((state: RootState) => state.user);
    const [sendCodeSuccess, setSendCodeSuccess] = useState(false); // eslint-disable-line
    const [allInputsChanged, setAllInputsChanged] = useState(false);
    const [phone, setPhone] = useState<string>(""); // eslint-disable-line
    const [userValues, updateUserValues] = useState<object>({});
    const [codeDig, setCodeDigit] = useState({
        dig1: "",
        dig2: "",
        dig3: "",
        dig4: "",
    });
    const inputWrapper = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (sendCodeSuccess && inputWrapper.current && inputWrapper.current.childNodes) {
            (inputWrapper.current.childNodes[0] as HTMLInputElement).focus();
        }
    }, [sendCodeSuccess, inputWrapper]);

    useEffect(() => {
        setAllInputsChanged(checkAll());
    }, [codeDig, setAllInputsChanged]); // eslint-disable-line

    useEffect(() => {
        if (allInputsChanged && phone) {
            dispatch(validateUser(userValues));
        }
    }, [allInputsChanged]); // eslint-disable-line

    const inputFocus = (idx: number) => {
        inputWrapper.current && (inputWrapper.current.childNodes[idx] as HTMLInputElement).focus();
    };

    const checkAll = () => {
        const startValue = Object.values(codeDig).length;
        const endValue = Object.values(codeDig).filter((item) => item).length;
        return startValue === endValue;
    };

    const codeInputs = [
        { id: 1, name: "dig1", callback: () => inputFocus(1) },
        { id: 2, name: "dig2", callback: () => inputFocus(2) },
        { id: 3, name: "dig3", callback: () => inputFocus(3) },
        { id: 4, name: "dig4", callback: () => {} },
    ];

    const onSignInSubmit = async ({ phone, firstName, lastName }) => {
        await dispatch(login({ phone, firstName, lastName })).then(() => {
            setPhone(phone);
            updateUserValues({ phone, firstName, lastName });
        });
    };

    useEffect(() => {
        if (isLogged && !userRegister) {
            props.history.replace("/");
        }
    }, []); // eslint-disable-line

    const inputKeyDownHandler = (e: KeyboardEvent, id: number) => {
        if (e.key === "Backspace") {
            codeDig[`dig${id}`]
                ? setCodeDigit((prev) => ({ ...prev, [`dig${id}`]: "" }))
                : setCodeDigit((prev) => ({ ...prev, [`dig${id - 1}`]: "" }));
            id > 1 && inputFocus(id - 2);
            setAllInputsChanged(false);
        }
    };

    const onChangeDigits = (e: ChangeEvent<HTMLInputElement>, item: any) => {
        e.persist();
        e.target.value && item.callback();
        setCodeDigit((prev) => ({
            ...prev,
            [item.name]: e.target.value,
        }));
    };

    const initialValues = {
        phone: "",
        firstName: "",
        lastName: "",
    };

    const validationSchema = yup.object({
        phone: yup
            .string()
            .test(
                "test-phone-validation",
                I18n.t("invalidNumber"),
                (value) => value && isValidPhoneNumber(value.replace(/\s/g, "")),
            )
            .required(I18n.t("invalidNumber")),
        firstName: yup.string().required(I18n.t("invalidFirstName")),
        lastName: yup.string().required(I18n.t("invalidName")),
    });

    return (
        <>
            <img className={classes.title} src={path} alt="tkbel" />
            <div className={classes.sliderWrapper}>
                <h2>{I18n.t("signIn")}</h2>
                <div className={classes.qrWrapper}>
                    <div className={classes.auth}>
                        <Formik
                            onSubmit={(values) => onSignInSubmit(values)}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {({ setFieldValue }) => (
                                <Form translate={""} className={classes.form}>
                                    <div className={classes.inputWrapper}>
                                        {oneTimePassword && phone ? (
                                            <>
                                                <h2>Write Code</h2>
                                                <div ref={inputWrapper}>
                                                    {codeInputs.map((item) => (
                                                        <input
                                                            className={classes.shortInput}
                                                            key={item.id}
                                                            value={codeDig[item.name]}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                onChangeDigits(e, item)
                                                            }
                                                            onKeyDown={(e: KeyboardEvent) =>
                                                                inputKeyDownHandler(e, item.id)
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Field
                                                    className={classes.input}
                                                    name="firstName"
                                                    placeholder={I18n.t("Fname")}
                                                    label={I18n.t("Fname")}
                                                    component={FormikInput}
                                                    onChange={(name) => setFieldValue("firstName", name.target.value)}
                                                />
                                                <Field
                                                    className={classes.input}
                                                    name="lastName"
                                                    placeholder={I18n.t("Lname")}
                                                    label={I18n.t("Lname")}
                                                    component={FormikInput}
                                                    onChange={(name) => setFieldValue("lastName", name.target.value)}
                                                />
                                                <Field
                                                    className={classes.input}
                                                    inputType="phone"
                                                    name="phone"
                                                    placeholder={I18n.t("phone")}
                                                    label={I18n.t("phone")}
                                                    component={FormikInput}
                                                    onChange={(phone) => setFieldValue("phone", phone)}
                                                />
                                            </>
                                        )}

                                        <div id="recaptcha-container" />
                                        {!sendCodeSuccess && (
                                            <button type="submit" className={classes.submitButton}>
                                                {I18n.t("signIn")}
                                            </button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

const useStyles = makeStyles({
    wrapper: {
        backgroundImage: linearBackgroud,
        position: "relative",
        height: "60%",
        display: "flex",
        alignItems: "flex-start",
    },
    list: {
        color: "#1D2226",
        fontSize: "1.5rem",
        paddingBottom: 0,
    },
    image: {
        width: "2.1rem",
        height: "1.9rem",
    },
    qrImage: {
        height: "18rem",
        width: "18rem",
    },
    body1: {
        fontSize: "1.5rem",
    },
    lastItem: {
        paddingBottom: 0,
    },
    title: {
        marginTop: "9rem",
        marginLeft: "18rem",
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            margin: "9rem auto 0",
        },
    },
    qrContainer: {
        margin: "5rem 7rem",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
    },
    sliderWrapper: {
        width: "100%",
        maxWidth: "1136px",
        position: "absolute",
        top: "22rem",
        left: "50%",
        transform: "translateX(-50%)",
        "& h2": {
            fontWeight: 500,
            marginBottom: "25px",
            color: white,
            textAlign: "center",
        },
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            fontSize: 12,
        },
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            top: "17rem",
        },
    },

    qrWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minHeight: "30.87rem",
        backgroundColor: "white",
        boxShadow: "0px 3px 36px #00000029",
        // display: "flex",
        padding: "30px 160px",
        // eslint-disable-next-line
        ["@media (max-width: 810px)"]: {
            padding: "30px 80px",
            alignItems: "unset",
            minHeight: "5rem",
        },
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            padding: "30px 40px",
            alignItems: "unset",
            minHeight: "18rem",
        },
    },

    toLoginButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px",
        padding: "0 5px 0 0",
    },

    submitButton: {
        color: "#ffffff",
        background: linearBackgroud,
        border: "none",
        width: "100%",
        height: "30px",
        borderRadius: "6px",
        cursor: "pointer",
        transition: ".2s linear",
        "&:hover": {
            background: reverseLinearBackgroud,
        },
    },

    input: {
        border: `2px solid ${purple}`,
        width: "100%",
        height: "40px",
        marginBottom: "10px",
        borderRadius: "6px",
        padding: "10px",

        "&:focus, &:active": {
            outline: "none",
        },

        "& .PhoneInputInput": {
            border: 0,
            outline: "none",
        },
    },

    shortInput: {
        border: `1px solid ${purple}`,
        width: "30px",
        height: "30px",
        margin: "0 5px 10px 0",
        borderRadius: "6px",
        padding: "10px",
        fontSize: "14px",

        "&:focus, &:active": {
            outline: "none",
        },
    },

    auth: {
        display: "flex",
        // flexDirection: 'column',
        alignItems: "center",
        width: "400px",
        height: "100%",
        // justifyContent: 'center',
        // alignItems: 'center',

        // '& > div': {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     width: '30%',
        //     fontWeight: 500,
        //     marginBottom: '25px'
        // }
    },

    linkToRegister: {
        color: red,
        margin: "5px 0 0 0!important",
        textDecoration: "underline",
        cursor: "pointer",
    },
    form: {
        width: "100%",
    },
    qr: {
        width: "300px",
    },
    inputWrapper: {
        "& h2": {
            color: "#000000",
        },
    },
});

export default Login;

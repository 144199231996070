import React, { useEffect } from "react";
import { darken, makeStyles } from "@material-ui/core";
import { RouteChildrenProps } from "react-router";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { PageWrapper } from "../components";
import PageCardWrapper from "../components/PageCardWrapper";
import { getProfile, updateUserRole } from "../redux/userReducer";
import { RootState } from "../redux/store";

import { Billing, EditProfile, Settings, SpeakerPage, UserAvatar } from "../assets/images";
import { linearBackgroud, white } from "../helpers/colors";

const Profile: React.FC<RouteChildrenProps> = (props) => {
    const { profile, isSpeaker } = useSelector((state: RootState) => state.user);
    const classes = useStyles({});
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getProfile({ uid: profile.id }));
    }, []); // eslint-disable-line

    const actionsForUser = [
        { id: 1, text: "Profile details", Icon: <EditProfile />, link: "/profile/details" },
        { id: 2, text: "Billing options", Icon: <Billing />, link: "/profile/billing" },
        { id: 3, text: "Settings", Icon: <Settings />, link: "/profile/settings" },
    ] as const;

    const actionsForSpeaker = [
        { id: 1, text: "Profile Details", Icon: <EditProfile />, link: "/profile/details" },
        { id: 2, text: "Billing options", Icon: <Billing />, link: "/profile/billing" },
        { id: 3, text: "Speaker board", Icon: <SpeakerPage />, link: `/speakerBoard/get/${profile.id}/` },
        { id: 4, text: "Settings", Icon: <Settings />, link: "/profile/settings" },
    ] as const;

    return (
        <PageWrapper {...props}>
            <PageCardWrapper {...props}>
                <div className={classes.profileWrapper}>
                    <div className={classes.profileHeader}>
                        <div className={classes.profileInfo}>
                            <div className={classes.user}>
                                <div className={classes.avatar}>
                                    {profile.profileImage && <img src={profile.profileImage} alt="user" />}
                                    {!profile.profileImage && <UserAvatar />}
                                </div>
                                <div className={classes.name}>
                                    <p>
                                        {profile.firstName} {profile.lastName}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.actions}>
                        {(isSpeaker ? actionsForSpeaker : actionsForUser).map((item) => (
                            <NavLink key={item.id} to={item.link}>
                                <div className={classes.actionItem}>
                                    {item.Icon}
                                    <p>{item.text}</p>
                                </div>
                            </NavLink>
                        ))}
                    </div>
                    {/* Сказали скрыть пока */}
                    {/* <div className={classes.referalsWrapper}>
                        <div>
                            <div>
                                <CircularProgressWithLabel procent={20} color="#7DE0A8" value={60} />
                                <p>Income</p>
                            </div>
                            <div>
                                <CircularProgressWithLabel procent={20} color="#7DE0A8" value={60} />
                                <p>Deposit Income</p>
                            </div>
                            <div>
                                <CircularProgressWithLabel procent={20} color="#7DE0A8" value={60} />
                                <p>Expense</p>
                            </div>
                        </div>
                        <button>Go to Referral program</button>
                    </div> */}
                    <button className={classes.switchBtn} onClick={() => history.push("/speakerBoard/edit")}>
                        Edit profile
                    </button>
                    <button
                        className={classes.switchBtn}
                        onClick={() => dispatch(updateUserRole(isSpeaker ? "customer" : "speaker"))}
                    >
                        Switch to {isSpeaker ? "user" : "speaker"}
                    </button>
                </div>
            </PageCardWrapper>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    profileWrapper: {
        padding: "25px 45px 20px 45px",
    },

    switchBtn: {
        width: "100%",
        height: "40px",
        background: linearBackgroud,
        borderRadius: "6px",
        border: "none",
        color: white,
        fontSize: "16px",
        fontWeight: 500,
        cursor: "pointer",
        marginTop: "10px",
    },

    profileHeader: {
        width: "700px",
    },

    profileInfo: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",

        "& button": {
            background: "#F1F1F1",
            border: "none",
            borderRadius: "6px",
            width: "110px",
            height: "35px",
            fontSize: "14px",
            fontWeight: "400",
            color: "#9B9CA3",
            cursor: "pointer",
            opacity: "1",
            transition: "opacity .2s linear",
            "&:hover": {
                opacity: ".8 ",
            },
        },
    },

    user: {
        display: "flex",
        flex: 1,
        "& div": {
            display: "flex",
            flexDirection: "column",
        },
    },

    name: {
        "& p": {
            fontSize: "28px",
            fontWeight: "200",
        },
    },

    avatar: {
        width: "95px",
        height: "95px",
        borderRadius: "12px",
        marginRight: "25px",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "fill",
            borderRadius: "6px",
        },
    },
    actions: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, 150px)",
        gap: "15px",
        marginTop: "40px",
    },
    actionItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "12px",
        height: "130px",
        cursor: "pointer",

        "& p": {
            fontSize: "14px",
            fontWeight: "500",
            marginTop: "10px",
        },

        "&:hover": {
            background: darken("#ffffff", 0.05),
        },
    },
    referalsWrapper: {
        width: "100%",
        padding: "40px 30px 20px 30px",
        boxShadow: "-2px -1px 27px 4px rgba(34, 60, 80, 0.08)",
        borderRadius: "18px",
        marginTop: "20px",
        "& > div": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",

            "& div": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                "& p": {
                    marginTop: "5px",
                    color: "#77838F",
                    fontSize: "14px",
                },
            },
        },

        "& button": {
            marginTop: "30px",
            width: "100%",
            height: "60px",
            background: "#EBEBEB",
            borderRadius: "12px",
            border: "none",
            fontSize: "18px",
            fontWeight: "400",
            cursor: "pointer",
        },
    },
});

export default Profile;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import PauseTwoToneIcon from "@material-ui/icons/PauseTwoTone";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { IconButton } from "@material-ui/core";

import { white } from "../helpers/colors";
import AudioSlider from "./AudioSlider";
import BeforeIcon from "../images/Icon_30_sec_before.svg";
import AfterIcon from "../images/icon_30_sec_after.svg";
import { usePlayConferenceContext } from "../context/conferenceContext";

const AudioTimeLine: React.FC = () => {
    const classes = useStyle({});

    const [currenTimeUpdated, setCurrentTimeUpdated] = useState(false);

    const playingConferenceContext = usePlayConferenceContext();

    useEffect(() => {
        if (currenTimeUpdated) {
            playingConferenceContext.updateAudioCurrentTime();
            setCurrentTimeUpdated(false);
        }
    }, [currenTimeUpdated]); // eslint-disable-line

    return (
        <div style={{ marginTop: "1rem", flex: 1 }}>
            <AudioSlider setTimeUpdated={setCurrentTimeUpdated} />
            {!playingConferenceContext.minimize && (
                <div className={classes.buttonsWraper}>
                    <IconButton onClick={() => playingConferenceContext.setAudioTime(-30)}>
                        <img alt="before" className={classes.posButton} src={BeforeIcon} />
                    </IconButton>
                    <IconButton onClick={playingConferenceContext.togglePlay} className={classes.statusWraper}>
                        {playingConferenceContext.playing ? (
                            <PauseTwoToneIcon className={classes.statusButton} />
                        ) : (
                            <PlayArrowIcon className={classes.statusButton} />
                        )}
                    </IconButton>
                    <IconButton onClick={() => playingConferenceContext.setAudioTime(30)}>
                        <img alt="after" className={classes.posButton} src={AfterIcon} />
                    </IconButton>
                </div>
            )}
        </div>
    );
};
const useStyle = makeStyles({
    buttonsWraper: {
        marginTop: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    posButton: {
        width: "3.45rem",
        height: "3.45rem",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            width: "2rem",
            height: "2rem",
        },
    },
    statusWraper: {
        marginRight: "4.8rem",
        marginLeft: "4.8rem",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            marginRight: "2.5rem",
            marginLeft: "2.5rem",
        },
    },
    statusButton: {
        width: "3.45rem",
        height: "3.45rem",
        color: white,
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            width: "2rem",
            height: "2rem",
        },
    },
});

export default AudioTimeLine;

import React from "react";
import ReactDOM from "react-dom";
import "typeface-roboto";
import { I18n } from "@tkbel/common";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@material-ui/core";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";
import theme from "./theme";

import "./index.scss";

ReactDOM.render(
    <Provider store={store}>
        <Helmet>
            <title>{I18n.t("webTitle")}</title>
            <meta name="description" content={I18n.t("webDescription")} />
        </Helmet>
        <CssBaseline />
        <ThemeProvider theme={theme}>
            <div className="App">
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </div>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { I18n } from "@tkbel/common";

export const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = Math.floor(Math.log(Number(bytes)) / Math.log(1024));
    return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + " " + sizes[i];
};
export const getPrice = (price) => {
    return !price || price === "" || price === "0" ? I18n.t("free") : price;
};

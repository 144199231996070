import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress, Modal } from "@material-ui/core";
import moment from "moment";
import { Prompt, RouteChildrenProps, useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { deleteConference, getConference, I18n, TkConference } from "@tkbel/common";

import { PageWrapper } from "../components";
import { black, darkBackdrop, midLightGray, pink, red } from "../helpers/colors";
import ConferenceDetails from "./conferenceDetails";
import Loader from "../components/Loader";
import MessageModal from "../components/MessageModal";
import { onCreateConference, onUpdateConference } from "../redux/conferenceReducer";
import EditForm from "../components/ConferenceEditForm";

interface MatchParams {
    id: string;
}

const AddOrEditConf: React.FC<RouteChildrenProps<MatchParams>> = (props) => {
    const [conference, setConference] = useState<TkConference>();
    const [date, setDate] = useState(null);
    const [dateSelected, setDateSelected] = useState(false);
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [goBack, setGoBack] = useState(false);
    const [preview, setPreview] = useState(false);
    const [id, setId] = useState();
    const [error, setError] = useState<boolean>(false); // eslint-disable-line
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [saving, SetSaving] = useState(false);
    const [progress, setProgress] = useState(0);
    const currentFile = useRef(0);
    const totalFiles = useRef(0);
    const [doneSavingFiles, setDoneSavingFiles] = useState(false);
    const canEditAll = useRef(true);
    const dispatch = useDispatch();
    const history = useHistory();

    const classes = useStyle({});

    useEffect(() => {
        if (goBack === true) {
            canEditAll.current ? props.history.replace("/conferences") : props.history.replace("/conferences/past");
        }
    }, [goBack]); // eslint-disable-line

    const createOrUpdateConf = (conf: any) => {
        return dispatch(id ? onUpdateConference({ id, conference: conf }) : onCreateConference(conf));
    };

    const goToPreview = useCallback((conference: any) => {
        setConference(conference);
        setPreview(true);
    }, []);

    const getPercent = () => {
        return Math.round(progress);
    };

    const getMessage = () => {
        if (currentFile.current < totalFiles.current && !doneSavingFiles) {
            return `${I18n.t("file")} ${currentFile.current + 1}/${totalFiles.current}`;
        } else if (doneSavingFiles) {
            return I18n.t("savingConf");
        }
    };

    const saveConference = async () => {
        SetSaving(true);
        setDoneSavingFiles(false);
        setProgress(0);
        currentFile.current = 0;
        totalFiles.current = 0;

        try {
            await createOrUpdateConf(conference);
            // setProgress(100);
            setGoBack(true);
        } catch (e) {
            console.warn("cannot save conference", e);
            alert("Internal Error : Error occurred while saving conference");
        } finally {
            SetSaving(false);
        }
    };

    useEffect(() => {
        if (!dateSelected) return;

        const fiveMinutesAgo = moment(new Date()).subtract(5, "minutes");
        if (moment(date) && moment(date).isBefore(fiveMinutesAgo)) {
            setError(true);
            setDate(new Date());
            setTimeout(() => setError(false), 2500);
            // return;
        }
        setDateSelected(false);
    }, [date, dateSelected]);

    const deleteConf = async (id) => {
        const conference = (await getConference(id).get()).data() as TkConference;
        // conference.presentationImages && conference.presentationImages.forEach(removeImage);
        // conference.pdfs &&
        //     conference.pdfs
        //         .map((item) => {
        //             return item.path;
        //         })
        //         .forEach(deleteFile);
        await deleteConference(id);
    };

    useEffect(() => {
        if (history.location.state && history.location.state.conference) {
            setConference(history.location.state.conference);
            setId(history.location.state.conference.id);
        }
    }, [history.location.state]);

    return (
        <>
            {preview ? (
                <ConferenceDetails
                    closeModal={() => setPreview(false)}
                    saveConf={saveConference}
                    previewConference={conference}
                    createMode
                />
            ) : (
                <PageWrapper {...props}>
                    <Prompt
                        message={() => {
                            if (goBack) return true;
                            if (preview) {
                                setPreview(false);
                                return false;
                            }
                            return I18n.t("leaveEditConf");
                        }}
                    />

                    <EditForm conference={conference} goToPreview={goToPreview} />

                    {loading && <Loader background={"rgba(0, 0, 0, 0.52)"} />}
                    {saving && (
                        <Modal className={classes.modal} open={true} disableBackdropClick>
                            <div className={classes.wrapperDiv}>
                                <span className={classes.pub}>{I18n.t("publishConf")}</span>
                                <span className={classes.upload}>{getMessage()}</span>
                                <LinearProgress
                                    value={progress}
                                    variant={doneSavingFiles ? "indeterminate" : "determinate"}
                                    classes={{
                                        colorPrimary: classes.prime,
                                        barColorPrimary: classes.barPrime,
                                        bar: classes.bar,
                                    }}
                                />
                                <span className={classes.percent}>{`${getPercent()}%`}</span>
                            </div>
                        </Modal>
                    )}
                    {id && (
                        <MessageModal
                            open={showDeletePopup}
                            message={I18n.t("deleteMessage")}
                            confirmButton={I18n.t("delete")}
                            onCancel={() => setShowDeletePopup(false)}
                            onConfirm={() => {
                                deleteConf(id);
                                setGoBack(true);
                            }}
                        />
                    )}
                </PageWrapper>
            )}
        </>
    );
};

const useStyle = makeStyles({
    detailsContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
        width: "100%",
    },
    confDetails: {
        height: "102%",
        "&:focus": {
            outline: "none !important",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: darkBackdrop,
    },
    wrapperDiv: {
        backgroundColor: "white",
        width: "90%",
        height: "34%%",
        padding: "3.125rem 2.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "1rem",
        "&:focus": {
            outline: "none !important",
        },
    },
    pub: {
        color: black,
        fontWeight: 500,
        fontFamily: "Roboto",
        fontSize: "2.8125rem",
        textAlign: "center",
        whiteSpace: "pre-line",
    },
    prime: {
        backgroundColor: pink,
    },
    barPrime: {
        backgroundColor: red,
    },
    upload: {
        margin: "1.875rem 0 0.68rem 0",
        color: black,
        fontWeight: 500,
        fontFamily: "Roboto",
        fontSize: "1.68rem",
    },
    bar: {
        width: "90%",
        height: "0.25rem",
    },
    percent: {
        textAlign: "left",
        alignSelf: "flex-start",
        color: midLightGray,
        fontSize: "1.4375rem",
        margin: "0.5rem 0 0 0",
    },
});

export default AddOrEditConf;

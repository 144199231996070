import React, { useEffect, useState } from "react";
import { I18n, userCanTalk } from "@tkbel/common";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { Clear } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { Modal } from "@material-ui/core";

import { linearBackgroud, white, green } from "../helpers/colors";
import Loader from "../components/Loader";
import BasePage from "../components/BasePage";
// import AudioDexieDB from "../helpers/audioDexieDB";
import { getFormatDD } from "../helpers/dateFormat";
import AudioTimeLine from "../components/AudioTimeLine";
import Stream from "../components/Stream";
import { RootState } from "../redux/store";
import { setMinimize } from "../redux/conferenceReducer";
import {
    ChecklistIcon,
    CloseIcon,
    FolderIcon,
    InviteIcon,
    MicIcon,
    NotaryIcon,
    ScheduleIcon,
    StartCallIcon,
    SyncIcon,
    TranslationIcon,
} from "../assets/images";
import { onLeaveConference, onMuteConference } from "../redux/userReducer";
import getUpload from "../utils/getUrl";
import Chat from "../chat";

import IconSoundOn from "../images/icon_soustraction.svg";
import IconSoundOff from "../images/icon_soustraction_off.svg";
import IconBack from "../images/icon_back.svg";
import IconExpand from "../images/expand_icon.svg";
import { BaseButton } from "../components/Buttons/BaseButton";
import { HorizontalButton } from "../components/Buttons/HorizontalButton";

const PlayAudio: React.FC = () => {
    const classes = useStyles({});
    const { profile, mutedConference } = useSelector((state: RootState) => state.user);
    const { minimizeScreen } = useSelector((state: RootState) => state.conference);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [showImageModal, setShowImageModal] = useState(false);
    const [currentConference, setCurrentConference] = useState<any>({});

    useEffect(() => {
        if (!minimizeScreen) {
            const handler = (event: PopStateEvent) => {
                dispatch(setMinimize(true));
                event.preventDefault();
            };
            window.addEventListener("popstate", handler);

            return () => {
                window.removeEventListener("popstate", handler);
            };
        }
    }, [minimizeScreen]); // eslint-disable-line

    // useEffect(() => {
    //     if (session) {
    //         if (userCanTalk(playingConferenceContext.currentPlayingConference, profile.id)) {
    //             session.unmute({ video: true, audio: true });
    //         } else {
    //             session.mute({ video: true, audio: true });
    //         }
    //     }
    // }, [speechPermission, session, playingConferenceContext, profile]);

    // useEffect(() => {
    //     const conferenceId = currentConference.if;
    //
    //     let db = new AudioDexieDB();
    //     db.audio
    //         .get(conferenceId)
    //         .then((result) => {
    //             result && playingConferenceContext.setAudioFile(result.audioFile);
    //             setLoading(false);
    //         })
    //         .catch((e) => {
    //             console.error(e);
    //             setLoading(false);
    //         });
    // }, [currentConference]); // eslint-disable-line

    const volumeButtons = () => {
        return (
            <div style={{ cursor: "pointer" }}>
                <img
                    alt="soustraction"
                    src={mutedConference ? IconSoundOff : IconSoundOn}
                    className={minimizeScreen ? classes.minimizeVolumeButton : classes.volumeButton}
                    onClick={() => dispatch(onMuteConference(currentConference.id))}
                />
            </div>
        );
    };

    useEffect(() => {
        if (profile.currentConference) {
            setCurrentConference(profile.currentConference);
            setLoading(false);
        }
    }, [profile]);

    useEffect(() => {
        if (currentConference && currentConference.currPresentationImage) setShowImageModal(true);
    }, [currentConference]);

    const PresentationImageModal = ({ image }) => {
        return (
            <Modal className={classes.modal} onClose={() => {}} open={true}>
                <div className={classes.wrapperDiv}>
                    <SVG className={classes.closeModalBtn} onClick={() => setShowImageModal(false)} src={CloseIcon} />
                    <div className={classes.modalImage}>
                        <img src={image} alt="conferenceImage" />
                    </div>
                </div>
            </Modal>
        );
    };

    return (
        <>
            {!minimizeScreen ? (
                <div style={{ position: "fixed", top: "0rem", width: "100%", height: "100%", zIndex: 100 }}>
                    <BasePage>
                        {loading ? (
                            <Loader />
                        ) : (
                            currentConference && (
                                <div className={classes.content}>
                                    {showImageModal ? (
                                        currentConference.currPresentationImage ? (
                                            <PresentationImageModal image={currentConference.currPresentationImage} />
                                        ) : (
                                            setShowImageModal(false)
                                        )
                                    ) : null}
                                    <img
                                        alt="back"
                                        src={IconBack}
                                        className={classes.iconBack}
                                        onClick={() => dispatch(setMinimize(!minimizeScreen))}
                                    />

                                    <div className={classes.wrapper}>
                                        {/*<div className={classes.header}>*/}
                                        {/*    <div className={classes.confDetails}>*/}
                                        {/*        <img*/}
                                        {/*            alt="posterImage"*/}
                                        {/*            className={classes.posterImage}*/}
                                        {/*            src={getUpload(currentConference.posterImage)}*/}
                                        {/*        />*/}
                                        {/*        <div className={classes.confSubDetails}>*/}
                                        {/*            <span className={classes.title}>{currentConference.title}</span>*/}
                                        {/*            <span className={classes.speaker}>*/}
                                        {/*                {currentConference.speakerId}*/}
                                        {/*            </span>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    {volumeButtons()}*/}
                                        {/*</div>*/}
                                        <div className={classes.mainWrapper}>
                                            <div className={classes.main}>
                                                {/*{playingConferenceContext.audioFile && (*/}
                                                {/*    <div>*/}
                                                {/*        <AudioTimeLine />*/}
                                                {/*    </div>*/}
                                                {/*)}*/}

                                                {currentConference && (
                                                    <Stream
                                                        conference={currentConference}
                                                        setShowEndPopup={() => {
                                                            dispatch(onLeaveConference());
                                                        }}
                                                        // onSetStream={() => join(currentConference.id)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className={classes.footer}>*/}
                                    {/*    <span className={classes.confDate}>*/}
                                    {/*        {`${I18n.t("listeningTo")} ${moment(currentConference.startTime).format(*/}
                                    {/*            getFormatDD(),*/}
                                    {/*        )}`}*/}
                                    {/*    </span>*/}

                                    {/*    <div className={classes.speechBtns}>*/}
                                    {/*        {userCanTalk(currentConference, profile.id) ? (*/}
                                    {/*            <div onClick={() => {}} className={classes.currentSpeaker}>*/}
                                    {/*                <div className={classes.speaker}>*/}
                                    {/*                    <SVG*/}
                                    {/*                        src={MicIcon}*/}
                                    {/*                        style={{ fill: green, width: 20, height: 30 }}*/}
                                    {/*                    />*/}
                                    {/*                </div>*/}
                                    {/*                <div className={classes.pointText}>*/}
                                    {/*                    {I18n.t("youHavePermission")}*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        ) : currentConference.speechRequests &&*/}
                                    {/*          currentConference.speechRequests.includes(profile.id) ? (*/}
                                    {/*            <div onClick={() => {}} className={classes.currentSpeaker}>*/}
                                    {/*                <div className={classes.speaker}>*/}
                                    {/*                    <SVG*/}
                                    {/*                        src={MicIcon}*/}
                                    {/*                        style={{ fill: white, width: 20, height: 30 }}*/}
                                    {/*                    />*/}
                                    {/*                </div>*/}
                                    {/*                <div className={classes.pointText}>{I18n.t("cancelRequest")}</div>*/}
                                    {/*            </div>*/}
                                    {/*        ) : !currentConference.isPaused ? (*/}
                                    {/*            <div onClick={() => {}} className={classes.point}>*/}
                                    {/*                <SVG src={MicIcon} style={{ width: 20, height: 30 }} />*/}
                                    {/*                <div className={classes.pointText}>{I18n.t("pointOut")}</div>*/}
                                    {/*            </div>*/}
                                    {/*        ) : null}*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/* <Chat currentConference={currentConference} />*/}
                                </div>
                            )
                        )}
                    </BasePage>
                </div>
            ) : (
                currentConference && (
                    <div className={classes.minimizeBackground}>
                        <Clear className={classes.exitIcon} onClick={() => dispatch(onLeaveConference())} />
                        <div className={classes.minimizeContent}>
                            <div className={classes.header}>
                                <div className={classes.confDetails}>
                                    <img
                                        alt="posterImage"
                                        className={classes.posterImage}
                                        src={getUpload(currentConference.posterImage)}
                                    />
                                    <div className={classes.confSubDetails}>
                                        <span className={classes.title}>{currentConference.title}</span>
                                        <span className={classes.speaker}>{currentConference.speakerId}</span>
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>{volumeButtons()}</div>
                            </div>
                            <div className={classes.audioPlayDiv}>
                                <AudioTimeLine />
                                {currentConference && (
                                    <Stream
                                        mode={"lite"}
                                        conference={currentConference}
                                        // onSetStream={() => join(currentConference.id)}
                                    />
                                )}
                                <img
                                    alt="exand"
                                    src={IconExpand}
                                    className={classes.fullScreenImg}
                                    onClick={() => {
                                        dispatch(setMinimize(false));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
};

const useStyles = makeStyles({
    content: {
        // backgroundImage: linearBackgroud,
        backgroundColor: "#F5F7F9",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "5.75rem",
        // eslint-disable-next-line
        ["@media (max-width: 820px)"]: {
            paddingTop: "1rem",
            position: "relative",
            zIndex: "999",
        },
    },
    minimizeBackground: {
        backgroundImage: linearBackgroud,
        height: "auto",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 99,
    },
    minimizeContent: {
        flexDirection: "column",
        display: "flex",
        padding: "2rem  0rem 0 3.4rem",
        // eslint-disable-next-line
        ["@media (max-width: 578px)"]: {
            padding: "1rem  0rem 0 1rem",
        },
    },
    iconBack: {
        cursor: "pointer",
        alignSelf: "flex-start",
        paddingLeft: "1.9rem",
        zIndex: 4,
        // eslint-disable-next-line
        ["@media (max-width: 820px)"]: {
            paddingLeft: "1rem",
            paddingTop: "1%",
        },
    },
    wrapper: {
        padding: "2rem 3.737rem 0",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        "@media (max-width: 820px)": {
            padding: "10px",
        },
    },
    exitIcon: {
        float: "right",
        color: white,
        width: "2rem",
        height: "2rem",
        cursor: "pointer",
    },
    header: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
    },
    confDetails: {
        display: "flex",
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            width: "80%",
        },
    },
    confSubDetails: {
        flexDirection: "column",
        display: "flex",
        marginLeft: "1.7rem",
        textAlign: "left",
        color: white,
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            marginLeft: "1.5rem",
        },
    },
    posterImage: {
        width: "4.5625rem",
        height: "4.5625rem",
        borderRadius: "0.5rem",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            width: "4rem",
            height: "4rem",
        },
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: "bold",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            fontSize: "1rem",
        },
    },
    speaker: {
        fontSize: "1.3rem",
        fontWeight: "lighter",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            fontSize: "0.8rem",
        },
    },
    volumeButton: {
        height: "3.125rem",
        width: "3.125rem",
        float: "right",
        marginBottom: "1.8rem",
        marginTop: "-0.3rem",
    },
    minimizeVolumeButton: {
        height: "3.125rem",
        width: "3.125rem",
        marginRight: "1.6rem",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            width: "2rem",
            height: "2rem",
            marginRight: "0rem",
        },
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "row",
        columnGap: 24,
    },
    main: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        width: "70%",
    },
    mainSideButton: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        width: "30%",
        gap: "16px",
    },
    description: {
        maxWidth: "67.625rem",
        color: white,
        fontSize: "1.5rem",
        maxHeight: "12.5rem",
        overflowY: "auto",
        alignSelf: "center",
        marginTop: "6.68rem",
        textAlign: "justify",
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            marginTop: "1rem",
            maxWidth: "100%",
        },
    },
    footer: {
        paddingBottom: "4rem",
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            paddingBottom: "0rem",
        },
    },
    confDate: {
        color: white,
        fontWeight: 400,
        fontSize: "1.4375rem",
        fontFamily: "Roboto",
    },
    modal: {},
    wrapperDiv: {
        background: "rgba(255,255,255,0.4)",
        height: "100%",
    },
    modalImage: {
        height: "100%",
        padding: "20px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            height: "100%",
            objectFit: "scale-down",
        },
    },
    closeModalBtn: {
        position: "absolute",
        top: "20px",
        right: "20px",
        width: "30px",
        height: "30px",
    },
    audioPlayDiv: {
        display: "flex",
        justifyContent: "space-between",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            display: "block",
        },
    },
    fullScreenImg: {
        marginLeft: "1.6rem",
        marginTop: "1rem",
        cursor: "pointer",
        // eslint-disable-next-line
        ["@media (max-width: 480px)"]: {
            marginLeft: "0",
            width: "4rem",
            height: "4rem",
        },
    },
    speechBtns: {},
    currentSpeaker: {},
    pointText: {},
    point: {},
});
export default PlayAudio;

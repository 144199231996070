import { Api } from "./api";

export const createConference = async (data) => {
    return await Api.post("conference", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getAllConferences = async (search) => {
    return await Api.get(`conference/${search}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getAllSearchConferences = async (search) => {
    return await Api.get(`conference/search/${search}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getAllCategories = async () => {
    return await Api.get("category")
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getConference = async (code) => {
    return await Api.get(`conference/hashCode/${code}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const updateConference = async (id, data) => {
    return await Api.patch(`conference/${id}`, data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const deleteConference = async (id) => {
    return await Api.delete(`conference/${id}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const findFilterConferences = async (data) => {
    return await Api.post("conference/filter", data)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const deactivateConference = async (id) => {
    return await Api.patch(`conference/deactivate/${id}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getSpeakerConferences = async (speakerId, search) => {
    return await Api.get(`conference/speaker/${speakerId}/${search}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getSpeakerSearchConferences = async (speakerId, search) => {
    return await Api.get(`conference/speaker/search/${speakerId}/${search}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const startConference = async (id) => {
    return await Api.post(`conference/start/${id}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const stopConference = async (id) => {
    return await Api.post(`conference/stop/${id}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

export const getConferenceInfo = async (title) => {
    return await Api.get(`conference/info/${title}`)
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

import React from "react";
import { RouteChildrenProps } from "react-router";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import cx from "classnames";

import { Header, Footer, LeftMenu } from "../index";
import SpeakerColoredHeader from "../SpeakerColoredHeader";
import { RootState } from "../../redux/store";

export interface tokenData {
    token: string;
    children: React.ReactChild | React.ReactNode;
    fullWidth?: boolean;
}

type Props = RouteChildrenProps | tokenData;

const PageWrapper: React.FC<Props> = (props) => {
    const { isSpeaker } = useSelector((state: RootState) => state.user);
    const classes = useStyles({});
    // const roleContext = useContext(RoleContext);
    // const currentRole = roleContext.currentRole;

    return (
        <div className={cx(classes.wrapper, props.fullWidth ? classes.wrapperFull : null)}>
            <LeftMenu {...props} />
            <div className={classes.page}>
                <Header {...props} />
                {isSpeaker && <SpeakerColoredHeader />}
                <div className={cx(classes.content, props.fullWidth ? classes.contentFull : null)}>
                    {props.children}
                </div>
                <Footer />
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        alignItems: "stretch",
        minHeight: "100%",
        position: "relative",
    },
    page: {
        width: "100%",
        backgroundColor: "#fafafb",
        display: "flex",
        flexDirection: "column",
        // eslint-disable-next-line
        ["@media (max-width: 576px)"]: {
            paddingBottom: 52,
        },
    },
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "1350px",
        padding: "36px 36px 36px 90px",
        margin: "0 auto",
        width: "100%",
        // eslint-disable-next-line
        ["@media (max-width: 576px)"]: {
            padding: "30px",
        },
    },
    wrapperFull: {},
    contentFull: {
        maxWidth: "none",
        padding: 0,
        zIndex: "99",
    },
});

export default PageWrapper;

import React, { useState } from "react";
import { InputBase } from "@material-ui/core";
import { darken, makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import { I18n, TkCategory, TkConference, TkUser } from "@tkbel/common";
import SVG from "react-inlinesvg";

import { SearchIcon } from "../../assets/images";
import { white } from "../../helpers/colors";
import { useClickOutside } from "../../hook/useClickOutside";
import ConferencesResult from "./components/ConferencesResult";
import CategoriesResult from "./components/CategoriesResult";

const SearchBar: React.FC<{
    search: string;
    setSearch: (val: string) => void;
    theme?: "default" | "white";
    speakers?: TkUser[];
    courses?: TkConference[];
    switchToSearch?: () => void;
    searchFor?: "category" | "conference";
    categories?: TkCategory[];
}> = ({ search, setSearch, courses, speakers, switchToSearch, searchFor, categories }) => {
    const classes = useStyles({});
    const searchBar = React.useRef(null);
    const dropdown = React.useRef<HTMLDivElement>(null);
    const [showAutoComplite, setShowAutoComplite] = useState(false);

    useClickOutside(dropdown, () => setShowAutoComplite(false));

    const handleSubmit = (event) => {
        event.preventDefault();
        searchBar.current.blur();
        switchToSearch && switchToSearch();
    };
    return (
        <div className={classes.wrapper}>
            <div className={classes.grayWrapper}>
                <SVG onClick={handleSubmit} src={SearchIcon} />
                <form className={classes.form} onSubmit={handleSubmit}>
                    <InputBase
                        className={classes.input}
                        placeholder={
                            searchFor === "category" ? I18n.t("searchForCategories") : I18n.t("searchConference")
                        }
                        value={search}
                        onChange={(event) => {
                            setSearch(event.target.value);
                        }}
                        inputRef={searchBar}
                    />
                </form>
                {search.length > 0 && <CancelIcon onClick={() => setSearch("")} className={classes.cancelIcon} />}
            </div>
            {(showAutoComplite || search) && (
                <div ref={dropdown} className={classes.autoComplite}>
                    {searchFor === "category" ? (
                        <CategoriesResult categories={categories} hideResults={() => setShowAutoComplite(false)} />
                    ) : (
                        <ConferencesResult
                            speakers={speakers}
                            courses={courses}
                            hideResults={() => setShowAutoComplite(false)}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        height: "100%",
    },
    grayWrapper: {
        border: "1px solid #eaebec",
        backgroundColor: "#fafafb",
        display: "flex",
        borderRadius: "4px",
        width: "100%",
        height: "100%",
        alignItems: "center",
        padding: "8px 15px",
        boxSizing: "border-box",
    },
    form: {
        display: "flex",
        alignItems: "center",
        flex: 1,
    },
    input: {
        fontSize: "15px",
        marginLeft: "20px",
        flex: 1,
        // eslint-disable-next-line
        ["@media (max-width: 576px)"]: {
            marginLeft: "5px",
        },
    },
    cancelIcon: {
        marginLeft: "auto",
        cursor: "pointer",
    },
    autoComplite: {
        position: "relative",
        width: "100%",
        background: white,
        borderRadius: "0 0 12px 12px",
        zIndex: 999,
        boxShadow: "0px 0px 67px 0px rgba(34, 60, 80, 0.2)",
        textAlign: "left",
        padding: "10px 0 0 0",
        "& span": {
            fontSize: "18px",
            fontWeight: "600",
            marginLeft: "10px",
        },
    },
    listItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px 10px",
        textAlign: "left",
        cursor: "pointer",
        transition: "background .2s linear",

        "&:hover": {
            background: darken(white, 0.02),
        },
    },

    info: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    imgWrapper: {
        width: "50px",
        height: "50px",
        borderRadius: "12px",
        background: "gray",

        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            objectFit: "fill",
        },
    },
    title: {
        fontWeight: 600,
        fontSize: "16px",
    },
    descr: {
        color: "#9b9b9b",
        fontWeight: 600,
        fontSize: "14px",
        width: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    reviews: {
        display: "flex",
        alignItems: "center",
    },
});

export default SearchBar;

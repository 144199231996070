import React, { useEffect, useState } from "react";
import { I18n } from "@tkbel/common";
import { RouteChildrenProps } from "react-router";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

import Loader from "../components/Loader";
import { CourseSlide, PageWrapper } from "../components";
import { RootState } from "../redux/store";

const HistoryPage: React.FC<RouteChildrenProps<{ filter: string }>> = (props) => {
    const { profile } = useSelector((state: RootState) => state.user);
    const classes = useStyles({});
    const [loading, setLoading] = useState(false);
    const [pastConferences, setPastConferences] = useState<any[]>([]);

    useEffect(() => {
        if (profile.conferences && profile.conferences.length) {
            let past = [];
            profile.conferences.forEach((conference) => {
                if (new Date(conference.endTime).valueOf() <= new Date().valueOf()) {
                    past.push(conference);
                }
            });

            if (past && past.length) {
                setPastConferences(
                    past.sort((a, b) => new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf()).reverse(),
                );
            }
        }
        setLoading(false);
    }, [profile]);

    return loading ? (
        <Loader />
    ) : (
        <PageWrapper {...props}>
            <div className={classes.container}>
                <h1 className={classes.pageHeader}>{I18n.t("myHistory")}</h1>
                {pastConferences.length ? (
                    <div className={classes.conferencesContainer}>
                        {pastConferences.map((conference) => (
                            <CourseSlide slide={conference} history={props.history} />
                        ))}
                    </div>
                ) : (
                    <div className={classes.notFound}>{I18n.t("conferenceNotFound")}</div>
                )}
            </div>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    pageContainer: {
        padding: "40px 20px",
        width: "100%",
        maxWidth: 1000,
        boxSizing: "border-box",
    },
    wrapper: {
        backgroundColor: "white",
    },
    tabs: {
        marginBottom: "1.5rem",
    },
    sectionTitle: {
        fontWeight: 500,
        fontSize: 14,
        borderBottom: " 1px #00000026 solid",
        marginBottom: 15,
    },
    list: {
        maxHeight: "33rem",
        overflow: "auto",
    },
    rating: {
        display: "flex",
        alignItems: "center",
    },
    price: {
        color: "#fd0053",
        fontSize: "16px",
        fontWeight: 600,
        margin: "10px 0 0",
    },
    container: {
        minHeight: "815px",
        width: "100%",
        boxShadow: "0px 3px 20px #00000008",
        margin: "22px auto 99px",
        borderRadius: "12px",
        backgroundColor: "#fff",
    },
    pageHeader: {
        marginTop: "36px",
        letterSpacing: "1.88px",
        color: "#1E2022",
        fontWeight: 400,
        fontSize: "30px",
    },
    library: {
        marginTop: "49px",
    },
    conferencesContainer: {
        display: "flex",
        padding: "0 20px",
        flexWrap: "wrap",
        "& > div": {
            width: "23%",
            margin: "10px 1%",
            // eslint-disable-next-line
            ["@media (max-width: 820px)"]: {
                width: "46%",
            },
            // eslint-disable-next-line
            ["@media (max-width: 480px)"]: {
                width: "100%",
            },
        },
    },
    notFound: {
        fontSize: 18,
        margin: "60px auto",
    },
});

export default HistoryPage;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TkConference } from "@tkbel/common";

const ReviewCardHeaderCourse = ({ course }: { course: TkConference }) => {
    const classes = useStyles({});

    return (
        <div className={classes.wrap}>
            <div className={classes.avatar}>{course.posterImage && <img src={course.posterImage} alt="poster" />}</div>
            <div className={classes.title}>{course.title}</div>
        </div>
    );
};

const useStyles = makeStyles({
    wrap: {
        minHeight: "59px",
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        width: "59px",
        height: "59px",
        borderRadius: "4px",
        backgroundColor: "black",
        marginRight: "15px",

        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "4px",
        },
    },
    title: {
        fontSize: "15px",
        lineHeight: "20px",
        height: "25px",
        color: "#222B45",
        marginTop: "6px",
    },
});

export default ReviewCardHeaderCourse;

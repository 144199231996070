import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onUpdateConference } from "../../redux/conferenceReducer";
import { TkConference } from "@tkbel/common";
import { transparentDarkGray } from "../../assets/colors";
import { PrevIcon, NextIcon, CloseIcon } from "../../assets/images";
import cx from "classnames";
import SVG from "react-inlinesvg";
import getUpload from "../../utils/getUrl";

import { makeStyles } from "@material-ui/styles";

const Gallery: React.FC<{ data; conference: TkConference; confId: string; active }> = (props) => {
    const classes = useStyles({});
    const dispatch = useDispatch();

    const images = props.data;
    const [imToDisplay, setImToDisplay] = useState(
        props.conference.currPresentationImage ? images.indexOf(props.conference.currPresentationImage) : -1,
    );
    const [activeParent, setActiveParent] = useState(false);

    useEffect(() => setActiveParent(props.active), [props.active]);
    const renderImages = () => {
        return (typeof images === "string" ? [images] : images || []).map((item, i) => {
            return (
                <div
                    key={i}
                    onClick={() => displayImage(i)}
                    className={cx(classes.imgTouch, imToDisplay >= 0 && classes.imgTouchDisabled)}
                >
                    <img
                        src={getUpload(item)}
                        className={cx(classes.imgSrc, imToDisplay >= 0 && classes.imgSemiTransparent)}
                        key={i}
                        alt=""
                    />
                </div>
            );
        });
    };

    const displayImage = async (i) => {
        setImToDisplay(i);
        await dispatch(onUpdateConference({ confId: props.confId, conference: { currPresentationImage: images[i] } }));
    };

    const endPresentation = async () => {
        setImToDisplay(-1);
        await dispatch(onUpdateConference({ confId: props.confId, conference: { currPresentationImage: null } }));
    };

    const displayModal = () => {
        return (
            <div className={classes.modal}>
                <div className={classes.modalContainer}>
                    <SVG src={CloseIcon} className={classes.cancel} onClick={endPresentation} />
                    <button
                        onClick={() => displayImage(imToDisplay - 1)}
                        className={cx(
                            classes.prevBtn,
                            classes.prevTouch,
                            imToDisplay > images.length - 2 && classes.btnTransparent,
                        )}
                        disabled={imToDisplay < 1}
                    >
                        <SVG src={PrevIcon} />
                    </button>
                    <img src={getUpload(images[imToDisplay])} className={classes.galleryImg} alt="" />
                    <button
                        onClick={() => displayImage(imToDisplay + 1)}
                        className={cx(
                            classes.prevBtn,
                            classes.prevTouch,
                            imToDisplay > images.length - 2 && classes.btnTransparent,
                        )}
                        disabled={imToDisplay > images.length - 2}
                    >
                        <SVG src={NextIcon} />
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className={cx(classes.imgContainer, imToDisplay >= 0 && classes.imgContainerDisplay)}>
            <div className={classes.imView}>{renderImages()}</div>
            {activeParent && imToDisplay >= 0 && displayModal()}
        </div>
    );
};

const useStyles = makeStyles({
    imgTouch: {
        marginRight: 10,
        marginTop: 9,
        width: "200px",
        height: "200px",
    },
    imgSrc: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    imgSemiTransparent: {
        opacity: 0.5,
    },
    imgContainer: {
        flex: 1,
        position: "relative",
    },
    imgContainerDisplay: {
        backgroundColor: transparentDarkGray,
    },
    imView: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        padding: 20,
        flex: 1,
        background: "rgba(255, 255, 255, 0.5)",
        display: "flex",
    },
    modal: {
        flexDirection: "row",
        top: 0,
        alignSelf: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.2)",
    },
    prevBtn: {
        width: 40,
        height: 40,
        opacity: 1,
    },
    cancel: {
        fontSize: 20,
        alignSelf: "flex-start",
        right: 15,
        top: 15,
        position: "absolute",
        width: "20px",
        height: "20px",
        fill: "#fff",
    },
    prevTouch: {
        height: 60,
        width: 60,
        backgroundColor: "#ffffff",
        borderRadius: "60px",
        border: "none",
        alignSelf: "center",
    },
    btnTransparent: {
        opacity: 0.4,
    },
    galleryImg: {
        width: "auto",
        height: "100%",
        objectFit: "cover",
        opacity: 1,
        margin: "0 5%",
        padding: "10px 0",
    },
    imgTouchDisabled: {},
    modalContainer: {
        height: "100%",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
});

export default Gallery;

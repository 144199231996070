import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import { RootState } from "../../../redux/store";
import { changeLanguage } from "../../../redux/userReducer";
import { useHistory } from "react-router";

const LanguageSelector = () => {
    const { language } = useSelector((state: RootState) => state.user);
    const classes = useStyles({});
    const history = useHistory();
    const [lang, setLang] = useState(language);
    const dispatch = useDispatch();

    const languages = [
        {
            label: "EN",
            value: "en",
        },
        {
            label: "FR",
            value: "fr",
        },
        {
            label: "HE",
            value: "he",
        },
        {
            label: "AR",
            value: "ar",
        },
    ];

    useEffect(() => {
        if (lang !== language) onSaveSettings().then(() => {});
    }, [lang]); // eslint-disable-line

    const onSaveSettings = async () => {
        await dispatch(changeLanguage({ language: lang }));
        history.push(history.location.pathname);
    };

    return (
        <FormControl>
            <Select
                value={lang}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => setLang(e.target.value as string)}
                displayEmpty
                classes={{
                    select: lang !== "he" ? classes.select : classes.select_rtl,
                    icon: lang !== "he" ? classes.selectIcon : classes.selectIcon_rtl,
                }}
            >
                {languages.map((item, i) => (
                    <MenuItem key={`conf_lang_${i}`} value={item.value} classes={{ root: classes.selectItem }}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const useStyles = makeStyles({
    select: {
        fontSize: 16,
        borderRadius: 4,
        padding: 10,
        background: "#fafafb",
        margin: "0 0 0 20px",
        height: 46,
        minWidth: 0,
        boxSizing: "border-box",
        border: "1px solid #eaebec",
        display: "flex",
        alignItems: "center",
    },
    select_rtl: {
        fontSize: 16,
        borderRadius: 4,
        padding: 10,
        background: "#fafafb",
        margin: "0 20px 0 0",
        height: 46,
        minWidth: 0,
        boxSizing: "border-box",
        border: "1px solid #eaebec",
        display: "flex",
        alignItems: "center",
        paddingLeft: "24px !important",
        paddingRight: "10px !important",
    },
    selectIcon: {
        top: "50%",
        transform: "translate(0, -50%)",
    },
    selectIcon_rtl: {
        top: "50%",
        transform: "translate(0, -50%)",
        left: "0 !important",
        right: "unset !important",
    },
    selectItem: {
        fontSize: 16,
    },
});

export default LanguageSelector;

import { Api } from "./api";

export const uploadMedia = async (data) => {
    const { file, type, name } = data;
    const formData = new FormData();
    formData.append("file", file, name || file.name);

    return await Api.post("media/upload", formData, {
        headers: {
            Type: type,
        },
    })
        .then((result) => result)
        .catch((error) => {
            console.error(error);
        });
};

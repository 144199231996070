import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SearchBar } from "../../index";
import { RootState } from "../../../redux/store";
import {
    onGetSearchConferences,
    onGetSpeakerSearchConferences,
    onGetAllSearchSpeakers,
} from "../../../redux/searchReducer";
import LanguageSelector from "./../components/LanguageSelector";
import HeaderMenu from "../components/HeaderMenu";

import * as classes from "../Header.module.scss";

interface HeaderTypes {}

const HeaderDesktop: React.FC<HeaderTypes> = () => {
    const { profile, isSpeaker } = useSelector((state: RootState) => state.user);
    const { searchConferences } = useSelector((state: RootState) => state.search);

    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState("");
    const { searchSpeakers } = useSelector((state: RootState) => state.search);

    useEffect(() => {
        if (profile && profile.id) {
            dispatch(
                isSpeaker
                    ? onGetSpeakerSearchConferences({ speakerId: profile.id, search: searchInput })
                    : onGetSearchConferences({ search: searchInput }),
            );
        }
        dispatch(onGetAllSearchSpeakers({ search: searchInput }));
    }, [searchInput, dispatch, profile, isSpeaker]);

    // @ts-ignore
    return (
        <div className={classes.wrapper}>
            <div className={classes.search}>
                <SearchBar
                    speakers={searchSpeakers}
                    search={searchInput}
                    setSearch={setSearchInput}
                    courses={searchConferences}
                />
            </div>
            <LanguageSelector classes={classes} />
            <HeaderMenu />
        </div>
    );
};

export default HeaderDesktop;

import React from "react";
import { StarIcon } from "../../../assets/images";
import { darken, makeStyles } from "@material-ui/core/styles";
import { white } from "../../../helpers/colors";
import { TkConference, TkUser, I18n } from "@tkbel/common";
import { useHistory } from "react-router";
import getUpload from "../../../utils/getUrl";

const ConferencesResult: React.FC<{
    speakers: TkUser[];
    courses: TkConference[];
    hideResults: () => void;
}> = ({ courses, speakers, hideResults }) => {
    const classes = useStyles({});
    const history = useHistory();

    return (
        <>
            <span>Conferences</span>
            {courses &&
                courses.length &&
                courses.map((item, index) =>
                    index > 3 ? null : (
                        <div
                            onClick={() => {
                                history.push(`/conference/${item.hashCode}`);
                                hideResults();
                            }}
                            className={classes.listItem}
                        >
                            <div className={classes.info}>
                                <div className={classes.imgWrapper}>
                                    <img src={getUpload(item.posterImage)} alt="course img" />
                                </div>
                                <div>
                                    <p className={classes.title}>{item.title}</p>
                                    <p className={classes.descr}>{item.description}</p>
                                </div>
                            </div>
                            <div className={classes.reviews}>
                                <StarIcon />
                                {I18n.t("reviews")}
                            </div>
                        </div>
                    ),
                )}
            <hr />
            <span>Instructors</span>
            {speakers &&
                speakers.length &&
                speakers.map((item, index) =>
                    index > 2 ? null : (
                        <div
                            onClick={() => {
                                history.push(`/speakerBoard/get/${item.id}`);
                                hideResults();
                            }}
                            className={classes.listItem}
                        >
                            <div className={classes.info}>
                                <div className={classes.imgWrapper}>
                                    {item.profileImage && <img src={getUpload(item.profileImage)} alt="img" />}
                                </div>
                                <div>
                                    <p className={classes.title}>
                                        {item.firstName} {item.lastName}
                                    </p>
                                    <p className={classes.descr}>{item.biography}</p>
                                </div>
                            </div>
                            <div className={classes.reviews}>
                                <StarIcon />
                                {I18n.t("reviews")}
                            </div>
                        </div>
                    ),
                )}
        </>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        height: "100%",
    },
    grayWrapper: {
        border: "1px solid #eaebec",
        backgroundColor: "#fafafb",
        display: "flex",
        borderRadius: "4px",
        width: "100%",
        height: "100%",
        alignItems: "center",
        padding: "8px 15px",
        boxSizing: "border-box",
    },
    form: {
        display: "flex",
        alignItems: "center",
        flex: 1,
    },
    input: {
        fontSize: "15px",
        marginLeft: "20px",
        flex: 1,
    },
    cancelIcon: {
        marginLeft: "auto",
        cursor: "pointer",
    },
    autoComplite: {
        position: "relative",
        width: "100%",
        background: white,
        borderRadius: "0 0 12px 12px",
        zIndex: 999,
        boxShadow: "0px 0px 67px 0px rgba(34, 60, 80, 0.2)",
        textAlign: "left",
        padding: "10px 0 0 0",
        "& span": {
            fontSize: "18px",
            fontWeight: "600",
            marginLeft: "10px",
        },
    },
    listItem: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "20px 10px",
        textAlign: "left",
        cursor: "pointer",
        transition: "background .2s linear",

        "&:hover": {
            background: darken(white, 0.02),
        },
    },

    info: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    imgWrapper: {
        width: "50px",
        height: "50px",
        borderRadius: "12px",
        background: "gray",

        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            objectFit: "fill",
        },
    },
    title: {
        fontWeight: 600,
        fontSize: "16px",
    },
    descr: {
        color: "#9b9b9b",
        fontWeight: 600,
        fontSize: "14px",
        width: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    reviews: {
        display: "flex",
        alignItems: "center",
    },
});

export default ConferencesResult;

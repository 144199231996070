import React, { useCallback, useEffect, useState } from "react";
import { RouteChildrenProps, useHistory } from "react-router";
import { TkConference, I18n, TkUser } from "@tkbel/common";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { Clear, PlayCircleFilledRounded, PauseCircleFilledRounded } from "@material-ui/icons";
import { Modal } from "@material-ui/core";
import { useSelector, shallowEqual } from "react-redux";

import Loader from "../components/Loader";
import { getFormatDD } from "../helpers/dateFormat";
import PageWrapper from "../components/PageWrapper";
import Stream from "../components/Stream";
import { linearBackgroud, white } from "../helpers/colors";
import { RootState } from "../redux/store";
import { useAppDispatch } from "../redux/hooks";
import SpeakerTabs from "../components/PlayConference/SpeakerTabs";
import { onEndConference, setCurrentConference } from "../redux/conferenceReducer";
import { startRecording, stopRecording } from "../utils/conference";
import getUpload from "../utils/getUrl";
import Chat from "../chat";
import { getMyProfile } from "../redux/userReducer";

interface MatchParams {
    id: string;
}

interface LocalProps {
    previewConference: TkConference;
    closeModal: () => void;
    saveConf: () => void;
    speaker?: TkUser;
}

type RouteProps = RouteChildrenProps<MatchParams>;
type Props = LocalProps | RouteProps;

const SpeakerConferenceScreen: React.FC<Props> = (props) => {
    const classes = useStyles({});
    const { paused, currentConference } = useSelector((state: RootState) => state.conference, shallowEqual);
    const { profile } = useSelector((state: RootState) => state.user);

    const history = useHistory();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(true);
    const [showEndPopup, setShowEndPopup] = useState(false);

    const onCheckConference = useCallback((conference) => {
        switch (conference) {
            case null:
                break;
            case "notFound":
                history.push("/conferences");
                break;
            default:
                setLoading(false);
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        onCheckConference(currentConference);
    }, [currentConference]); // eslint-disable-line

    useEffect(() => {
        dispatch(getMyProfile());
    }, [dispatch]);

    useEffect(() => {
        if (profile.hasOwnProperty("currentConference")) {
            dispatch(setCurrentConference(profile.currentConference));
        }
    }, [dispatch, profile]);

    const togglePlayConference = () => {
        // dispatch(
        //     paused
        //         ? onResumeConference({ confId: currentConference.id, pauses: currentConference.pauses })
        //         : onPauseConference({ confId: currentConference.id }),
        // );
    };

    const onStartRecording = () => {
        if (currentConference.recordType) {
            startRecording({
                room: currentConference.id,
                conferenceId: currentConference.id,
                format: "mp4",
            });
        }
    };

    const onHandleEndConference = async () => {
        setLoading(true);
        setShowEndPopup(false);
        await dispatch(onEndConference(currentConference.id))
            .unwrap()
            .then(() => history.push("/conferences"));

        if (currentConference.recordType) {
            await stopRecording({
                room: currentConference.id,
                conferenceId: currentConference.id,
                format: currentConference.recordType === "audio" ? "mp3" : "mp4",
            });
        }

        setLoading(false);
    };

    const ConfirmEndConference = () => {
        return (
            showEndPopup && (
                <Modal className={classes.modal} onClose={() => setShowEndPopup(false)} open={true}>
                    <div className={classes.wrapperDiv}>
                        <div className={classes.modalTitle}>You want to end conference?</div>
                        <button onClick={() => onHandleEndConference()} className={classes.modalBtn}>
                            End conference
                        </button>
                    </div>
                </Modal>
            )
        );
    };

    return (
        <PageWrapper fullWidth {...props}>
            <ConfirmEndConference />
            {loading ? (
                <Loader />
            ) : (
                currentConference &&
                currentConference.id && (
                    <div className={classes.content}>
                        <div className={classes.wrapper}>
                            <div className={classes.header}>
                                <div className={classes.confDetails}>
                                    <img
                                        alt="posterImage"
                                        className={classes.posterImage}
                                        src={getUpload(currentConference.posterImage)}
                                    />
                                    <div className={classes.confSubDetails}>
                                        <span className={classes.title}>{currentConference.title}</span>
                                        <span className={classes.speaker}>
                                            {profile.firstName} {profile.lastName}
                                        </span>
                                    </div>
                                </div>
                                <Clear className={classes.exitIcon} onClick={() => setShowEndPopup(true)} />
                            </div>
                            <div className={classes.main}>
                                {currentConference && (
                                    <>
                                        <Stream
                                            conference={currentConference}
                                            setShowEndPopup={setShowEndPopup}
                                            onSetStream={() => {
                                                // join(currentConference.id);
                                                onStartRecording();
                                            }}
                                        />
                                    </>
                                )}
                                <div className={classes.description}>{currentConference.description}</div>
                            </div>
                        </div>
                        <div className={classes.footer}>
                            <span className={classes.confDate}>
                                {`${I18n.t("listeningTo")} ${moment(currentConference.startTime).format(
                                    getFormatDD(),
                                )}`}
                            </span>
                        </div>
                        <SpeakerTabs conference={currentConference} confId={currentConference.id} resume={() => {}} />
                    </div>
                )
            )}
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    content: {
        backgroundImage: linearBackgroud,
        backgroundColor: white,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "5.75rem",
        width: "100%",
        // eslint-disable-next-line
        ["@media (max-width: 820px)"]: {
            paddingTop: "0rem",
        },
    },
    minimizeBackground: {
        backgroundImage: linearBackgroud,
        height: "20%",
        position: "fixed",
        bottom: 0,
        width: "100%",
    },
    minimizeContent: {
        flexDirection: "column",
        display: "flex",
        padding: "2rem  0rem 0 3.4rem",
    },
    iconBack: {
        cursor: "pointer",
        alignSelf: "flex-start",
        paddingLeft: "1.9rem",
        zIndex: 4,
    },
    wrapper: {
        padding: "2rem 3.737rem 0",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        // eslint-disable-next-line
        ["@media (max-width: 820px)"]: {
            padding: "1rem 1rem 0",
        },
    },
    exitIcon: {
        float: "right",
        color: white,
        width: "2rem",
        height: "2rem",
        cursor: "pointer",
    },
    header: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
    },
    confDetails: {
        display: "flex",
    },
    confSubDetails: {
        flexDirection: "column",
        display: "flex",
        marginLeft: "1.7rem",
        textAlign: "left",
        color: white,
    },
    posterImage: {
        width: "4.5625rem",
        height: "4.5625rem",
        borderRadius: "0.5rem",
    },
    title: {
        fontSize: "1.8rem",
        fontWeight: "bold",
    },
    speaker: {
        fontSize: "1.3rem",
        fontWeight: "lighter",
    },
    volumeButton: {
        height: "3.125rem",
        width: "3.125rem",
        float: "right",
        marginBottom: "1.8rem",
        marginTop: "-0.3rem",
    },
    minimizeVolumeButton: {
        height: "3.125rem",
        width: "3.125rem",
        marginRight: "1.6rem",
    },
    main: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    },
    description: {
        maxWidth: "67.625rem",
        color: white,
        fontSize: "1.5rem",
        maxHeight: "12.5rem",
        overflowY: "auto",
        alignSelf: "center",
        marginTop: "6.68rem",
        textAlign: "justify",
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            marginTop: "1rem",
            textAlign: "center",
        },
    },
    footer: {
        paddingBottom: "4rem",
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            paddingBottom: "1rem",
        },
    },
    confDate: {
        color: white,
        fontWeight: 400,
        fontSize: "1.4375rem",
        fontFamily: "Roboto",
    },
    controls: {
        margin: "15px auto",
    },
    controlBtn: {
        background: "transparent",
        borderRadius: "60px",
        margin: "0 10px",
        border: "none",
        "& svg": {
            fontSize: "60px",
            fill: "#ffffff",
        },
    },
    modal: {},
    modalTitle: {
        fontSize: 20,
        margin: "0 0 15px",
    },
    modalBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        width: "200px",
        height: "40px",
        borderRadius: "25px",
        border: "none",
        color: white,
        background: linearBackgroud,
        cursor: "pointer",
        fontSize: "18px",
    },
    wrapperDiv: {
        minHeight: 200,
        background: "#ffffff",
        width: 600,
        margin: "0 auto",
        top: "50%",
        position: "relative",
        transform: "translate(0, -50%)",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    },
    confButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        width: "200px",
        height: "40px",
        borderRadius: "25px",
        border: "none",
        color: white,
        background: linearBackgroud,
        cursor: "pointer",
        fontSize: "18px",
    },
});

export default SpeakerConferenceScreen;

export const transparent = "rgba(0,0,0,0)";
export const transparentBlack = "rgba(0,0,0,0.5)";

export const black = "#1D2226";

export const midGray = "#1D222666";
export const gray = "#A8A9AA";
export const lightGray = "#E4E4E5";
export const veryLightGray = "#FAFAFA";
export const darkGray = "#555656";
export const grayTimeline = "#FFFFFF33";

export const midLightGray = "#00000080";

export const red = "#ED034E";
export const purple = "#97196A";
export const primaryColor = red;
export const white = "#ffffff";

export const green = "#06c817";
export const yellow = "#ffe700";
export const blue = "#2655B4";

export const blackTrans = "#000000";
export const transparentGray = "#DCDCDC";
export const transparentDarkGray = "#00000099";

export const transparentWhite = "#CCFFFFFF";

export const grayShadow = "#0000001A";

export const darkBackdrop = "#000000BF";
export const pink = "#FFC0CB";

export const linearBackground = `linear-gradient(to right,${purple} ,${red})`;

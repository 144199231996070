import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { RouteChildrenProps } from "react-router";
import { PageWrapper } from "../components";
import ReviewCard from "../components/ReviewCard";
import ReviewCardHeaderInstructor from "../components/ReviewCard/ReviewCardHeaderInstructor";
import ReviewCardHeaderCourse from "../components/ReviewCard/ReviewCardHeaderCourse";

const CourseReview: React.FC<RouteChildrenProps> = (props) => {
    const classes = useStyles({});
    const [teacherRatingValue, setTeacherRatingValue] = useState(undefined);
    const [teacherTextarea, setTeacherTextarea] = useState("");
    const [courseRatingValue, setCourseRatingValue] = useState(undefined);
    const [courseTextarea, setCourseTextarea] = useState("");

    return (
        <PageWrapper {...props}>
            <div className={classes.container}>
                <h1 className={classes.pageHeader}>Review</h1>
                <div className={classes.cardsWrap}>
                    <ReviewCard
                        ratingValue={teacherRatingValue}
                        setRatingValue={setTeacherRatingValue}
                        textarea={teacherTextarea}
                        setTextarea={setTeacherTextarea}
                        reviewOn="Instructors"
                        textareaPlaceholder="Say something about your teacher..."
                        title="How about your teacher?"
                    >
                        <ReviewCardHeaderInstructor />
                    </ReviewCard>
                    <ReviewCard
                        ratingValue={courseRatingValue}
                        setRatingValue={setCourseRatingValue}
                        textarea={courseTextarea}
                        setTextarea={setCourseTextarea}
                        reviewOn="Course"
                        textareaPlaceholder="Say something about this course..."
                        title="How about this course?"
                    >
                        <ReviewCardHeaderCourse />
                    </ReviewCard>
                </div>
                <button className={classes.button}>Send Review</button>
            </div>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    container: {
        width: "1150px",
        minHeight: "815px",
        boxShadow: "0px 3px 20px #00000008",
        margin: "22px auto 99px",
        borderRadius: "12px",
        backgroundColor: "#fff",
    },
    pageHeader: {
        marginTop: "36px",
        letterSpacing: "1.88px",
        color: "#1E2022",
        fontWeight: 400,
        fontSize: "30px",
        marginBottom: 0,
    },
    cardsWrap: {
        display: "flex",
        padding: "84px 108px 69px",
        justifyContent: "space-between",
    },
    button: {
        marginBottom: "69px",
        background: "transparent linear-gradient(91deg, #9D1767 0%, #E8044F 100%) 0% 0% no-repeat padding-box",
        borderRadius: "10px",
        width: "343px",
        height: "44px",
        cursor: "pointer",
        fontSize: "15px",
        lineHeight: "16px",
        color: "#FFF",
        transition: "0.6s",
        border: "none",
        marginLeft: "auto",

        "&:hover": {
            transform: "scale(1.07)",
        },

        "&:active": {
            background: "linear-gradient(271deg, #9D1767 0%, #E8044F 100%)",
        },
    },
});

export default CourseReview;

import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { I18n } from "@tkbel/common";
import getUpload from "../../utils/getUrl";

interface CourseSlideTypes {
    slide: any;
    history?: any;
}

const CourseSlideWithBg: React.FC<CourseSlideTypes> = ({ slide, history }) => {
    const classes = useStyles({});
    let cityToDisplay = slide.city;

    const showConference = useCallback(
        (hashCode) => {
            history.push(`/conference/${hashCode}`);
        },
        [history],
    );

    return (
        <div onClick={() => showConference(slide.hashCode)} className={classes.slide}>
            <div className={classes.image}>
                <img className={classes.img} src={getUpload(slide.posterImage) || ""} alt="" />
            </div>
            <div className={classes.content}>
                <div className={classes.heading}>{I18n.t("conference")}</div>
                <div className={classes.title}>{slide.title}</div>
                <div className={classes.speaker}>{slide.speakerId}</div>
                {cityToDisplay && (
                    <div className={classes.location}>
                        <LocationOnIcon />
                        {cityToDisplay}
                    </div>
                )}
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    slide: {
        height: 250,
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        borderRadius: 10,
        overflow: "hidden",
        position: "relative",
    },
    image: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    title: {
        color: "#ffffff",
        width: "100%",
        fontSize: 24,
        margin: "5px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& svg path": {
            transition: "fill .2s linear",
        },

        "& svg.active path": {
            fill: "red",
        },
    },
    speaker: {
        display: "flex",
        justifyContent: "space-between",
        color: "#ffffff",
        fontSize: "18px",
        margin: "5px 0",
    },
    iconText: {
        margin: "5px 0 0",
        fontSize: "12px",
        color: "#ED034E",
    },
    location: {
        display: "flex",
        alignItems: "center",
        color: "#ffffff",
        fontSize: 16,
        margin: "0 0 6px",
        "& svg": {
            margin: "0 5px 0 0",
        },
    },
    content: {
        position: "relative",
        zIndex: 10,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "22px 20px",
        background: "rgba(0,0,0,0.2)",
    },
    heading: {
        color: "#ececec",
        fontSize: 16,
    },
});

export default CourseSlideWithBg;

import React, { useEffect, useState } from "react";
import { RouteChildrenProps, useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";
import SVG from "react-inlinesvg";
import { I18n } from "@tkbel/common";
import { useDispatch, useSelector } from "react-redux";

import { PageWrapper, SearchBar, Slider } from "../components";
import { linearBackgroud } from "../helpers/colors";
import Loader from "../components/Loader";
import { LocationIcon, FilterIcon } from "../assets/images";
import { RootState } from "../redux/store";
import FilterSidebar from "../components/FilterSidebar";
import PageBanner from "../components/PageBanner";
import { onGetConferences, onGetCategories } from "../redux/conferenceReducer";
import { getAllSpeakers } from "../redux/speakerReducer";
import { onGetSearchConferences, onGetSpeakerSearchConferences, onGetAllSearchSpeakers } from "../redux/searchReducer";

const HomePage: React.FC<RouteChildrenProps> = (props) => {
    const { speakers } = useSelector((state: RootState) => state.speaker);
    const { conferences, categories } = useSelector((state: RootState) => state.conference);
    const { profile, isSpeaker } = useSelector((state: RootState) => state.user);
    const [groupedData, setGroupedData] = useState<any[]>([]);
    const [pastData, setPastData] = useState<any[]>([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [showSidebar, setShowSidebar] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles({});
    const { searchConferences } = useSelector((state: RootState) => state.search);
    const { searchSpeakers } = useSelector((state: RootState) => state.search);

    const switchToSearch = (filters?: { country?: any[]; language?: any[]; category?: any[] }) => {
        localStorage.setItem("search", JSON.stringify({ ...filters, search }));

        history.push("/search");
    };

    // useEffect(() => {
    //     search && dispatch(searchCourses(search));
    //     dispatch(searchSpeakers(search));
    // }, [search, dispatch]);

    useEffect(() => {
        if (isSpeaker) history.push("/conferences");
    }, [profile]); // eslint-disable-line

    // const statuses = [
    //     { id: 1, value: "future", name: "Future" },
    //     { id: 1, value: "ongoing", name: "Ongoing" },
    //     { id: 1, value: "past", name: "Past" },
    // ];

    useEffect(() => {
        if (conferences.length) {
            setGroupedData(conferences.filter((item) => item.status === "Future"));
            setPastData(conferences.filter((item) => item.status === "Past" && item.recordType));
        }
    }, [conferences]);

    useEffect(() => {
        dispatch(onGetConferences());
        dispatch(getAllSpeakers());
        dispatch(onGetCategories());
    }, [dispatch]);

    useEffect(() => {
        if (profile && profile.id) {
            dispatch(
                isSpeaker
                    ? onGetSpeakerSearchConferences({ speakerId: profile.id, search: search })
                    : onGetSearchConferences({ search: search }),
            );
        }
        dispatch(onGetAllSearchSpeakers({ search: search }));
    }, [search, dispatch, profile, isSpeaker]); // eslint-disable-line

    return loading ? (
        <Loader />
    ) : (
        <PageWrapper {...props}>
            <PageBanner title={I18n.t("welcomeHomePage")} subtitle={I18n.t("subWelcomeHomePage")}>
                <>
                    <SearchBar
                        switchToSearch={switchToSearch}
                        speakers={
                            // speakersFilter
                            searchSpeakers
                        }
                        courses={
                            // filtratingCourses.map((item) => item.data() as TkConference)
                            searchConferences
                        }
                        search={search}
                        setSearch={setSearch}
                    />
                    <div className={classes.location}>
                        <SVG src={LocationIcon} />
                    </div>
                    <div onClick={() => setShowSidebar(true)} className={classes.filter}>
                        <SVG src={FilterIcon} />
                    </div>
                </>
            </PageBanner>
            {groupedData.length > 0 && (
                <Slider
                    slides={groupedData}
                    slideWrapper="courseWithBg"
                    // slidesPerView={isMobile ? 1: 3}
                    breakpoints={{
                        1200: {
                            slidesPerView: 4,
                        },
                        991: {
                            slidesPerView: 3,
                        },
                        670: {
                            slidesPerView: 2,
                        },
                        420: {
                            slidesPerView: 2,
                        },
                        300: {
                            slidesPerView: 2,
                        },
                    }}
                />
            )}
            <Slider
                title={I18n.t("categories")}
                slides={categories}
                slideWrapper="category"
                breakpoints={{
                    1200: {
                        slidesPerView: 4,
                    },
                    991: {
                        slidesPerView: 3,
                    },
                    670: {
                        slidesPerView: 2,
                    },
                    420: {
                        slidesPerView: 1,
                    },
                    300: {
                        slidesPerView: 1,
                    },
                }}
                showLinkViewAll
                linkToMore="/allcategories"
            />
            {groupedData.length > 0 && (
                <Slider
                    title={I18n.t("futureOngoing")}
                    slides={groupedData}
                    slideWrapper="course"
                    // slidesPerView={isMobile ? 1: 5}
                    breakpoints={{
                        1200: {
                            slidesPerView: 5,
                        },
                        991: {
                            slidesPerView: 4,
                        },
                        670: {
                            slidesPerView: 3,
                        },
                        420: {
                            slidesPerView: 2,
                        },
                        300: {
                            slidesPerView: 2,
                        },
                    }}
                    showLinkViewAll
                    linkToMore="/allconferences/future"
                />
            )}
            {pastData.length > 0 && (
                <Slider
                    title={I18n.t("past")}
                    slides={pastData}
                    slideWrapper="course"
                    // slidesPerView={isMobile ? 1:5}
                    breakpoints={{
                        1200: {
                            slidesPerView: 5,
                        },
                        991: {
                            slidesPerView: 4,
                        },
                        670: {
                            slidesPerView: 3,
                        },
                        420: {
                            slidesPerView: 2,
                        },
                        300: {
                            slidesPerView: 2,
                        },
                    }}
                    showLinkViewAll
                    linkToMore="/allconferences/past"
                />
            )}
            {speakers && speakers.length > 0 && (
                <Slider
                    title={I18n.t("popularInstructors")}
                    slides={speakers}
                    slideWrapper="people"
                    breakpoints={{
                        1200: {
                            slidesPerView: 8,
                        },
                        991: {
                            slidesPerView: 6,
                        },
                        670: {
                            slidesPerView: 4,
                        },
                        420: {
                            slidesPerView: 3,
                        },
                        300: {
                            slidesPerView: 3,
                        },
                    }}
                />
            )}
            <FilterSidebar sortCallback={switchToSearch} close={() => setShowSidebar(false)} show={showSidebar} />
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    lastItem: {
        paddingBottom: 0,
    },
    title: {
        marginTop: "9rem",
        marginLeft: "18rem",
    },
    page: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fafafb",
    },
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: "1350px",
        padding: "36px",
        margin: "0 auto",
    },
    location: {
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        minWidth: "50px",
        height: "100%",
        margin: "0 0 0 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    filter: {
        position: "relative",
        // background: linearBackgroud,
        borderRadius: "4px",
        minWidth: "50px",
        height: "100%",
        margin: "0 0 0 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
});

export default HomePage;

const lang = navigator.language.split("-")[0];
export function getFormatDD() {
    if (lang === "fr") return "DD MMM YYYY";
    return "MMM DD YYYY";
}

export function getFormatD() {
    if (lang === "fr") return "D MMM YYYY";
    return "MMM D YYYY";
}

export function getFormatHr() {
    if (lang === "fr") return "HH:mm";
    return "hh:mm a";
}

export function is24Hr() {
    return lang === "fr";
}

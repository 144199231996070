import React from "react";
import { Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SVG from "react-inlinesvg";

import { linearBackgroud } from "../helpers/colors";
import iconDelete from "../assets/images/icon_delete.svg";

const MessageModal: React.FC<{
    open: boolean;
    message: string;
    confirmButton: string;
    onConfirm: () => void;
    onCancel: () => void;
}> = ({ open, message, onConfirm, onCancel, confirmButton }) => {
    const classes = useStyle({});
    return (
        <Modal disableAutoFocus={true} className={classes.modal} open={open} onBackdropClick={onCancel}>
            <div className={classes.wrapperDiv}>
                <SVG src={iconDelete} className={classes.delete} onClick={onCancel} />
                <div className={classes.message}>{message}</div>
                <Button className={classes.button} onClick={onConfirm}>
                    {confirmButton}
                </Button>
            </div>
        </Modal>
    );
};

const useStyle = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    wrapperDiv: {
        backgroundColor: "white",
        width: "30%",
        height: "30%",
        position: "relative",
        padding: "3%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "1rem",
        "&:focus": {
            outline: "none !important",
        },
        // eslint-disable-next-line
        ["@media (max-width: 580px)"]: {
            width: "100%",
        },
    },
    delete: {
        position: "absolute",
        width: "3rem",
        height: "3rem",
        right: "-1.5rem",
        top: "-1.5rem",
        cursor: "pointer",
    },
    message: {
        fontSize: "1.5rem",
        fontWeight: 500,
        textAlign: "center",
    },
    button: {
        backgroundImage: linearBackgroud,
        width: "70%",
        height: "3rem",
        fontSize: "1rem",
        color: "white",
    },
});

export default MessageModal;

import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { IconPlus, StarIcon } from "../../../assets/images";
import { white } from "../../../helpers/colors";
import SVG from "react-inlinesvg";
import { I18n } from "@tkbel/common";
import getUpload from "../../../utils/getUrl";

interface IProps {
    data: any;
}

const WishlistItem = ({ data }: IProps) => {
    const classes = useStyles({});
    const [course, setCourse] = useState<any | null>(null);

    useEffect(() => {
        setCourse(data);
    }, [data]);

    return (
        <div className={classes.cardWrapper}>
            <div className={classes.cardInfo}>
                <div className={classes.imgWrapper}>
                    <img src={getUpload(course && course.posterImage)} alt="course img" />
                    {data.discount && <div className={classes.discount}>{data.discount}%</div>}
                </div>
                <div className={classes.courseInfo}>
                    <p className={classes.courseName}>{course && course.title}</p>
                    {/*<p className={classes.speacker}>{course && `${course.speaker.firstName} ${course.speaker.lastName}`}</p>*/}
                    <div className={classes.courseReview}>
                        <StarIcon />
                        <p>{course && course.rating && course.rating}</p>
                        <p>
                            ({course && course.reviews && course.reviews.length} {I18n.t("reviews")})
                        </p>
                    </div>
                </div>
            </div>
            <SVG src={IconPlus} />
        </div>
    );
};

const useStyles = makeStyles({
    cardWrapper: {
        width: "100%",
        borderRadius: "12px",
        boxShadow: "-2px -7px 100px 0px rgba(34, 60, 80, 0.1)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "40px 30px",
    },
    cardInfo: {
        display: "flex",
        alignItems: "center",
        gap: "15px",
        "-webkit-gap": "15px",
    },
    imgWrapper: {
        position: "relative",
        width: "90px",
        height: "90px",
        borderRadius: "8px",
        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "8px",
        },
    },
    courseInfo: {},
    courseReview: {
        display: "flex",
        marginTop: "10px",
        gap: "15px",
        "-webkit-gap": "15px",

        "& p:nth-child(2)": {
            color: "#8F9BB3",
            fontSize: "17px",
        },

        "& p:last-child": {
            color: "#C5CEE0",
            fontSize: "17px",
        },
    },
    speacker: {
        marginTop: "5px",
        color: "#8F9BB3",
        fontSize: "17px",
    },

    courseName: {
        fontSize: "20px",
        fontWeight: 500,
    },
    discount: {
        position: "absolute",
        background: "#FF6302",
        minWidth: "80px",
        padding: "10px 20px",
        borderRadius: "50px",
        fontSize: "20px",
        fontWeight: 600,
        color: white,
        top: "-30px",
        left: "-20px",
    },
});

export default WishlistItem;

import { makeStyles } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { DeleteIcon } from "../../assets/images";
import { linearBackgroud, white } from "../../helpers/colors";
import { useClickOutside } from "../../hook/useClickOutside";
import CustomCheckbox from "../CustomCheckbox";
import { I18n } from "@tkbel/common";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { onGetCategories, onGetConferences } from "../../redux/conferenceReducer";

interface IProps {
    show: boolean;
    close: () => void;
    sortCallback: (filters?: { country?: any[]; language?: any[]; category?: any[] }) => void;
}

const FilterSidebar = ({ show, close, sortCallback }: IProps) => {
    const { courses } = useSelector((state: RootState) => state.courses);
    const { conferences, categories } = useSelector((state: RootState) => state.conference);
    const classes = useStyles({ show });
    const ref = useRef<HTMLDivElement>(null);
    const allCategories = categories;
    const [countries, setCountries] = useState<any[]>([]);
    const [languages, setLanguages] = useState<any[]>([]);
    const [categoriesArray, setCategoriesArray] = useState<any[]>([]);

    const dispatch = useDispatch();

    useClickOutside(ref, close);

    useEffect(() => {
        if (conferences.length) {
            console.log("CONF", conferences);
            setCountries(
                conferences
                    .map((item) => ({ name: item.country, value: false }))
                    .filter((v, i, a) => v.name && a.findIndex((value) => v.name === value.name) === i),
            );
            setLanguages(
                conferences
                    .map((item) => ({ name: item.language, value: false }))
                    .filter((v, i, a) => v.name && a.findIndex((value) => v.name === value.name) === i),
            );
        }
    }, [conferences]);

    useEffect(() => {
        dispatch(onGetCategories());
        dispatch(onGetConferences());
    }, [dispatch]);

    useEffect(() => {
        if (allCategories.length) {
            setCategoriesArray(allCategories.map((item) => ({ name: item.title, id: item.id, value: false })));
        }
    }, [allCategories]);

    const updateFilterArray = (array, name) => {
        for (const item of array) {
            if (item.name === name) {
                item.value = !item.value;
                break;
            }
        }

        return array;
    };

    const onChangeFilters = (filter, name) => {
        switch (filter) {
            case "language":
                setLanguages([...updateFilterArray(languages, name)]);
                break;
            case "country":
                setCountries([...updateFilterArray(countries, name)]);
                break;
            case "category":
                setCategoriesArray([...updateFilterArray(categoriesArray, name)]);
                break;
            default:
                break;
        }
    };

    const sort = () => {
        sortCallback({ country: countries, language: languages, category: categoriesArray });
    };

    return (
        <aside ref={ref} className={classes.sidebar}>
            <DeleteIcon onClick={close} />
            <div className={classes.wrapper}>
                <p>{I18n.t("language")}</p>
                {languages.map((lang, index) => (
                    <div key={`Filter_lang_${index}`}>
                        <CustomCheckbox
                            htmlFor={lang.name}
                            value={lang.value}
                            onChange={() => onChangeFilters("language", lang.name)}
                        />
                        {lang.name}
                    </div>
                ))}
            </div>
            <div className={classes.wrapper}>
                <p>{I18n.t("country")}</p>
                {countries.map((country, index) => (
                    <div key={`Filter_country_${index}`}>
                        <CustomCheckbox
                            htmlFor={country.name}
                            value={country.value}
                            onChange={() => onChangeFilters("country", country.name)}
                        />
                        {country.name}
                    </div>
                ))}
            </div>
            <div className={classes.wrapper}>
                <p>{I18n.t("categories")}</p>
                {categoriesArray.map((category, index) => (
                    <div key={`Filter_category_${index}`}>
                        <CustomCheckbox
                            htmlFor={category.name}
                            value={category.value}
                            onChange={() => onChangeFilters("category", category.name)}
                        />
                        {category.name}
                    </div>
                ))}
            </div>
            <div className={classes.wrapper}>
                <button onClick={sort} className={classes.submit}>
                    {I18n.t("sort")}
                </button>
            </div>
        </aside>
    );
};

const useStyles = makeStyles({
    sidebar: {
        width: "calc(100vw / 5)",
        height: "100vh",
        position: "fixed",
        padding: "40px 20px",
        top: 0,
        right: 0,
        transform: ({ show }) => `translate(${show ? "0" : "100%"})`,
        transition: ".2s linear",
        background: white,
        zIndex: 99999,
        boxShadow: "-13px 0px 37px -1px rgba(34, 60, 80, 0.2)",
        overflowY: "scroll",
        // eslint-disable-next-line
        ["@media (max-width: 578px)"]: {
            width: "80%",
        },

        "& > svg": {
            position: "absolute",
            right: "20px",
            top: "20px",
            width: "50px",
            height: "50px",
        },
    },
    wrapper: {
        "& div": {
            display: "flex",
            alignItems: "center",
            margin: "5px 0",
            cursor: "pointer",
            "& div": {
                marginRight: "10px",
            },
        },
    },
    submit: {
        width: "50%",
        height: "40px",
        background: linearBackgroud,
        borderRadius: "6px",
        border: "none",
        color: white,
        fontSize: "16px",
        fontWeight: 500,
        cursor: "pointer",
        margin: "20px 10px 0 0",
    },
});

export default FilterSidebar;

import Dexie from "dexie";

class PDFDexieDB extends Dexie {
    pdf: Dexie.Table<IPDF, string>;
    constructor() {
        super("PDFDexieDB");
        this.version(1).stores({
            pdf: "++id, pdfFile,pdfName",
        });

        this.pdf = this.table("pdf");
    }
}

interface IPDF {
    id: string;
    pdfFile: Blob;
    pdfName: string;
}
export default PDFDexieDB;

import React from "react";
import { InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import { I18n } from "@tkbel/common";

import imgPath from "../assets/images/search_menu.svg";

const SearchBar: React.FC<{
    search: string;
    setSearch: (val: string) => void;
}> = ({ search, setSearch, setSearchEnter }) => {
    const classes = useStyles({});
    const searchBar = React.useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        searchBar.current.blur();
    };

    const handleChange = (event) => {
        if (event.key === "Enter") {
            setSearchEnter(true);
        } else {
            setSearchEnter(false);
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.grayWrapper}>
                <img alt="search" className={classes.img} src={imgPath} />
                <form className={classes.form} onSubmit={handleSubmit}>
                    <InputBase
                        className={classes.input}
                        placeholder={I18n.t("searchConference")}
                        value={search}
                        onChange={(event) => {
                            setSearch(event.target.value);
                            setSearchEnter(false);
                        }}
                        onKeyPress={handleChange}
                        inputRef={searchBar}
                    />
                </form>
                {search.length > 0 && <CancelIcon onClick={() => setSearch("")} className={classes.cancelIcon} />}
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        height: "4rem",
        margin: "2rem 0",
    },
    grayWrapper: {
        backgroundColor: "#FAFAFA",
        display: "flex",
        borderRadius: "3.625rem",
        width: "100%",
        height: "100%",
        alignItems: "center",
    },
    img: {
        marginLeft: "2rem",
        // eslint-disable-next-line
        ["@media (max-width: 820px)"]: {
            marginLeft: "0",
        },
    },
    form: {
        display: "flex",
        alignItems: "center",
        flex: 1,
    },
    input: {
        fontSize: "14px",
        marginLeft: "15px",
        flex: 1,
    },
    cancelIcon: {
        marginLeft: "auto",
        marginRight: "20px",
        cursor: "pointer",
    },
});

export default SearchBar;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "./store";
import { getParticipantsForConference, TkConference, TkUser, getJoinedConference } from "@tkbel/common";
import {
    createConference,
    getAllConferences,
    getSpeakerConferences,
    getConference,
    updateConference,
    startConference,
    stopConference,
    getAllCategories,
} from "../utils/api/conference";
import { joinToConference } from "../utils/api/user";

const initialState = {
    statusForSpeaker: "",
    statusForUser: "",
    startTime: null,
    activeConfirm: false,
    speechPermission: "",
    currentConference: null,
    participants: [],
    paused: false,
    joinedConference: [],
    session: null,
    call: null,
    conferences: [],
    conference: {},
    minimizeScreen: false,
    categories: [],
};

interface ConferenceFields {
    confId: string;
    speakerId: string;
    userId: string;
    startTime: any;
    confStatus: string;
    pauses: any[];
    conference: TkConference;
    mediaServer: string;
}

export const onStartConference = createAsyncThunk<object, { id: number }>(
    "conference/onStartConference",
    async (payload, { dispatch }) => {
        const { id } = payload;
        try {
            await startConference(id).then((result: any) => {
                dispatch(setCurrentConference(result.data));
                dispatch(setConferenceStartTime(result.data.actuallyStartTime));
            });
        } catch (error) {}
    },
);

export const onGetConferences = createAsyncThunk<object>(
    "conference/getAllConferences",
    async (payload, { dispatch }) => {
        // const { search } = payload;
        try {
            await getAllConferences("").then((result: any) => dispatch(setConferences(result.data)));
        } catch (error) {}
    },
);

export const onGetCategories = createAsyncThunk("categories/getAll", async (payload, { dispatch }) => {
    try {
        await getAllCategories().then((result: any) => dispatch(setCategories(result.data)));
    } catch (error) {}
});

export const onGetConference = createAsyncThunk<object, { id: string }>("conference/getConference", async (id) => {
    return await getConference(id).then((result: any) => result.data);
});

export const onGetSpeakerConferences = createAsyncThunk<object, { speakerId: any; search: any }>(
    "conference/getSpeakerConferences",
    async (payload, { dispatch }) => {
        const { speakerId, search } = payload;
        try {
            await getSpeakerConferences(speakerId, search).then((result: any) =>
                dispatch(setConferences(result.data.length ? result.data : result.data)),
            );
        } catch (error) {}
    },
);

export const onCreateConference = createAsyncThunk<object, ConferenceFields>(
    "conference/createConference",
    async (payload) => {
        try {
            await createConference(payload).then(() => {});
        } catch (error) {}
    },
);

export const onEndConference = createAsyncThunk<object, { id: number }>(
    "conference/onEndConference",
    async (id, { dispatch }: { dispatch: AppDispatch }) => {
        try {
            await stopConference(id);
            dispatch(setCurrentConference({}));
        } catch (error) {}
    },
);

export const onUpdateConference = createAsyncThunk<object, { id: string; conference: any }>(
    "conference/onUpdateConference",
    async (payload) => {
        const { id, conference } = payload;
        try {
            await updateConference(id, conference);
        } catch (error) {}
    },
);

export const onGetParticipantsForConference = createAsyncThunk<object, ConferenceFields>(
    "conference/onGetParticipantsForConference",
    async (payload, { dispatch }) => {
        const { confId, userId } = payload;
        try {
            await getParticipantsForConference(confId).onSnapshot((snap) => {
                dispatch(
                    setParticipants(
                        snap.docs.filter(
                            (item) =>
                                item.id !== userId &&
                                !(item.roles && item.roles.speaker && confId in (item.data() as TkUser).conferences),
                        ),
                    ),
                );
            });
        } catch (error) {}
    },
);

export const onJoinCurrentConference = createAsyncThunk<object, { id: number }>(
    "conference/joinCurrentConference",
    async (payload, { dispatch }) => {
        const { id } = payload;
        try {
            await joinToConference({ conferenceId: id }).then((result: any) => {
                dispatch(setConferenceStatusUser("joinedToCurrent"));
                dispatch(setCurrentConference(result.data.currentConference));
            });
        } catch (error) {}
    },
);

export const onStartCall = createAsyncThunk<object, { call: any; session: any }>(
    "conference/onStartCall",
    async (payload, { dispatch }) => dispatch(setCall({ type: "start", ...payload })),
);

export const onStopCall = createAsyncThunk<object>("conference/onStopCall", async (payload, { dispatch }) =>
    dispatch(setCall({ type: "stop" })),
);

export const onRefreshConference = createAsyncThunk<object, ConferenceFields>(
    "conference/onRefreshConference",
    async (payload, { dispatch }) => {
        dispatch(setCurrentConference({ ...payload }));
    },
);

export const onGetJoinedConference = createAsyncThunk<object, ConferenceFields>(
    "conference/getJoinedConference",
    async (payload, { dispatch }) => {
        const { userId } = payload;
        try {
            await getJoinedConference(userId).onSnapshot((snap) => dispatch(setJoinedConference(snap.docs)));
        } catch (error) {}
    },
);

export const conferenceSlice = createSlice({
    name: "conference",
    initialState,
    reducers: {
        setConferences: (state, action: PayloadAction<any>) => {
            state.conferences = action.payload;
        },
        setCategories: (state, action: PayloadAction<any>) => {
            state.categories = action.payload;
        },
        setConference: (state, action: PayloadAction<any>) => {
            state.conference = action.payload;
        },
        setConferenceStatusSpeaker: (state, action: PayloadAction<string>) => {
            state.statusForSpeaker = action.payload;
        },
        setConferenceStatusUser: (state, action: PayloadAction<string>) => {
            state.statusForUser = action.payload;
        },
        setConferenceStartTime: (state, action: PayloadAction<string>) => {
            state.startTime = action.payload;
        },
        setActiveConfirm: (state, action: PayloadAction<boolean>) => {
            state.activeConfirm = action.payload;
        },
        setSpeechPermission: (state, action: PayloadAction<string>) => {
            state.speechPermission = action.payload;
        },
        setCurrentConference: (state, action: PayloadAction<any>) => {
            state.currentConference = action.payload;
        },
        setParticipants: (state, action: PayloadAction<any>) => {
            state.participants = action.payload;
        },
        setPauseConference: (state, action: PayloadAction<any>) => {
            state.paused = action.payload;
        },
        setCall: (state, action: PayloadAction<any>) => {
            const { type } = action.payload;

            if (type === "start") {
                state.session = action.payload.session || null;
                state.call = action.payload.call || null;
            } else if (type === "stop") {
                if (state.session && state.call) {
                    state.session.terminate();
                    state.call.stop();
                }

                state.session = state.call = null;
            }
        },
        setJoinedConference: (state, action: PayloadAction<any>) => {
            state.joinedConference = action.payload;
        },
        setMinimize: (state, action: PayloadAction<any>) => {
            state.minimizeScreen = action.payload;
        },
    },
});

const {
    setConferenceStatusSpeaker,
    setConferenceStatusUser,
    setConferenceStartTime,
    setActiveConfirm,
    setSpeechPermission,
    setCurrentConference,
    setParticipants,
    setPauseConference,
    setCall,
    setJoinedConference,
    setConferences,
    setConference,
    setMinimize,
    setCategories,
} = conferenceSlice.actions;

export {
    setConferenceStatusSpeaker,
    setConferenceStatusUser,
    setConferenceStartTime,
    setActiveConfirm,
    setSpeechPermission,
    setCurrentConference,
    setParticipants,
    setPauseConference,
    setCall,
    setJoinedConference,
    setConferences,
    setConference,
    setMinimize,
    setCategories,
};

export default conferenceSlice.reducer;

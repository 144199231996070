import React, { useRef } from "react";
import { TkFile, I18n } from "@tkbel/common";
import { makeStyles } from "@material-ui/styles";
import { Checkbox } from "@material-ui/core";

import { gray, black, grayShadow, midLightGray, red } from "../helpers/colors";
import { getPrice } from "../helpers/fileFunctions";
import plugIcon from "../assets/images/Icon_plus.svg";
import closeIcon from "../assets/images/close.svg";

const wrapper = {
    // display: "flex",
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: gray,
    padding: "0.7rem 1.5625rem 0 1.5625rem",
    borderRadius: 1,
    // eslint-disable-next-line
    ["@media (max-width: 780px)"]: {
        padding: "0.7rem 0.3rem 0 1.5625rem",
    },
};

const PdfUpload: React.FC<{
    onSelect?: (doc) => void;
    documents: TkFile[];
    hasDocuments: boolean;
    setHasDocuments: (boolean) => void;
    onDeletePdf: (number) => void;
    canEdit?: boolean;
}> = (props) => {
    const classes = useStyle({});

    const upload = useRef(null);
    const { hasDocuments, documents, setHasDocuments } = props;

    const renderRows = () => {
        return documents
            ? documents.map((doc, i) => {
                  return (
                      <div className={classes.documentRow} key={i}>
                          {(props.canEdit || !(typeof doc.path === "string")) && (
                              <img
                                  alt="cancel"
                                  className={classes.cancel}
                                  src={closeIcon}
                                  onClick={() => props.onDeletePdf(i)}
                              />
                          )}
                          <span className={classes.name}>{doc.name}</span>
                          <span className={classes.size}>{doc.size}</span>
                          <div className={classes.priceDiv}>
                              <span className={classes.priceTitle}>{`${I18n.t("price")}  \u00A0`}</span>
                              <span className={classes.price}>{getPrice(doc.price)}</span>
                          </div>
                      </div>
                  );
              })
            : null;
    };
    const selectFile = (e: React.ChangeEvent<{ files: unknown }>) => {
        if (e.target.files) {
            Object.values(e.target.files).forEach((file) => {
                if (file.size === 0) alert(`${I18n.t("emptyFile")}:${"\n"} ${file.name}`);
                else props.onSelect(file as Blob);
            });
        }
    };

    return (
        <div style={hasDocuments ? { ...wrapper, margin: "0rem 0" } : {}}>
            <div className={classes.checbox}>
                <Checkbox onChange={(e) => setHasDocuments(e.target.checked)} checked={hasDocuments} color="primary" />
                <div className={hasDocuments ? classes.textWithDocs : classes.text}>{I18n.t("documents")}</div>
            </div>
            {hasDocuments && (
                <>
                    {renderRows()}
                    <div className={classes.addDiv}>
                        <div
                            className={classes.clickDiv}
                            onClick={() => {
                                upload.current.click();
                            }}
                        >
                            <img alt="plus" src={plugIcon} className={classes.plus} />

                            <input
                                type="file"
                                id="file"
                                ref={upload}
                                multiple
                                accept=".pdf"
                                style={{ display: "none" }}
                                onChange={selectFile}
                            />
                        </div>
                        <span className={classes.span}>{I18n.t("addDocuments")}</span>
                    </div>
                </>
            )}
        </div>
    );
};

const useStyle = makeStyles({
    checbox: {
        display: "flex",
        alignItems: "center",
    },
    text: {
        fontSize: "1.2rem",
        fontWeight: 500,
        textAlign: "start",
        alignSelf: "center",
        margin: "1.75rem 0",
    },
    checkboxWithDocs: {
        display: "flex",
        alignItems: "center",
    },
    textWithDocs: {
        fontSize: "1.2rem",
        fontWeight: 500,
        textAlign: "start",
        alignSelf: "center",
    },
    addDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "1.875rem 0",
    },
    clickDiv: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    span: {
        fontSize: "1.08rem",
        marginLeft: "0.8rem",
    },
    documentRow: {
        display: "flex",
        padding: "0.75rem 1.44rem",
        alignItems: "center",
        justifyContent: "space-between",
        alignSelf: "stretch",
        margin: "1.875rem 0 0 0",
        borderWidth: 1,
        borderColor: grayShadow,
        borderStyle: "solid",
        position: "relative",
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            padding: "0.75rem 0.5rem",
        },
    },
    name: {
        flex: 1,
        alignItems: "flex-start",
        fontSize: "1rem",
        fontFamily: "Roboto",
        fontWeight: 500,
        color: black,
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            width: "50%",
        },
    },
    size: {
        flex: 1,
        alignItems: "flex-start",
        fontSize: "1rem",
        fontFamily: "Roboto",
        color: midLightGray,
        textAlign: "right",
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            width: "100%",
        },
    },
    priceDiv: {
        flex: 1,
        alignItems: "flex-start",
        justifyContent: "flex-end",
        display: "flex",
    },
    priceTitle: {
        fontSize: "1rem",
        fontFamily: "Roboto",
    },
    price: {
        fontSize: "1rem",
        fontFamily: "Roboto",
        fontWeight: 500,
        color: red,
    },
    plus: {
        width: "2rem",
        height: "2rem",
    },
    cancel: {
        width: "1.125rem",
        height: "1.125rem",
        cursor: "pointer",
        right: "-0.5rem",
        top: "-0.5rem",
        position: "absolute",
        zIndex: 1,
    },
});
export default PdfUpload;

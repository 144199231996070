import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LinkedIn, YouTube } from "@material-ui/icons";
import { I18n } from "@tkbel/common";

interface FooterTypes {}

const Footer: React.FC<FooterTypes> = () => {
    const classes = useStyles({});

    return (
        <div className={classes.wrapper}>
            <div className={classes.footerContainer}>
                <div className={classes.row}>
                    <Button
                        style={{
                            marginBottom: "10px",
                        }}
                        variant="contained"
                        href="https://tkbel.fr/nous-contacter"
                        target="_blank"
                        size="large"
                        color="primary"
                    >
                        {I18n.t("contactUs")}
                    </Button>
                    <div className={classes.linkContainer}>
                        <a className={classes.link} href="mailto:support@tkbel.fr">
                            support@tkbel.fr
                        </a>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.iconsContainer}>
                        <a
                            className={classes.icon}
                            href="https://www.linkedin.com/company/tkbel/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LinkedIn htmlColor="#ffffff" />
                        </a>
                        <a
                            className={classes.icon}
                            href="https://www.youtube.com/channel/UCN1ryueR8Q6VrdtmAJglx6w"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <YouTube htmlColor="#ffffff" />
                        </a>
                    </div>
                    <ul className={classes.list}>
                        <li className={classes.listItem}>
                            <a href="https://tkbel.fr/conditions-generales" target="_blank" rel="noreferrer">
                                {I18n.t("termsAndConditions")}
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a
                                href="https://tkbel.fr/conditions-particulieres-applicables-aux-speakers"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {I18n.t("specialConditionsForSpeakers")}
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a href="https://tkbel.fr/charte-de-confidentialite" target="_blank" rel="noreferrer">
                                {I18n.t("privacyPolicy")}
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a href="https://tkbel.fr/faq" target="_blank" rel="noreferrer">
                                FAQ
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={classes.row}>
                    <div className={classes.listHeader}>{I18n.t("about")}</div>
                    <ul className={classes.list}>
                        <li className={classes.listItem}>
                            <a href="https://tkbel.fr/blog" target="_blank" rel="noreferrer">
                                Blog
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a href="https://tkbel.fr/a-propos" target="_blank" rel="noreferrer">
                                Qui sommes-nous
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a href="https://tkbel.fr/tarifs" target="_blank" rel="noreferrer">
                                Tarifs
                            </a>
                        </li>
                        <li className={classes.listItem}>
                            <a href="https://tkbel.fr/nous-contacter" target="_blank" rel="noreferrer">
                                Nous contacter
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={classes.row}>
                    <div className={classes.contacts}>
                        <div className={classes.contactItem}>
                            <b>{I18n.t("contactDetails")}</b> TKBEL, 118 Avenue Jean Jaures, 75019 Paris
                        </div>
                        <div className={classes.contactItem}>
                            <b>{I18n.t("telephone")}</b> 0177470958
                        </div>
                        <div className={classes.contactItem}>
                            <b>{I18n.t("publicationDirector")}</b> Haim Berkovits
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        margin: "20px 0 0",
        boxShadow: "1px -1px 6px -1px rgba(0,0,0,.15)",
        padding: "70px 0px 70px 0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "#001C38",
        zIndex: "9",
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            padding: "20px 0",
        },
    },
    footerContainer: {
        width: "100%",
        maxWidth: "1300px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-between",
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            flexWrap: "wrap",
        },
    },
    row: {
        flexBasis: "25%",
        textAlign: "left",
        padding: "0 10px",
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            flexBasis: "50%",
        },
        // eslint-disable-next-line
        ["@media (max-width: 400px)"]: {
            flexBasis: "100%",
            marginTop: 30,
        },
    },
    logo: {
        width: "200px",
    },
    img: {
        width: "100%",
    },
    linkContainer: {},
    link: {
        color: "#ffffff",
        fontSize: 16,
        fontWeight: 500,
    },
    iconsContainer: {
        display: "flex",
        alignItems: "center",
        margin: "0 0 15px",
    },
    icon: {
        background: "rgba(255,255,255,0.21)",
        padding: "8px",
        display: "flex",
        margin: "0 5px 0 0",
        borderRadius: "4px",
    },
    list: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    listItem: {
        paddingBottom: "calc(5px/2)",
        textAlign: "left",
        "& > a": {
            fontSize: 12,
            fontWeight: 300,
            color: "rgba(255,255,255,0.38)",
        },
    },
    listHeader: {
        color: "#ffffff",
        fontSize: 14,
        fontWeight: 500,
        textAlign: "left",
        margin: "0 0 15px",
    },
    contacts: {},
    contactItem: {
        textAlign: "left",
        color: "#ffffff",
        fontSize: 12,
        margin: "0 0 10px",
    },
});

export default Footer;

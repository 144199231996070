import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useHistory } from "react-router";
import { I18n } from "@tkbel/common";

import { PageWrapper } from "../../components";
import PageCardWrapper from "../../components/PageCardWrapper";
import WishlistItem from "./wishlistItem";
import { linearBackgroud, white } from "../../helpers/colors";
import { ShopingCartElips } from "../../assets/images";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const WishList = (props) => {
    const { profile } = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const classes = useStyle({});

    return (
        <PageWrapper {...props}>
            <PageCardWrapper {...props}>
                <div className={classes.wishlistWrapper}>
                    <div className={classes.title}>
                        <p>{I18n.t("wishlist")}</p>
                    </div>
                    <div className={classes.wishlistContent}>
                        <div className={classes.wishlistItems}>
                            {profile.wishList &&
                                profile.wishList.map((item, idx) => <WishlistItem key={idx} data={item} />)}
                        </div>
                    </div>
                    <button onClick={() => history.goBack()}>{I18n.t("backExplore")}</button>
                    <div className={classes.card}>
                        <ShopingCartElips />
                    </div>
                </div>
            </PageCardWrapper>
        </PageWrapper>
    );
};

const useStyle = makeStyles({
    wishlistWrapper: {
        position: "relative",
        maxWidth: "800px",
        width: "100%",
        padding: "35px 50px 55px 50px",

        "& button": {
            maxWidth: "386px",
            width: "100%",
            height: "48px",
            background: linearBackgroud,
            color: white,
            border: "none",
            borderRadius: "10px",
            fontSize: "17px",
            fontWeight: 700,
            marginTop: "20px",
            cursor: "pointer",
        },
    },
    title: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: "60px",

        "& p": {
            fontSize: "30px",
            fontWeight: "500",
        },
    },
    wishlistContent: {},
    instructor: {
        display: "flex",
        alignItems: "center",
        gap: "6px",
        "-webkit-gap": "6px",

        "& p": {
            color: "#77838F",
        },
    },
    wishlistItems: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        "-webkit-gap": "15px",
        width: "100%",
        marginTop: "20px",
        // maxHeight: '500px',
        // overflowY: 'auto'
    },
    imgWrapper: {
        width: "38px",
        height: "38px",
        borderRadius: "50%",

        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "fill",
            borderRadius: "50%",
        },
    },
    card: {
        position: "absolute",
        width: "38px",
        height: "38px",
        borderRadius: "10px",
        background: "#FFFFFF",
        top: "50px",
        right: "50px",
        boxShadow: "-2px -7px 100px -32px rgba(34, 60, 80, .5)",
    },
});

export default WishList;

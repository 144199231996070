import React, { useEffect, useState } from "react";
import moment from "moment";
import { I18n } from "@tkbel/common";
import { useDispatch, useSelector } from "react-redux";
import momentDurationFormatSetup from "moment-duration-format";
import { Redirect, Switch, useLocation } from "react-router-dom";

import { RootState } from "./redux/store";
import LoginPage from "./routes/login";
import PrivateRoute from "./routes/privateRoute";
import AppRouter from "./routes/router";
import { changeLanguage, getMyProfile, setLogged } from "./redux/userReducer";
import { useHistory } from "react-router";
import { CustomCircularProgress } from "./components/Loader";

momentDurationFormatSetup(moment);

const App: React.FC = () => {
    const { isLogged, language, isSpeaker } = useSelector((state: RootState) => state.user);
    const { rehydrated } = useSelector((state: RootState) => state._persist);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = () => {
            dispatch(getMyProfile());
            dispatch(setLogged(true));
        };

        return () => {
            unsubscribe();

            const lang = navigator.language.split("-")[0];
            // @ts-ignore
            dispatch(changeLanguage({ language: lang === "en" || lang === "fr" ? lang : "he" }));
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        const getConference = () => {
            if (location.search && location.search.includes("hashCode")) {
                history.push(`conference/${location.search.substring(location.search.indexOf("=") + 1)}`);
            } else {
                return;
            }
        };

        getConference();
    }, [history, location]);

    useEffect(() => {
        if (isLogged && location.pathname === "/") {
            history.push(isSpeaker ? "/conferences" : "/home");
        }
    }, [isLogged, isSpeaker]); // eslint-disable-line

    useEffect(() => {
        if (isLogged) {
            dispatch(getMyProfile());
            // dispatch(coursesGet());
            // dispatch(searchInstructors({}));
        }
    }, [isLogged, dispatch]);

    useEffect(() => {
        if (!rehydrated) setLoading(true);
        else setLoading(false);
    }, [rehydrated]);

    useEffect(() => {
        let lang: string;
        switch (language) {
            case "fr":
                require("moment/locale/fr");
                lang = "fr";
                break;
            case "he":
                require("moment/locale/he");
                lang = "he";
                break;
            default:
                require("moment/locale/en-in");
                lang = "en-in";
                break;
        }

        I18n.locale = lang;
        I18n.dir = lang === "he" ? "rtl" : "ltr";

        let html = document.querySelector("html");
        html.setAttribute("lang", lang);
        html.setAttribute("dir", I18n.dir === "rtl" ? "rtl" : "ltr");

        moment.locale(lang);
    }, [language]);

    if (loading) return <CustomCircularProgress />;

    return (
        <>
            <Switch>
                <PrivateRoute
                    exact
                    path="/login"
                    component={LoginPage}
                    history={history}
                    redirect={isSpeaker ? "/conferences" : "/home"} // TODO: думаю это нужно убрать
                    access={!isLogged}
                />
                <PrivateRoute exact={!isLogged} path="/" component={AppRouter} redirect="/login" access={isLogged} />
                {!isLogged && <Redirect to="/login" />}
            </Switch>
        </>
    );
};

export default App;

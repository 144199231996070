import React, { useEffect, useState, useCallback } from "react";
import { RouteChildrenProps, useHistory, useParams } from "react-router";
import { TkConference, convertToMilis, I18n, categories } from "@tkbel/common";
import { makeStyles } from "@material-ui/styles";
import { FiberManualRecord } from "@material-ui/icons";
import SVG from "react-inlinesvg";
import moment from "moment";
import { Button, Modal } from "@material-ui/core";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { PageWrapper } from "../components";
import { red, linearBackgroud, white, purple } from "../helpers/colors";
import Loader from "../components/Loader";
import { getFormatD, getFormatHr } from "../helpers/dateFormat";
import { usePlayConferenceContext } from "../context/conferenceContext";
import { BackIcon, DomnloadIcon, HeartIcon2, StarIcon, UserIcon } from "../assets/images";
import { RootState } from "../redux/store";
import { onAddToWishlist, onRemoveFromWishlist } from "../redux/userReducer";
import { onGetConference, onStartConference } from "../redux/conferenceReducer";
import { onJoinCurrentConference } from "../redux/userReducer";
import { FUTURE, ONGOING, PAST } from "../utils/getStatus";
import { LockerIcon, PenIcon } from "../assets/images";
import getUpload from "../utils/getUrl";
import ShareButtons from "../components/ShareButtons";
import { useAppDispatch } from "../redux/hooks";

interface MatchParams {
    id: string;
}

interface LocalProps {
    previewConference: TkConference;
    closeModal: () => void;
    saveConf: () => void;
    createMode?: boolean;
}

type RouteProps = RouteChildrenProps<MatchParams>;
type Props = LocalProps | RouteProps;

function isLocal(props: Props): props is LocalProps {
    return (props as RouteProps).location === undefined;
}

const canJoinConference = (date) => {
    return parseInt((new Date(date).valueOf() - new Date().valueOf()) / 60000) < 30;
};

// function isRouter(props: Props): props is RouteProps {
//     return (props as RouteProps).location !== undefined;
// }

const ConferenceDetails: React.FC<Props> = (props) => {
    const { profile, isSpeaker } = useSelector((state: RootState) => state.user);
    const { speaker } = useSelector((state: RootState) => state.speaker);
    const [loading, setLoading] = useState(true);
    const [conference, setConference] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const startTime = conference && conference.startTime;
    const endTime = conference && conference.endTime;
    const [active, setActive] = useState(false);
    const [show, setShow] = useState(false); // eslint-disable-line
    const [currentCategory, setCurrentCategory] = useState<any>({});
    const [showEditBtn, setShowEditBtn] = useState(false);
    const [showPlayPopup, setShowPlayPopup] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const classes = useStyle({});
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [pdf, setPdf] = useState("");
    const [futureTime, setFutureTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
    });

    const { hashCode } = useParams();
    const playConferenceContext = usePlayConferenceContext();

    const getConfs = useCallback(() => {
        if (isLocal(props)) {
            setConference(props.previewConference);
            setLoading(false);
        } else if (hashCode) {
            dispatch(onGetConference(hashCode))
                .unwrap()
                .then((data) => {
                    setConference(data);
                    setLoading(false);
                });
        }
    }, [dispatch, hashCode, props]);

    useEffect(() => {
        if (conference) {
            if (props.createMode) setUser(profile);

            const _30_MIN_BEFORE_START = convertToMilis(moment(conference.startTime).add(-30, "m").toDate());
            const NOW = convertToMilis(new Date());

            setShowEditBtn(isSpeaker && !conference.actuallyStartTime && _30_MIN_BEFORE_START > NOW);

            setCurrentCategory(categories.find((item) => item.id === conference.categoryId));
        }
    }, [conference]); // eslint-disable-line

    useEffect(() => {
        if (speaker) setUser(profile);
    }, [profile, speaker]);

    useEffect(() => {
        conference && setActive(profile.wishList && !!profile.wishList.find((item) => item.id === conference.id));
    }, [profile, conference]);

    const toggleWishList = () => {
        dispatch(active ? onRemoveFromWishlist(conference.id) : onAddToWishlist(conference.id));
    };

    useEffect(() => {
        getConfs();
    }, [props]); // eslint-disable-line

    const getTimeOffset = () => {
        const timestamp = moment(startTime).diff(moment());

        setFutureTime({
            days:
                Math.floor(timestamp / (1000 * 60 * 60 * 24)) >= 0 ? Math.floor(timestamp / (1000 * 60 * 60 * 24)) : 0,
            hours:
                Math.floor((timestamp / (1000 * 60 * 60)) % 24) >= 0
                    ? Math.floor((timestamp / (1000 * 60 * 60)) % 24)
                    : 0,
            minutes: Math.floor((timestamp / (1000 * 60)) % 60) >= 0 ? Math.floor((timestamp / (1000 * 60)) % 60) : 0,
        });
    };

    useEffect(() => {
        if (conference && conference.status === FUTURE) {
            getTimeOffset();
            const interval = setInterval(getTimeOffset, 1000 * 60);
            return () => {
                clearInterval(interval);
            };
        }
    }, [conference]); // eslint-disable-line

    const getHours = () => {
        return moment(startTime).format(getFormatHr());
    };

    const getDuration = () => {
        let duration = moment.duration(moment(endTime).diff(moment(startTime))).asMilliseconds() / 1000;
        const hours = Math.floor(duration / 3600) % 24;
        duration -= hours * 3600;
        const minutes = Math.floor(duration / 60) % 60;
        return `${hours}h${minutes ? " " + minutes : ""}`;
    };

    // const countDownColumn = (title: string, value: number, last?: boolean) => {
    //     if (value < 0) return;
    //     return (
    //         <div className={classes.column} style={last ? { paddingRight: 0 } : {}}>
    //             <span className={classes.times}>{value}</span>
    //             <span className={classes.timesTitles}>{title}</span>
    //         </div>
    //     );
    // };

    const onLaunchConference = async () => {
        setShowPlayPopup(false);
        await dispatch(onStartConference({ id: conference.id }));
    };

    const onCheckPassword = useCallback(
        (password) => {
            setPasswordError(password && password !== conference.accessCode);
        },
        [conference],
    );

    const onChangePassword = (e) => {
        e.persist();
        setTimeout(() => setPassword(e.target.value), 300);
    };

    useEffect(() => {
        onCheckPassword(password);
    }, [password]); // eslint-disable-line

    const ConfirmPlayConference = () => {
        return (
            showPlayPopup && (
                <Modal className={classes.modal} onClose={() => setShowPlayPopup(false)} open={true}>
                    <div className={classes.wrapperDiv}>
                        <div className={classes.modalTitle}>{I18n.t("popupMessagePlayConf")}</div>
                        {!isSpeaker && conference.accessCode && (
                            <div className={classes.accessCodeBlock}>
                                <label className={classes.codeInputLabel}>{I18n.t("accessCode")}</label>
                                <input
                                    autoFocus
                                    className={classes.codeInput}
                                    defaultValue={password}
                                    onChange={onChangePassword}
                                />
                                {passwordError && (
                                    <div className={classes.accessErrorMessage}>{I18n.t("invalidCode")}</div>
                                )}
                            </div>
                        )}
                        <button
                            disabled={!isSpeaker && (!password || passwordError)}
                            onClick={() => {
                                if (isSpeaker) {
                                    onLaunchConference();
                                } else {
                                    onJoinConferenceUser();
                                }
                            }}
                            className={classes.modalBtn}
                        >
                            {I18n.t("launch")}
                        </button>
                    </div>
                </Modal>
            )
        );
    };

    const onClickToStartConference = () => {
        if (conference.actuallyStartTime) {
            goToPlayConference();
        } else {
            setShowPlayPopup(true);
        }
    };

    const goToPlayConference = () => {
        dispatch(onStartConference({ id: conference.id }));
    };

    const onJoinConferenceUser = () => {
        setShowPlayPopup(false);
        dispatch(onJoinCurrentConference({ id: conference.id }));
    };

    const onJoinConferenceWithModal = () => {
        if (conference.accessCode) {
            setShowPlayPopup(true);
        } else {
            onJoinConferenceUser();
        }
    };

    // const goToViewPDF = (fileId) => {
    //     isRouter(props) && props.history.push(`/conference/${fileId}/view`);
    // };
    //
    // const edit = () => {
    //     isRouter(props) && props.history.push(`/conference/${conference.id}/edit`);
    // };

    const getDate = () => {
        const format = getFormatD();
        return moment(startTime).format(format);
    };

    // const futureRecords =
    //     conference &&
    //     !conference.actuallyEndTime &&
    //     !isConferenceAborted(conference) &&
    //     conference.recordType &&
    //     !isLocal(props);

    const futureRecords = null;

    const isAudioContent = conference && conference.recordType === "audio" && conference.actuallyEndTime;
    const isVideoContent = conference && conference.recordType === "video" && conference.actuallyEndTime;
    const isPDFContent = conference && conference.documents && conference.documents.length > 0; // && conference.actuallyEndTime !== null;

    useEffect(() => {
        if (conference && conference.documents && conference.documents.length > 0) {
            conference.documents.map((row, i) => {
                if (i === 0) {
                    console.log("ROW", row);
                    if (row?.length) setPdf(getUpload(JSON.parse(row).path));
                }

                return row;
            });
        }
    }, [conference]);

    const onEditPress = () => {
        history.push(`/conference/${conference.id}/edit`, { conference });
    };

    const PlayConference = () => {
        // todo move to common/src/conference/utils
        //   const canStartConference = startTimeMinutesDiff <= 30 && endTimeMinutesDiff >= 0 && days === 0 && hours === 0;
        return isSpeaker ? (
            canJoinConference(conference.startTime) && (
                <div className={classes.pastConf}>
                    <button className={classes.confButton} onClick={onClickToStartConference}>
                        Start
                    </button>
                </div>
            )
        ) : (
            <div className={classes.pastConf}>
                <button className={classes.confButton} onClick={onJoinConferenceWithModal}>
                    Join
                </button>
            </div>
        );
    };

    return (
        <PageWrapper {...props}>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {<ConfirmPlayConference />}
                    <div className={classes.regularHeight}>
                        <div className={classes.wrapper}>
                            <div className={classes.wrapHeader}>
                                <div className={classes.imageWrapper}>
                                    <div className={classes.actions}>
                                        <BackIcon onClick={() => history.goBack()} />
                                        {conference.status === FUTURE && (
                                            <HeartIcon2 onClick={toggleWishList} className={active ? "active" : ""} />
                                        )}
                                    </div>
                                    <img
                                        alt="poster"
                                        className={classes.posterImg}
                                        src={getUpload(conference.posterImage)}
                                    />
                                    <div className={classes.title}>
                                        {conference.status === FUTURE ? (
                                            <>
                                                <div className={classNames(classes.days, classes.timeToStart)}>
                                                    {futureTime.days}
                                                    <p>Days</p>
                                                </div>
                                                <div className={classNames(classes.hours, classes.timeToStart)}>
                                                    {futureTime.hours} <p>Hours</p>
                                                </div>
                                                <div className={classes.timeToStart}>
                                                    {futureTime.minutes} <p>Minutes</p>
                                                </div>
                                            </>
                                        ) : conference.status === ONGOING ? null : (
                                            <div className={classes.pastConf}>
                                                {(isPDFContent || isAudioContent || isVideoContent) && (
                                                    <>
                                                        {isAudioContent && (
                                                            <a href="/#">
                                                                <DomnloadIcon /> Audio
                                                            </a>
                                                        )}
                                                        {isVideoContent && (
                                                            <a href="/#">
                                                                <DomnloadIcon /> Video
                                                            </a>
                                                        )}
                                                        {isPDFContent && pdf && (
                                                            <a
                                                                href={pdf}
                                                                target="_blank"
                                                                download="aaa.pdf"
                                                                rel="noreferrer"
                                                            >
                                                                <DomnloadIcon /> PDF
                                                            </a>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {!isLocal(props) && !props.previewConference && conference.status !== PAST && (
                                            <PlayConference />
                                        )}
                                    </div>
                                </div>
                                <div className={classes.wrapTimeEdit}>
                                    {/* {!isOngoing(conference) && (
                                    <div className={classes.textIconWrapper}>
                                        <span className={classes.modifyText} onClick={edit}>
                                            {I18n.t("modifier")}
                                        </span>
                                        <SVG src={iconPen} className={classes.iconPen} onClick={edit} />
                                    </div>
                                )} */}
                                    {isSpeaker && conference.accessCode && (
                                        <div className={classes.textIconWrapper}>
                                            <SVG src={LockerIcon} className={classes.accessIcon} />
                                            <span className={classes.accessCodeTitle}>{`${I18n.t(
                                                "accessCode",
                                            )} : `}</span>
                                            <span className={classes.accessCode}>{conference.accessCode}</span>
                                        </div>
                                    )}
                                    {/* <div className={classes.wrapCountDown}>
                                    {countDownColumn(I18n.t("days"), days)}
                                    <hr className={classes.columnHr} />
                                    {countDownColumn(I18n.t("hours"), hours)}
                                    <hr className={classes.columnHr} />
                                    {countDownColumn(I18n.t("moments"), moments, true)}
                                </div> */}
                                </div>
                            </div>
                            <div className={classes.topDetails}>
                                {showEditBtn && (
                                    <div className={classes.editBtn} onClick={onEditPress}>
                                        <PenIcon />
                                    </div>
                                )}
                                <div>
                                    <div className={classes.infoRow}>
                                        <div className={classes.infoCol}>
                                            <div className={classes.conferenceName}>
                                                {conference && conference.title}
                                            </div>
                                        </div>
                                        <div className={classNames(classes.infoCol, classes.infoColDate)}>
                                            <div className={classes.date}>{getDate()}</div>
                                            <div className={classes.timeDetails}>
                                                <div className={classes.time}>{getHours()}</div>
                                                <FiberManualRecord className={classes.icon} />
                                                <div className={classes.duration}>{getDuration()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.courseRating}>
                                        <p onClick={() => setShow(true)} className={classes.stars}>
                                            <StarIcon /> {conference.rating ? conference.rating : 0}
                                        </p>
                                        <p className={classes.reviews}>
                                            ({conference.reviews ? conference.reviews.length : 0} {I18n.t("reviews")})
                                        </p>
                                    </div>
                                    <div className={classes.information}>
                                        <div className={classes.infoRow}>
                                            <div className={classes.infoCol}>
                                                <span className={classes.infoTitle}>Information</span>
                                                <p className={classes.description}>{conference.description}</p>
                                            </div>
                                        </div>
                                        <span className={classes.infoTitle}>Records</span>
                                        <div>
                                            {conference.recordFiles?.split(",").map((rec, index) => {
                                                return (
                                                    <>
                                                        <a href={`/recordings/${rec}`}>Record {index + 1}</a>
                                                        <br />
                                                    </>
                                                );
                                            })}
                                        </div>
                                        <span className={classes.infoTitle}>Documents</span>
                                        <div>
                                            {conference.documents.map((row, index) => {
                                                const doc = row.length ? JSON.parse(row) : row;
                                                return (
                                                    <>
                                                        <a href={getUpload(doc.path)}>{doc.name}</a>
                                                        <br />
                                                    </>
                                                );
                                            })}
                                        </div>
                                        <span className={classes.infoTitle}>Speaker</span>
                                        <div className={classes.speakerContainer}>
                                            <div
                                                onClick={() =>
                                                    conference.speaker.id &&
                                                    history.push(`/speakerBoard/get/${conference.speaker.id}`)
                                                }
                                                className={classes.instructor}
                                            >
                                                <div className={classes.imgWrapper}>
                                                    <img
                                                        src={
                                                            conference.speaker &&
                                                            (conference.speaker.profileImage ||
                                                                user.profileSpeakerImage)
                                                                ? getUpload(
                                                                      conference.speaker.profileSpeakerImage ||
                                                                          conference.speaker.profileImage,
                                                                  )
                                                                : UserIcon
                                                        }
                                                        alt="avatar"
                                                    />
                                                </div>
                                                <div className={classes.instructorInfo}>
                                                    <p className={classes.instructorName}>
                                                        {conference.speaker
                                                            ? `${conference.speaker.firstName} ${conference.speaker.lastName}`
                                                            : `${user.firstName} ${user.lastName}`}
                                                    </p>
                                                    <div className={classes.rating}>
                                                        <p className={classes.stars}>
                                                            <StarIcon />{" "}
                                                            {conference.speaker && conference.speaker.rating
                                                                ? conference.speaker.rating
                                                                : 0}
                                                        </p>
                                                        <p className={classes.reviews}>
                                                            (
                                                            {conference.speaker && conference.speaker.reviews
                                                                ? conference.speaker.reviews.length
                                                                : 0}{" "}
                                                            {I18n.t("reviews")})
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.category}>
                                            <p className={classes.categoryTitle}>Category:</p>
                                            <p className={classes.categoryName}>
                                                {currentCategory ? currentCategory.name : ""}
                                            </p>
                                        </div>
                                        <ShareButtons
                                            className=""
                                            link={conference.hashCode}
                                            title="Share"
                                            shareTitle={conference.title}
                                            shareSummary={`My conference '${conference.title}' starts at ${moment(
                                                conference.startTime,
                                            ).format("MM.DD.YYYY HH:mm")}!`}
                                        />
                                        {conference.recordType &&
                                            conference.actuallyEndTime &&
                                            !conference.recording && (
                                                <span className={classes.waitingAudioText}>
                                                    {I18n.t("waitingForFiles")}
                                                </span>
                                            )}
                                        {futureRecords && (
                                            <span className={classes.waitingAudioText}>
                                                {I18n.t("recordWillBeAvailable")}
                                            </span>
                                        )}
                                        {/* {(isPDFContent || isAudioContent) && (
                                        <div>
                                            {isAudioContent && (
                                                <AudioContent
                                                    conference={conference}
                                                    id={conference.id}
                                                    goToPlayAudio={goToPlayAudio}
                                                />
                                            )}
                                            {isAudioContent && isPDFContent && (
                                                <div
                                                    style={{
                                                        borderTop: "0.031rem #F1F1F1 solid",
                                                        height: "0.1rem",
                                                        margin: " 1rem 6.3rem 0rem",
                                                    }}
                                                />
                                            )}
                                            {isPDFContent && (
                                                <PDFContent
                                                    conference={conference}
                                                    id={conference.id}
                                                    goToViewPDF={goToViewPDF}
                                                />
                                            )}
                                        </div>
                                    )} */}
                                        {/*<Reviews reviews={conference.reviews ? conference.reviews : []} />*/}
                                        {/*<ReviewModal*/}
                                        {/*    successCallback={getConfs}*/}
                                        {/*    course={conference}*/}
                                        {/*    show={show}*/}
                                        {/*    close={() => setShow(false)}*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {isLocal(props) && props.previewConference && (
                <div className={playConferenceContext.minimize ? classes.buttonMinimize : classes.buttonFull}>
                    <div className={classes.buttonsWrapper}>
                        <Button className={classes.editButton} onClick={props.closeModal}>
                            {I18n.t("cancel")}
                        </Button>
                        <Button className={classes.saveButton} onClick={props.saveConf}>
                            {I18n.t("save")}
                        </Button>
                    </div>
                </div>
            )}
        </PageWrapper>
    );
};

const useStyle = makeStyles({
    wrapper: {
        width: "1000px",
        maxWidth: "1000px",
        display: "flex",
        padding: "0 0 20px 0",
        flexDirection: "column",
        backgroundColor: "white",
        overflowY: "auto",
        height: "100%",
        borderRadius: "10px",
        // eslint-disable-next-line
        ["@media (max-width: 1050px)"]: {
            width: "100%",
        },
    },
    regularHeight: {
        height: "100%",
    },
    posterImg: {
        width: "100%",
        height: "400px",
        objectFit: "cover",
        backgroundColor: "#cfcccc",
        display: "block",
        borderRadius: "0 0 20px 20px",
    },
    title: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        fontWeight: 600,
        fontSize: "30px",
        textAlign: "left",
        borderRadius: "0 0 20px 20px",
        position: "absolute",
        bottom: "0",
        left: "0",
        zIndex: 10,
        background: "#1C2226",
        padding: "30px 10px",
        color: "#ffffff",
        boxShadow: "0px -20px 24px 1px rgba(92, 92, 92, 0.8)",
    },
    name: {
        color: red,
        fontSize: "1.4rem",
        fontWeight: 500,
        textAlign: "left",
    },
    topDetails: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 30px 15px",
        position: "relative",

        "& > div": {
            width: "100%",
        },
    },
    date: {
        textAlign: "end",
        fontSize: 14,
        fontWeight: 400,
    },
    duration: {
        fontSize: 15,
        color: "#A8A9AA",
        fontWeight: 600,
    },
    time: {
        fontWeight: 600,
        fontSize: 15,
    },
    timeDetails: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    icon: {
        fontSize: 8,
        margin: "0 3px",
    },
    address: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "5px 0 40px 0",

        "& > div": {
            display: "flex",
            alignItems: "center",
        },
    },
    locationName: {
        fontSize: "1.2rem",
        fontWeight: 600,
        marginRight: "0.5rem",
    },
    locationAddress: {
        fontSize: "1.1rem",
        fontWeight: 300,
        marginLeft: "0.5rem",
    },
    description: {
        fontFamily: "Roboto",
        fontWeight: 300,
        marginBottom: "10px",
        fontSize: 18,
        textAlign: "start",
        whiteSpace: "pre-line",
    },
    wrapHeader: {},
    imageWrapper: {
        width: "100%",
        position: "relative",
        marginBottom: "60px",

        "&::after": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            background: "#1f1f1fae",
            opacity: 0.5,
            borderRadius: "0 0 20px 20px",
            // width: '100%',
            // height: '100%'
        },
    },
    wrapCountDown: {
        display: "flex",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        padding: "0 2.5rem",
    },
    times: {
        textAlign: "center",
        fontSize: "1.6rem",
        fontWeight: 600,
    },
    timesTitles: {
        fontWeight: 300,
    },
    columnHr: {
        border: "none",
        borderLeft: "0.15rem solid hsl(200, 5%, 17%)",
        width: "1px",
    },
    wrapTimeEdit: {
        // padding: "8rem 6.5rem",
        // padding: "20px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end", //"flex-start",
        justifyContent: "flex-end",
    },
    textIconWrapper: {
        marginBottom: "auto", // "2.375rem",
        display: "flex",
        // alignItems: "baseline",
        // alignSelf: "center",
        alignItems: "center",
    },
    modifyText: {
        fontSize: "1.3rem",
        marginRight: "1rem",
        cursor: "pointer",
    },
    iconPen: {
        cursor: "pointer",
        width: "2rem",
        height: "3rem",
    },
    accessCode: {
        fontSize: "1.3rem",
        color: red,
        fontWeight: 500,
        alignSelf: "baseline",
    },
    accessCodeTitle: {
        fontSize: "1.3rem",
        marginRight: "0.5rem",
        fontWeight: "bold",
        alignSelf: "baseline",
    },
    accessIcon: {
        width: "1.875rem",
        height: "1.875rem",
        marginRight: "0.5rem",
        alignSelf: "baseline",
    },
    buttonsWrapper: {
        backgroundColor: "white",
        padding: "1.2rem",
        borderRadius: "0.5rem",
        boxShadow: "0px 3px 36px #00000029",
        // transform: "translateX(-50%)",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        maxWidth: "1000px",
    },
    buttonFull: {
        width: "100%",
        height: "100%",
        display: "flex",
        padding: "20px 0 20px 0",
        maxWidth: "1000px",
        overflowY: "auto",
        borderRadius: "10px",
        flexDirection: "column",
        backgroundColor: "white",
    },
    buttonMinimize: {
        position: "fixed",
        marginBottom: "1rem",
        width: "35rem",
        height: "5.5rem",
        bottom: "12rem",
        left: "50%",
    },
    saveButton: {
        backgroundImage: linearBackgroud,
        width: "45%",
        height: "3rem",
        fontSize: "1rem",
        color: "white",
        "&:hover": {
            backgroundColor: "#bdbdbd",
        },
    },
    editButton: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        width: "45%",
        height: "3rem",
        fontSize: "1rem",
        "&:hover": {
            backgroundColor: "#bdbdbd",
        },
    },
    days: {
        borderRight: "1px solid white",
    },
    hours: {
        borderRight: "1px solid white",
    },
    conferenceName: {
        fontSize: "32px",
        fontWeight: 500,
        textAlign: "left",
    },
    timeToStart: {
        display: "grid",
        placeItems: "center",
        // width: '50px',
        padding: "0 20px",
        // height: '50px',

        "& p": {
            fontSize: "12px",
            fontWeight: 300,
        },
    },
    actions: {
        width: "100%",
        padding: "0 30px",
        position: "absolute",
        display: "flex",
        justifyContent: "space-between",
        top: "30px",
        left: 0,
        zIndex: 10,

        "& svg": {
            width: "30px",
            height: "30px",
        },
        "& svg.active path": {
            fill: red,
        },
    },
    information: {
        margin: "15px 0 0 0",
        textAlign: "start",
    },
    infoTitle: {
        fontWeight: 300,
        fontSize: 20,
        margin: "20px 0 0",
        display: "block",
    },
    pastConf: {
        position: "absolute",
        display: "flex",
        width: "100%",
        bottom: 0,
        justifyContent: "center",
        transform: "translateY(50%)",
        gap: "10px",
        "& a": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            width: "200px",
            height: "40px",
            borderRadius: "25px",
            border: "none",
            color: white,
            background: linearBackgroud,
            cursor: "pointer",
            fontSize: "18px",

            "& svg": {
                width: "20px",
                height: "20px",

                "& path": {
                    fill: white,
                },
            },
        },
    },
    waitingAudioText: {
        fontWeight: 400,
        fontSize: "1.2rem",
        color: red,
        alignSelf: "flex-start",
    },

    speakerContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },

    instructor: {
        display: "flex",
        margin: "10px 0 30px 0",
        alignItems: "center",
        cursor: "pointer",
    },
    imgWrapper: {
        width: "92px",
        height: "92px",
        borderRadius: "50%",

        "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "50%",
        },
    },
    instructorInfo: {
        marginLeft: "10px",
    },
    instructorName: {
        fontSize: "18px",
    },
    rating: {
        display: "flex",
        alignItems: "center",
        gap: "15px",

        "& svg": {
            marginRight: "15px",
        },
    },
    stars: {
        display: "flex",
        alignItems: "center",
        color: "#8F9BB3",
    },
    reviews: {
        color: "#C5CEE0",
    },
    category: {
        width: "100%",
        display: "flex",
        marginBottom: "30px",
        borderBottom: "1px solid #8F9BB3",
        padding: "0 0 10px 0",
    },
    categoryTitle: {
        color: "#8F9BB3",
        fontSize: "20px",
    },
    categoryName: {
        fontSize: "20px",
        marginLeft: 10,
    },
    courseRating: {
        display: "flex",
        alignItems: "center",
        gap: "15px",

        "& svg": {
            marginRight: "15px",
        },
    },
    editBtn: {
        cursor: "pointer",
        position: "absolute",
        top: "15px",
        right: "15px",
        borderRadius: "100%",
        padding: "10px",
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 7px -1px rgba(0,0,0,0.15)",
        width: "50px !important",
        height: "50px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& svg": {
            width: "30px",
            height: "30px",
        },
    },
    confButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        width: "200px",
        height: "40px",
        borderRadius: "25px",
        border: "none",
        color: white,
        background: linearBackgroud,
        cursor: "pointer",
        fontSize: "18px",
    },
    modal: {},
    modalTitle: {
        fontSize: 20,
        margin: "0 0 15px",
    },
    modalBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        width: "200px",
        height: "40px",
        borderRadius: "25px",
        border: "none",
        color: white,
        background: linearBackgroud,
        cursor: "pointer",
        fontSize: "18px",

        "&:disabled": {
            opacity: 0.6,
            cursor: "not-allowed",
        },
    },
    wrapperDiv: {
        minHeight: 200,
        background: "#ffffff",
        width: 600,
        margin: "0 auto",
        top: "50%",
        position: "relative",
        transform: "translate(0, -50%)",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        // eslint-disable-next-line
        ["@media (max-width: 580px)"]: {
            width: "100%",
        },
    },
    infoRow: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    infoCol: {
        display: "block",
    },
    infoColDate: {
        minWidth: 120,
    },
    codeInput: {
        border: `1px solid ${purple}`,
        height: "30px",
        margin: "0 5px 10px 0",
        borderRadius: "6px",
        padding: "10px",
        fontSize: "14px",

        "&:focus, &:active": {
            outline: "none",
        },
    },
    codeInputLabel: {
        fontSize: 14,
        marginBottom: 5,
    },
    accessCodeBlock: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "280px",
    },
    accessErrorMessage: {
        color: red,
        fontSize: 12,
        fontWeight: 600,
        marginBottom: 20,
    },
});

export default ConferenceDetails;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import SVG from "react-inlinesvg";

import { red } from "../helpers/colors";

import iconFile from "../assets/images/icon_file.svg";

const EmptyList: React.FC<{
    title: string;
}> = ({ title }) => {
    const classes = useStyle({});
    return (
        <div className={classes.wrapper}>
            <SVG src={iconFile} className={classes.img} />
            <div className={classes.title}>{title}</div>
        </div>
    );
};

const useStyle = makeStyles({
    wrapper: {
        paddingTop: "5.5rem",
    },
    img: {
        marginBottom: 20,
        width: 200,
        height: 200,
    },
    title: {
        fontWeight: 600,
        fontSize: 16,
        color: red,
        marginBottom: 30,
    },
});

export default EmptyList;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router";

import { RateIcon, UserIcon } from "../../assets/images";
import getUpload from "../../utils/getUrl";

interface InstructorSlideTypes {
    slide: any;
}

const InstructorSlide: React.FC<InstructorSlideTypes> = ({ slide }) => {
    const classes = useStyles({});
    const history = useHistory();
    const { id, profileImage, profileSpeakerImage, firstName, lastName, rating } = slide;

    return (
        <div onClick={() => history.push(`/speakerBoard/get/${id}`)} className={classes.slide}>
            <div className={classes.image}>
                {profileSpeakerImage || profileImage ? (
                    <img className={classes.img} src={getUpload(profileSpeakerImage || profileImage)} alt="speaker" />
                ) : (
                    <SVG src={UserIcon} />
                )}
            </div>
            <div className={classes.name}>
                {firstName} {lastName}
            </div>
            <div className={classes.type} />
            {rating && (
                <div className={classes.rating}>
                    <SVG src={RateIcon} />
                    {rating}
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles({
    slide: {
        textAlign: "center",
        cursor: "pointer",
    },
    image: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "120px",
        height: "120px",
        borderRadius: "50%",
        overflow: "hidden",
        margin: "0 auto 10px",
        // padding: '20px',
        boxSizing: "border-box",
        background: "#eeebea",
        "& svg": {
            width: "80px",
            height: "80px",
            fill: "#6f6f6f",
        },
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    name: {
        color: "#222b45",
        width: "100%",
        fontSize: "20px",
        margin: "5px 0",
    },
    rating: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#8f9bb3",
        fontSize: "20px",
        "& svg": {
            width: "20px",
            height: "20px",
            margin: "0 6px 0 0",
        },
    },
    type: {
        color: "#8f9bb3",
        width: "100%",
        fontSize: "19px",
        margin: "5px 0",
    },
    reviews: {
        color: "#c5cee0",
        fontSize: "13px",
        margin: "0 0 0 6px",
    },
});

export default InstructorSlide;

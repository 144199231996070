import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import logger from "redux-logger";

import userReducer from "./userReducer";
import speakerReducer from "./speakerReducer";
import searchReducer from "./searchReducer";
import courseReducer from "./coursesReducer";
import conferenceReducer from "./conferenceReducer";
import * as Flatted from "flatted";

export const transformCircular = createTransform(
    (inboundState) => Flatted.stringify(inboundState),
    (outboundState) => Flatted.parse(outboundState),
);

const rootPersistConfig = {
    key: "rootStorage",
    storage: storage,
    whitelist: ["user"],
    transforms: [transformCircular],
};

const middleware: Middleware[] = [thunk];
if (process.env.NODE_ENV === "development" && process.env.REACT_APP_REDUX_LOGGER === "true") {
    middleware.push(logger);
}

const rootReducer = combineReducers({
    user: userReducer,
    speaker: speakerReducer,
    search: searchReducer,
    courses: courseReducer,
    conference: conferenceReducer,
});

const pReducer = persistReducer(rootPersistConfig, rootReducer);
export const store = configureStore({
    reducer: pReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { persistor };

import { darken, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { PageWrapper, Slider } from "../../../components";
import PageCardWrapper from "../../../components/PageCardWrapper";
import { linearBackgroud, white } from "../../../helpers/colors";
import { StarIcon, UserIcon } from "../../../assets/images";
import Loader from "../../../components/Loader";
// import Reviews from "../../../components/Reviews";
import { I18n } from "@tkbel/common";
import { getProfile } from "../../../redux/userReducer";
import { useAppDispatch } from "../../../redux/hooks";
import getUpload from "../../../utils/getUrl";
// import ReviewModal from "../../../components/ReviewModal";

const SpeakerBoardPage = (props) => {
    const [futureCourses, setFutureCourses] = useState<any[]>([]);
    const [pastCourses, setPastCourses] = useState<any[]>([]);
    const [show, setShow] = useState(false); // eslint-disable-line
    const [speaker, setSpeaker] = useState<any>({}); // eslint-disable-line
    const classes = useStyles({});
    const dispatch = useAppDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getProfile({ id }))
            .unwrap()
            .then((result) => {
                setSpeaker(result);
            });
    }, [id]); // eslint-disable-line

    useEffect(() => {
        const setConferences = () => {
            let past = [];
            let future = [];
            if (speaker && speaker.speakingConferences && speaker.speakingConferences.length) {
                past = speaker.speakingConferences.filter((item) => item.type && item.actuallyStartTime);
                future = speaker.speakingConferences.filter(
                    (item) => item.status === "Future" || item.status === "OnGoing",
                );
            }

            setPastCourses(past);
            setFutureCourses(future);
        };

        setConferences();
    }, [speaker]);

    return (
        <PageWrapper {...props}>
            <PageCardWrapper {...props}>
                {!Object.keys(speaker || {}).length ? (
                    <Loader />
                ) : (
                    <div className={classes.wrapper}>
                        <div className={classes.header}>
                            <div className={classes.imgWrapper}>
                                <img
                                    src={speaker.profileImage ? getUpload(speaker.profileImage) : UserIcon}
                                    alt="avatar"
                                />
                            </div>
                            <div className={classes.info}>
                                <div className={classes.speakerInfo}>
                                    <p className={classes.name}>
                                        {speaker.firstName} {speaker.lastName}
                                    </p>
                                    <div className={classes.rating}>
                                        <p className={classes.stars} onClick={() => setShow(true)}>
                                            <StarIcon /> {speaker.rating ? speaker.rating : 0}
                                        </p>
                                        <p className={classes.reviews}>
                                            ({speaker.reviews ? speaker.reviews.length : 0} {I18n.t("reviews")})
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.content}>
                            <span className={classes.titlePage}>{I18n.t("biography")}</span>
                            <p className={classes.biography}>{speaker.biography}</p>
                        </div>
                        {speaker.titles && (
                            <div className={classes.content}>
                                <span className={classes.titlePage}>{I18n.t("titles")}</span>
                                <p className={classes.biography}>{speaker.titles}</p>
                            </div>
                        )}
                        {speaker.booksAndArticles && (
                            <div className={classes.content}>
                                <span className={classes.titlePage}>{I18n.t("booksAndArticles")}</span>
                                <p className={classes.biography}>{speaker.booksAndArticles}</p>
                            </div>
                        )}
                        {!!futureCourses.length && (
                            <Slider
                                title={I18n.t("futureOngoing")}
                                titleSize={18}
                                slides={futureCourses}
                                slideWrapper="course"
                                // slidesPerView={5}
                                breakpoints={{
                                    1200: {
                                        slidesPerView: 5,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                    670: {
                                        slidesPerView: 2,
                                    },
                                    420: {
                                        slidesPerView: 2,
                                    },
                                    300: {
                                        slidesPerView: 2,
                                    },
                                }}
                            />
                        )}
                        {!!pastCourses.length && (
                            <Slider
                                title={I18n.t("past")}
                                titleSize={18}
                                slides={pastCourses}
                                slideWrapper="course"
                                // slidesPerView={5}
                                breakpoints={{
                                    1200: {
                                        slidesPerView: 5,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                    670: {
                                        slidesPerView: 2,
                                    },
                                    420: {
                                        slidesPerView: 2,
                                    },
                                    300: {
                                        slidesPerView: 2,
                                    },
                                }}
                            />
                        )}
                        {/*<ReviewModal*/}
                        {/*    instructor={speaker}*/}
                        {/*    show={show}*/}
                        {/*    close={() => setShow(false)}*/}
                        {/*    successCallback={successCallback}*/}
                        {/*/>*/}
                        {/*<Reviews reviews={speaker.reviews ? speaker.reviews : []} />*/}
                    </div>
                )}
            </PageCardWrapper>
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        maxWidth: "1350px",
        textAlign: "left",
        padding: "30px",
    },
    header: {
        display: "flex",
        width: "100%",
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            display: "block !important",
        },
    },
    imgWrapper: {
        width: "200px",
        height: "200px",
        background: darken("#ffffff", 0.3),
        borderRadius: "100%",
        marginRight: "10px",
        overflow: "hidden",

        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        "& svg": {
            width: "150px",
            height: "150px",
        },
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            width: "100%",
        },
    },
    content: {
        marginTop: "20px",
    },
    name: {
        fontSize: "26px",
        fontWeight: 500,
        color: "#000",
    },
    info: {
        display: "flex",
        justifyContent: "space-between",
        width: "calc(100% - 220px)",
        alignItems: "center",
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            width: "100%",
        },
    },
    biography: {
        fontSize: 14,
        textAlign: "justify",
        // marginBottom: "20px",
    },
    titlePage: {
        fontSize: 18,
        marginBottom: 6,
        display: "block",
    },
    certificats: {
        width: "100%",
        marginTop: "20px",
        "& p": {
            fontSize: "18px",
            fontWeight: 700,
        },

        "& span": {
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "start",
        },
    },
    contacts: {
        marginTop: "20px",
    },
    contact: {
        width: "100%",
        marginTop: "10px",
        borderBottom: "1px solid #EDF1F7",
        padding: "5px",
    },
    email: {
        fontSize: "16px",
        fontWeight: 500,
    },
    phone: {
        fontSize: 14,
        fontWeight: 500,
        color: "#9b9b9b",
        padding: 0,
    },
    certificateWrapper: {
        display: "flex",
        alignItems: "center",
    },
    backIcon: {
        position: "absolute",
        top: "10px",
        left: "10px",
        width: "30px",
        height: "30px",
    },

    edit: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "20px",
        width: "30px",
        height: "30px",
    },
    rating: {
        display: "flex",
        alignItems: "center",
        gap: "15px",

        "& svg": {
            marginRight: "15px",
        },
    },
    stars: {
        display: "flex",
        alignItems: "center",
        color: "#8F9BB3",
    },
    reviews: {
        color: "#C5CEE0",
    },
    speakerInfo: {},
    following: {
        width: "100px",
        height: "30px",
        background: linearBackgroud,
        border: "none",
        color: white,
        borderRadius: "6px",
        cursor: "pointer",
    },
});

export default SpeakerBoardPage;

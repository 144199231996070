import React from "react";
import { makeStyles } from "@material-ui/styles";
import { I18n } from "@tkbel/common";
import SVG from "react-inlinesvg";

import iconPlus from "../assets/images/Icon_plus.svg";

const CreateConfBtn: React.FC<{
    createConfPress: () => void;
}> = ({ createConfPress }) => {
    const classes = useStyle({});
    return (
        <div className={classes.wrapper}>
            <div className={classes.clickDiv} onClick={createConfPress}>
                <SVG src={iconPlus} />
                <span className={classes.span}>{I18n.t("createNewConf")}</span>
            </div>
        </div>
    );
};

const useStyle = makeStyles({
    wrapper: {
        display: "flex",
        marginBottom: "20px",
        alignItems: "center",
    },
    clickDiv: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    span: {
        fontSize: "16px",
        fontWeight: 600,
        marginLeft: "10px",
    },
});

export default CreateConfBtn;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    courses: [],
    filtratingCourses: [],
};

export const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {
        searchCourses: (state, action: PayloadAction<string>) => {
            const search = action.payload.toLowerCase();

            state.filtratingCourses = state.courses.filter((item) => item.data().title.toLowerCase().includes(search));
        },
        filter: (
            state,
            action: PayloadAction<{ category: any[]; country: any[]; language: any[]; search: string }>,
        ) => {
            const filterCallback = (item) => {
                const { category, country, language } = action.payload;

                const filterCategory = category.filter((item) => item.value).map((item) => item.id);
                const filterLanguage = language.filter((item) => item.value).map((item) => item.name);
                const filterCountry = country.filter((item) => item.value).map((item) => item.name);

                const additionalFilters =
                    (filterCategory.length ? filterCategory.includes(item.data().categoryId) : true) &&
                    (filterLanguage.length ? filterLanguage.includes(item.data().language) : true) &&
                    (filterCountry.length ? filterCountry.includes(item.data().location.country) : true);

                return (
                    item.data().title.toLowerCase().includes(action.payload.search.toLowerCase()) && additionalFilters
                );
            };
            state.filtratingCourses = state.courses.filter((item) => filterCallback(item));
        },
    },
});

const { searchCourses, filter } = coursesSlice.actions;

export { searchCourses, filter };

export default coursesSlice.reducer;

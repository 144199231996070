import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import AddOrEditConf from "./addOrEditConf";
import HomePage from "./homePage";
import CoursePage from "./coursePage";
import ConferencesList from "./conferencesList";
import EmailValidated from "./emailValidated";
import ConferenceDetails from "./conferenceDetails";
import Profile from "./profile";
import WishList from "./wishlist";
import AllCategories from "./allCategories";
import CategoryPage from "./CategoryPage";
import ProfileDetail from "./profileDetail";
import { RootState } from "../redux/store";
import SpeakerBoardEdit from "./speakerBoard/speakerBoardEdit";
import SpeakerBoardPage from "./speakerBoard/speakerBoardPage";
import SpeakerConferenceScreen from "./SpeakerConferenceScreen";
import Register from "./register";
import CourseReview from "./CourseReview";
import PlayAudio from "./playAudio";
import PDFView from "./PDFView";
import Search from "./search/search";
import { useHistory } from "react-router";
import AllConferences from "./allConferences";
import HistoryPage from "./historyPage";

const AppRouter: React.FC<{}> = () => {
    const { profile, isSpeaker } = useSelector((state: RootState) => state.user);
    const { currentConference } = useSelector((state: RootState) => state.conference, shallowEqual);
    const history = useHistory();
    const location = useLocation();
    const [userConference, setUserConference] = useState<any>({});

    // if (!profile.firstName || !profile.lastName || !profile.email) {
    //     return (
    //         <Switch>
    //             <Redirect to="/register" />
    //         </Switch>
    //     );
    // }

    // useEffect(() => {
    //     dispatch(onCheckRunningConference({ userId: profile.id }));
    // }, []); // eslint-disable-line

    useEffect(() => {
        if (location.pathname !== "/playConference" && isSpeaker && currentConference && currentConference.id) {
            history.push("/playConference");
        } else if (!isSpeaker) {
            setUserConference(profile.currentConferenceId ? profile.currentConference : {});
        }
    }, [profile]); // eslint-disable-line

    return (
        <>
            <Switch>
                <>
                    <Route exact path="/profile" component={Profile} />
                    <Route exact path="/profile/details" component={ProfileDetail} />
                    <Route exact path="/speakerBoard/edit" component={SpeakerBoardEdit} />
                    <Route exact path="/speakerBoard/get/:id" component={SpeakerBoardPage} />
                    <Route exact component={ConferenceDetails} path="/conference/:hashCode" />
                    <Route exact component={ConferencesList} path="/conferences/" />
                    <Route exact component={ConferencesList} path="/conferences/:filter" />
                    <Route exact component={PDFView} path="/conference/:id/view" />
                    <Route path="/home" component={HomePage} />
                    <Route exact path="/register" component={Register} />
                    <Route exact component={SpeakerConferenceScreen} path="/playConference" />
                    {isSpeaker ? (
                        <>
                            <Route exact path="/speakerBoard/edit/:id" component={SpeakerBoardEdit} />
                            <Route exact component={AddOrEditConf} path="/conference/new/form" />
                            <Route exact component={AddOrEditConf} path="/conference/:id/edit" />
                            {currentConference && (
                                <>
                                    <Redirect to="/playConference" />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Route path="/wishlist" component={WishList} />
                            <Route path="/allcategories" component={AllCategories} />
                            <Route path="/category/:id" component={CategoryPage} />
                            <Route path="/course/:id" component={CoursePage} />
                            <Route component={EmailValidated} path="/validateEmailResult" />
                            <Route exact component={AllConferences} path="/allconferences/:type" />
                            <Route exact component={CourseReview} path="/conference/:id/review" />
                            <Route exact component={HistoryPage} path="/history" />
                            <Route exact component={Search} path="/search" />
                            <Redirect to="/home" />
                        </>
                    )}
                </>
            </Switch>
            {userConference && userConference.id && !isSpeaker && <PlayAudio />}
        </>
    );
};

export default AppRouter;

import moment from "moment";

export const getStatusAndTime = (conf: any): { status: "past" | "ongoing" | "future"; utils?: any } => {
    const startTime = conf && moment(conf.startTime);
    const endTime = conf && moment(conf.endTime);
    const actualityStartTime = conf && moment(conf.actuallyStartTime);
    const actualityEndTime = conf && moment(conf.actuallyEndTime);

    const getTimeOffset = () => {
        const timestamp = moment(startTime).diff(moment());

        return {
            days: Math.floor(timestamp / (1000 * 60 * 60 * 24)),
            hours: Math.floor((timestamp / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((timestamp / (1000 * 60)) % 60),
        };
    };

    if (moment(endTime).isBefore(moment(), "minutes")) {
        let duration = moment(actualityEndTime).diff(actualityStartTime, "milliseconds");
        const hours = moment.duration(duration, "milliseconds").hours();
        const minutes = moment.duration(duration, "milliseconds").minutes();
        return {
            status: "past",
            utils: `${hours ? hours + "h " : ""}${minutes ? minutes + "m" : "0m"}`,
        };
    } else if (moment(startTime).isBefore(moment(), "minutes") && moment(endTime).isAfter(moment(), "minutes")) {
        return { status: "ongoing", utils: null };
    } else {
        return { status: "future", utils: getTimeOffset() };
    }
};

import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import cx from "classnames";
import moment from "moment";

import { RateIcon, HeartIcon, HeadphoneIcon, VideoIcon, FileIcon } from "../../assets/images";
import { linearBackgroud, red } from "../../helpers/colors";
import { getStatusAndTime } from "../../helpers/getStatusAndTime";
// import { addToWishlist } from "../../firebase/library";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
// import { addToWishlistLocal } from "../../redux/userReducer";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import { I18n } from "@tkbel/common";
import getUpload from "../../utils/getUrl";

interface CourseSlideTypes {
    slide: any;
    history?: any;
}

const CourseSlide: React.FC<CourseSlideTypes> = ({ slide, history }) => {
    const { profile } = useSelector((state: RootState) => state.user);
    const [active, setActive] = useState(false);
    const classes = useStyles({});
    const [statusAndUtils, setStatusAndUtils] = useState({} as { status: "ongoing" | "future" | "past"; utils?: any });
    const confDate = moment(slide.startTime).format("DD.MM.YYYY");

    const cityToDisplay = slide.city;

    useEffect(() => {
        setStatusAndUtils(getStatusAndTime(slide));
    }, [slide]);

    useEffect(() => {
        setActive(profile.wishlist && !!profile.wishlist.find((item) => item.id === slide.id));
    }, [profile]); // eslint-disable-line

    // const successCallback = (ref: firebase.firestore.DocumentReference) => {
    //     dispatch(addToWishlistLocal(ref));
    // };

    // const add = (e: React.MouseEvent<SVGElement>) => {
    //     e.stopPropagation();
    //     addToWishlist(slide.id, profile.id, successCallback);
    // };

    const showConference = useCallback(
        (hashCode) => {
            history.push(`/conference/${hashCode}`);
        },
        [history],
    );

    // @ts-ignore
    // @ts-ignore
    return (
        <div onClick={() => showConference(slide.hashCode)} className={classes.slide}>
            <div className={classes.image}>
                <img className={classes.img} src={getUpload(slide.posterImage) || ""} alt="" />
            </div>
            <div className={classes.title}>
                <p>{slide.title || ""}</p>
                {statusAndUtils.status === "future" && (
                    <SVG onClick={() => {}} className={active ? "active" : ""} src={HeartIcon} />
                )}
            </div>
            <div className={classes.bottomPart}>
                <div className={classes.speaker}>
                    {/*<p>{slide?.speaker?.name || ""}</p>*/}
                    <p>
                        {statusAndUtils.status === "future" ? (
                            <span className={classes.ongoing}>{confDate}</span>
                        ) : statusAndUtils.status === "ongoing" ? (
                            <span className={classes.ongoing}>Ongoing</span>
                        ) : (
                            `${statusAndUtils.utils}`
                        )}
                    </p>
                </div>
                {cityToDisplay && (
                    <div className={classes.location}>
                        <LocationOnIcon />
                        {cityToDisplay}
                    </div>
                )}
                <div className={classes.rateAndIcons}>
                    <div className={classes.rating}>
                        {slide.rating && (
                            <div className={classes.rateSign}>
                                {slide.rating}
                                <SVG src={RateIcon} />
                            </div>
                        )}
                        <div className={classes.reviews}>
                            ({slide.reviews ? slide.reviews.length : 0} {I18n.t("reviews")})
                        </div>
                    </div>
                    {statusAndUtils.status === "past" ? (
                        <div className={classes.iconsContainer}>
                            {slide.documents && slide.documents.length && (
                                <div className={classes.iconContainer}>
                                    <img src={FileIcon} className={classes.file} alt="fileIcon" />
                                    <span className={classes.iconText}>File</span>
                                </div>
                            )}
                            {slide.recordType ? (
                                <div className={classes.iconContainer}>
                                    <img
                                        src={slide.recordType === "video" ? VideoIcon : HeadphoneIcon}
                                        className={cx(
                                            classes.headphone,
                                            slide.recordType === "video" && classes.videoIcon,
                                        )}
                                        alt="videoIcon"
                                    />
                                    <span className={classes.iconText}>
                                        {slide.recordType === "video" ? "Video" : "Audio"}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
                {slide.price > 0 && <div className={classes.price}>{slide.price}</div>}
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    slide: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
    },
    image: {
        width: "100%",
        height: "150px",
        borderRadius: "10px",
        overflow: "hidden",
        margin: "0 0 10px",
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    title: {
        color: "#222b45",
        width: "100%",
        fontSize: "18px",
        margin: "5px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& > p": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },

        "& svg": {
            padding: "0 0 0 10px",
        },

        "& svg path": {
            transition: "fill .2s linear",
        },

        "& svg.active path": {
            fill: "red",
        },
    },
    rating: {
        display: "flex",
        alignItems: "center",
    },
    price: {
        color: "#fd0053",
        fontSize: "16px",
        fontWeight: 600,
        margin: "10px 0 0",
    },
    ongoing: {
        color: red,
    },
    button: {
        height: "50px",
        padding: "10px",
        boxSizing: "border-box",
        fontSize: "14px",
        fontWeight: 600,
        background: linearBackgroud,
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "10px 0 0",
        borderRadius: "4px",
        cursor: "pointer",
    },
    speaker: {
        display: "flex",
        justifyContent: "space-between",
        color: "#8f9bb3",
        fontSize: 14,
        margin: "5px 0",
    },
    rateSign: {
        display: "flex",
        alignItems: "center",
        color: "#8f9bb3",
        fontSize: "13px",
        "& svg": {
            margin: "0 4px 0 0",
        },
    },
    reviews: {
        color: "#c5cee0",
        fontSize: "13px",
        margin: "0 0 0 6px",
    },
    bottomPart: {
        margin: "auto 0 0",
    },
    file: {
        width: "24px",
        height: "24px",
        objectFit: "scale-down",
    },
    iconText: {
        margin: "5px 0 0",
        fontSize: "12px",
        color: "#ED034E",
    },
    headphone: {
        width: "24px",
        height: "24px",
        objectFit: "scale-down",
    },
    videoIcon: {
        width: "24px",
        height: "24px",
        objectFit: "scale-down",
    },
    rateAndIcons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    iconsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 0 0 5px",
    },
    location: {
        display: "flex",
        alignItems: "center",
        color: "#6e727a",
        fontSize: "13px",
        margin: "0 0 6px",
        "& svg": {
            margin: "0 5px 0 0",
        },
    },
});

export default CourseSlide;

import React from "react";

import SpeakerColoredHeader from "./SpeakerColoredHeader";
import SpeakerProfileHeader from "./SpeakerProfileHeader";
import { usePlayConferenceContext } from "../context/conferenceContext";

const BasePage: React.FC<{ title?: string }> = ({ children, title }) => {
    const playingConferenceContext = usePlayConferenceContext();
    return (
        <div
            style={
                playingConferenceContext.minimize
                    ? { height: "80%", position: "relative", overflowY: "auto" }
                    : { height: "100%", position: "relative", overflowY: "auto" }
            }
        >
            <SpeakerProfileHeader />
            <SpeakerColoredHeader title={title ? title : null} />
            {children}
        </div>
    );
};

export default BasePage;

import React, { useCallback, useEffect, useState } from "react";
import { ConferenceTabs, I18n, TAB_NAME_KEYS } from "@tkbel/common";
import { RouteChildrenProps } from "react-router";
import { List, ListItem, Tab, Tabs } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { red } from "../helpers/colors";
import SectionItem from "../components/SectionItem";
import SearchBar from "../components/SearchBar";
import CreateConfBtn from "../components/CreateConfBtn";
import EmptyList from "../components/EmptyList";
import Loader from "../components/Loader";
import { PageWrapper } from "../components";
import { getFormatDD } from "../helpers/dateFormat";
import { RootState } from "../redux/store";
import Library from "../components/Library";
import { onGetConferences, onGetSpeakerConferences } from "../redux/conferenceReducer";

interface StyledTabsProps {
    label: string;
}

const CustomTabs = withStyles({
    root: {
        borderBottom: "none",
    },
    indicator: {
        backgroundColor: red,
    },
})(Tabs);

const CustomTab = withStyles((theme) =>
    createStyles({
        root: {
            textTransform: "none",
            minWidth: 72,
            width: "auto",
            marginRight: theme.spacing(4),
            color: "#0000004a",
            fontWeight: 400,
            fontSize: 14,
            marginBottom: 15,
            "&$selected": {
                color: "black",
            },
        },
        selected: {},
    }),
)((props: StyledTabsProps) => <Tab {...props} />);

const ConferencesList: React.FC<RouteChildrenProps<{ filter: string }>> = (props) => {
    const { profile, isSpeaker } = useSelector((state: RootState) => state.user);
    const { conferences } = useSelector((state: RootState) => state.conference);
    const filter = props.match.params.filter;
    const currentTab = filter === "past" ? ConferenceTabs.PAST_CONFS : ConferenceTabs.FUTURE_CONFS;
    const search = props.location.hash.startsWith("#") ? props.location.hash.slice(1) : props.location.hash;
    const [searchEnter, setSearchEnter] = useState(false);
    const classes = useStyles({});
    const [loading, setLoading] = useState(false);
    const [currentConferences, setCurrentConferences] = useState<any[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        if (profile && profile.id) {
            dispatch(isSpeaker ? onGetSpeakerConferences({ speakerId: profile.id }) : onGetConferences());
        }
    }, [dispatch, isSpeaker, profile]);

    useEffect(() => {
        setLoading(true);
        if (profile && profile.id) {
            dispatch(
                isSpeaker ? onGetSpeakerConferences({ speakerId: profile.id, search: search }) : onGetConferences(),
            );
        }
    }, [searchEnter]); // eslint-disable-line

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        props.history.push(newValue === ConferenceTabs.PAST_CONFS ? "/conferences/past" : "/conferences/");
    };

    const onClickItem = (hashCode: string) => {
        props.history.push(`/conference/${hashCode}`);
    };

    const onGroupData = useCallback(() => {
        const groupedData = [];
        let day = null;
        let dayData = [];

        const status = currentTab === ConferenceTabs.PAST_CONFS ? "Past" : "Future";
        const _currentConferences = conferences.filter((conf) => conf.status === status);

        for (const conference of _currentConferences) {
            const data = conference;
            const currentItemDay = moment(data.startTime).startOf("day");

            if (!currentItemDay.isSame(day)) {
                day = currentItemDay;
                dayData = [];
                groupedData.push({ day, data: dayData });
            }
            if (moment(data.endTime).isAfter(moment()) || isSpeaker) {
                dayData.push(conference);
            }
        }

        setCurrentConferences(groupedData);
    }, [conferences, currentTab, isSpeaker]);

    useEffect(() => {
        setLoading(false);
        onGroupData();
    }, [conferences, onGroupData]);

    const renderSection = (items) => {
        return items.map((item) => (
            <SectionItem
                conference={item}
                id={item.id}
                search={search.length > 1 ? search : ""}
                onClickItem={onClickItem}
            />
        ));
    };

    const renderList = () => {
        return currentConferences.length > 0 ? (
            currentConferences.map((item, i) => {
                let label: string;
                if (item.day.isSame(moment(), "day")) {
                    label = I18n.t("today");
                } else if (item.day.isSame(moment().add(1, "day"), "day")) {
                    label = I18n.t("tomorrow");
                } else if (item.day.isSame(moment().add(-1, "day"), "day")) {
                    label = I18n.t("yesterday");
                } else {
                    label = item.day.format(getFormatDD());
                }
                return (
                    <div key={`list section ${i}`}>
                        <ListItem className={classes.sectionTitle} key={i}>
                            {label}
                        </ListItem>
                        <List key={"list" + i}>{renderSection(item.data)}</List>
                    </div>
                );
            })
        ) : (
            <EmptyList title={getEmptyListTitle()} />
        );
    };

    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const getEmptyListTitle = () => {
        if (search.length > 0) {
            return I18n.t("noResults");
        }
        if (currentTab === ConferenceTabs.PAST_CONFS) {
            return I18n.t("noRPastConfs");
        }
        return I18n.t("noRFuturesConfs");
    };

    const setSearch = (value) => {
        props.history.replace({ ...props.location, hash: value });
    };

    return loading ? (
        <Loader />
    ) : (
        <PageWrapper {...props}>
            {isSpeaker ? (
                <div className={classes.pageContainer}>
                    <SearchBar search={search} setSearch={setSearch} setSearchEnter={setSearchEnter} />
                    <CreateConfBtn createConfPress={() => props.history.push("/conference/new/form")} />
                    <div className={classes.wrapper}>
                        <CustomTabs className={classes.tabs} value={currentTab} onChange={handleChangeTab}>
                            <CustomTab
                                className={classes.tabsButton}
                                label={I18n.t(TAB_NAME_KEYS[ConferenceTabs.FUTURE_CONFS])}
                                {...tabsProps(0)}
                            />
                            <CustomTab
                                className={classes.tabsButton}
                                label={I18n.t(TAB_NAME_KEYS[ConferenceTabs.PAST_CONFS])}
                                {...tabsProps(1)}
                            />
                        </CustomTabs>
                        {conferences.length !== 0 ? (
                            <List className={classes.list}>{renderList()}</List>
                        ) : (
                            <EmptyList title={getEmptyListTitle()} />
                        )}
                    </div>
                </div>
            ) : (
                <Library className={classes.library} {...props} />
            )}
        </PageWrapper>
    );
};

const useStyles = makeStyles({
    pageContainer: {
        padding: "40px 20px",
        width: "100%",
        maxWidth: 1000,
        boxSizing: "border-box",
        // eslint-disable-next-line
        ["@media (max-width: 578px)"]: {
            padding: "0px 0px",
        },
    },
    wrapper: {
        backgroundColor: "white",
    },
    tabs: {
        marginBottom: "1.5rem",
    },

    tabsButton: {
        // eslint-disable-next-line
        ["@media (max-width: 420px)"]: {
            marginRight: "5px",
            marginBottom: "5px",
            fontSize: "12px",
        },
    },
    sectionTitle: {
        fontWeight: 500,
        fontSize: 14,
        borderBottom: " 1px #00000026 solid",
        marginBottom: 15,
    },
    list: {
        maxHeight: "33rem",
        overflow: "auto",
    },
    rating: {
        display: "flex",
        alignItems: "center",
    },
    price: {
        color: "#fd0053",
        fontSize: "16px",
        fontWeight: 600,
        margin: "10px 0 0",
    },
    container: {
        width: "100%",
        minHeight: "815px",
        margin: "22px auto 99px",
        "& > div": {
            padding: "0 0 30px 0",
        },
    },
    pageHeader: {
        marginTop: "36px",
        letterSpacing: "1.88px",
        color: "#1E2022",
        fontWeight: 400,
        fontSize: "30px",
    },
    library: {
        marginTop: "49px",
    },
});

export default ConferencesList;

import React from "react";
import useWindowSize from "../../hook/useWindowSize";
import HeaderDesktop from "./_types/HeaderDesktop";
import HeaderMobile from "./_types/HeaderMobile";

interface HeaderTypes {
    location: {
        hash: string;
    };
    history?: any;
}

const Header: React.FC<HeaderTypes> = () => {
    const windowSize = useWindowSize();

    return windowSize.width < 577 ? <HeaderMobile /> : <HeaderDesktop />;
};

export default Header;

import { makeStyles } from "@material-ui/core";
import React from "react";
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon,
    TelegramIcon,
    TelegramShareButton,
} from "react-share";

const ShareButtons: React.FC<{
    title: string;
    className: string;
    link: string;
    shareTitle: string;
    shareSummary: string;
}> = ({ title, shareTitle, shareSummary, className, link }) => {
    const classes = useStyles({});
    const iconSize = 32;

    const shareLink = `${window.location.origin}/share?hashCode=${link}`;

    return (
        <div className={[classes.share, className].join(" ")}>
            <span className={classes.title}>{title}</span>
            <div className={classes.wrapper}>
                <EmailShareButton url={shareLink} subject={shareTitle} body={shareSummary} translate={undefined}>
                    <EmailIcon size={iconSize} round crossOrigin={undefined} path={undefined} />
                </EmailShareButton>
                <FacebookShareButton url={shareLink} quote={shareSummary} translate={undefined}>
                    <FacebookIcon size={iconSize} round crossOrigin={undefined} path={undefined} />
                </FacebookShareButton>
                <WhatsappShareButton url={shareLink} title={shareSummary} translate={undefined}>
                    <WhatsappIcon size={iconSize} round crossOrigin={undefined} path={undefined} />
                </WhatsappShareButton>
                <TelegramShareButton url={shareLink} title={shareSummary} translate={undefined}>
                    <TelegramIcon size={iconSize} round crossOrigin={undefined} path={undefined} />
                </TelegramShareButton>
                <TwitterShareButton url={shareLink} title={shareSummary} translate={undefined}>
                    <TwitterIcon size={iconSize} round crossOrigin={undefined} path={undefined} />
                </TwitterShareButton>
                <LinkedinShareButton url={shareLink} title={shareTitle} summary={shareSummary} translate={undefined}>
                    <LinkedinIcon size={iconSize} round crossOrigin={undefined} path={undefined} />
                </LinkedinShareButton>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    share: {
        display: "flex",
        flexDirection: "column",
    },
    title: {
        color: "black",
        marginBottom: 10,
    },
    wrapper: {
        display: "flex",
        flexDirection: "row",
        gap: 5,
    },
});

export default ShareButtons;

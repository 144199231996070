import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TkConference } from "@tkbel/common";
import cx from "classnames";
import Participants from "./Participants";
import Gallery from "./Gallery";
import Details from "./Details";
import { RootState } from "../../redux/store";
import { onGetParticipantsForConference } from "../../redux/conferenceReducer";
import { makeStyles } from "@material-ui/styles";

export const GALLERY = "gallery";
export const PARTICIPANTS = "participants";
export const DETAILS = "details";

const SpeakerTabs: React.FC<{ conference: TkConference; confId: string; resume: () => void }> = (props) => {
    const classes = useStyles({});
    const [tab, setTab] = useState(GALLERY);
    const dispatch = useDispatch();
    const { participants, currentConference } = useSelector((state: RootState) => state.conference);
    const { profile } = useSelector((state: RootState) => state.user);

    const [active, setActive] = useState(false);

    useEffect(() => setActive(true), []);

    useEffect(() => {
        dispatch(onGetParticipantsForConference({ confId: currentConference.id, userId: profile.id }));
    }, []); // eslint-disable-line

    const RenderTabContent = () => {
        switch (tab) {
            // case PARTICIPANTS:
            //     return (
            //         <Participants
            //             data={participants}
            //             confId={currentConference.id}
            //             conference={currentConference}
            //             resume={props.resume}
            //         />
            //     );
            case GALLERY:
                return (
                    <Gallery
                        data={props.conference.presentationImages}
                        conference={props.conference}
                        confId={props.confId}
                        active={active}
                    />
                );
            default:
                return <Details conference={props.conference} />;
        }
    };

    return (
        <div className={classes.mainView}>
            <div className={classes.mainViewContainer}>
                <div className={classes.tabView}>
                    {/*}<span
                        className={cx(tab === PARTICIPANTS ? classes.activeTab : null, classes.tab)}
                        onClick={() => setTab(PARTICIPANTS)}
                    >
                        {PARTICIPANTS}
                    </span>*/}
                    <span
                        className={cx(tab === GALLERY ? classes.activeTab : null, classes.tab)}
                        onClick={() => setTab(GALLERY)}
                    >
                        {GALLERY}
                    </span>
                    <span
                        className={cx(tab === DETAILS ? classes.activeTab : null, classes.tab)}
                        onClick={() => setTab(DETAILS)}
                    >
                        {DETAILS}
                    </span>
                </div>
            </div>
            <RenderTabContent />
        </div>
    );
};
const useStyles = makeStyles({
    mainView: {},
    mainViewContainer: {
        flexDirection: "row",
        margin: "20px 0",
    },
    tabView: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tab: {
        cursor: "pointer",
        fontSize: 18,
        margin: 10,
        color: "#ffffff",
        paddingBottom: "10px",
        borderBottom: "4px solid transparent",
    },
    activeTab: {
        borderBottomColor: "#ffffff",
    },
    accessCodeContainer: {},
    accessCode: {},
});

export default SpeakerTabs;

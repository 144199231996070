import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: "#ED034E",
        },
        secondary: {
            main: "#19857b",
        },
        error: {
            main: red.A400,
        },
        background: {
            default: "#fff",
        },
    },
    overrides: {
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottomColor: "transparent",
                },
                "&:after": {
                    borderBottomColor: "transparent",
                },
                "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "transparent",
                },
            },
        },
        MuiFilledInput: {
            underline: {
                "&:before": {
                    borderBottomColor: "transparent",
                },
                "&:after": {
                    borderBottomColor: "transparent",
                },
                "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "transparent",
                },
            },
        },
    },
});

export default theme;

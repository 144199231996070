import React from "react";
import { FieldProps } from "formik";
import { makeStyles } from "@material-ui/core";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { I18n } from "@tkbel/common";

import { getNestedValue } from "../../../helpers/getNestedValue";

interface IInputProps extends FieldProps {
    label?: string;
}

const FormikInput = ({
    label,
    field: { value, onChange, ...field },
    form: { errors, touched },
    inputType,
    ...props
}: IInputProps) => {
    const classes = useStyles({});
    const fieldError = getNestedValue(errors, field.name);
    const fieldTouched = getNestedValue(touched, field.name);
    return (
        <div className={classes.wrapper}>
            {label && <label className={classes.label}>{label}</label>}
            {inputType === "phone" ? (
                <PhoneInput
                    countries={["FR", "ID", "IL", "US", "GB", "RU", "AE", "CN", "DE", "ES", "IT", "CA"]}
                    name="phone"
                    international={true}
                    withCountryCallingCode={true}
                    defaultCountry="FR"
                    placeholder={I18n.t("phone")}
                    value={value}
                    onChange={(phone) => onChange(formatPhoneNumberIntl(phone))}
                    {...field}
                    {...props}
                />
            ) : (
                <input value={value} onChange={onChange} {...field} {...props} />
            )}
            {fieldError && fieldTouched && <p className={classes.error}>{fieldError}</p>}
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",

        "& input:focus": {
            outline: "none",
        },
    },
    error: {
        color: "#cc0606",
        fontWeight: 500,
    },
    label: {
        fontWeight: 600,
        margin: "0 0 5px 1px!important",
    },
});

export default FormikInput;

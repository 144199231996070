import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Menu, MenuItem, Fade } from "@material-ui/core";
import { RouteChildrenProps } from "react-router";
import { withRouter } from "react-router-dom";
import { I18n } from "@tkbel/common";
import SVG from "react-inlinesvg";

// import { signOut } from "../firebase/auth";

import blackLogo from "../images/black_logo.svg";

const SpeakerProfileHeader: React.FC<RouteChildrenProps> = ({ history }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const classes = useStyle({});
    // const userContext = useContext(UserContext);
    // const currentUser = userContext.currentUser.data() as TkUser;

    // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        // signOut().catch((e) => {
        //     console.error("Logout Failed:", e);
        //     alert(I18n.t("internalError"));
        // });
    };
    return (
        <div className={classes.wrapper}>
            <div className={classes.logo}>
                <SVG src={blackLogo} className={classes.image} onClick={() => history.replace("/")} />
                <span className={classes.span}>{I18n.t("logoTitle")}</span>
            </div>
            <div className={classes.profile}>
                {/*<span className={classes.name}>{currentUser.firstName + " " + currentUser.lastName}</span>*/}
                {/*<Avatar alt="profile image" imgProps={{ onClick: handleClick }} src={currentUser.profileImage*/}
                {/*    ? currentUser.profileImage : <SVG src={noProfile} className={classes.image} onClick={() => history.replace("/")} />}/>*/}
                <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={handleLogout}>{I18n.t("logout")}</MenuItem>
                </Menu>
            </div>
        </div>
    );
};

const useStyle = makeStyles({
    wrapper: {
        width: "100%",
        height: "60px",
        position: "fixed",
        color: "black",
        fontSize: "16px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 20px",
        backgroundColor: "white",
        zIndex: 3,
    },
    logo: {
        display: "flex",
        flex: 1,
    },
    image: {
        cursor: "pointer",
    },
    span: {
        fontSize: "1rem",
        fontWeight: 100,
        marginLeft: "1.5rem",
    },
    profile: {
        display: "flex",
        flex: 1,
        height: "100%",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    name: {
        marginRight: "0.7rem",
        fontSize: "1rem",
    },
});

export default withRouter(SpeakerProfileHeader);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import {
    AllGroups,
    Arts,
    CareerBusiness,
    Environment,
    Health,
    Language,
    Movements,
    Family,
    Religion,
    Science,
    Social,
    Sports,
    Coaching,
    Technology,
    History,
    Geography,
    Cooking,
    Finance,
} from "./images";
import { I18n } from "@tkbel/common";

interface CategorySlideTypes {
    slide: any;
    showImages?: boolean;
}

const backgrounds = {
    "All groups": AllGroups,
    Arts: Arts,
    "Career & Business": CareerBusiness,
    "Environment and Tourism": Environment,
    "Health & Wellness": Health,
    "Language & Culture": Language,
    "Movements & Politics": Movements,
    Family: Family,
    "Religion & Spirituality": Religion,
    "Science & Education": Science,
    "Social activities": Social,
    "Sports & Fitness": Sports,
    Coaching: Coaching,
    Technology: Technology,
    History: History,
    Geography: Geography,
    Cooking: Cooking,
    Finance: Finance,
};

const CategorySlide: React.FC<CategorySlideTypes> = ({ slide, showImages }) => {
    const classes = useStyles({});
    return (
        <NavLink
            to={`/category/${slide.id}`}
            className={classes.slide}
            style={{ backgroundColor: showImages && backgrounds[slide.title] ? "rgba(0,0,0,0.8)" : slide.color }}
        >
            {showImages && backgrounds[slide.title] && (
                <div className={classes.bgImage}>
                    <img src={backgrounds[slide.title]} alt="" />
                </div>
            )}
            <div className={classes.title}>{I18n.t(slide.title)}</div>
        </NavLink>
    );
};

const useStyles = makeStyles({
    slide: {
        height: "164px",
        borderRadius: "8px",
        padding: "13px 24px",
        display: "flex",
        alignItems: "flex-end",
        boxSizing: "border-box",
        position: "relative",
        overflow: "hidden",
    },
    title: {
        color: "#ffffff",
        width: "100%",
        fontSize: "15px",
        position: "relative",
        zIndex: 1,
    },
    bgImage: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: "0.8",
        },
    },
});

export default CategorySlide;

import React, { useMemo, useRef, useState, useEffect, useCallback } from "react";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/styles";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputBase,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@material-ui/core";
import { Cancel, LocationOnOutlined } from "@material-ui/icons";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { I18n, languages } from "@tkbel/common";

import { black, linearBackgroud, red, veryLightGray, white } from "../../helpers/colors";
import SelectField from "../SelectField";
import GooglePlacesSearch from "../GooglePlacesSearch";
import ImageUpload from "../ImageUpload";
import { getFormatDD, getFormatHr, is24Hr } from "../../helpers/dateFormat";
import { RootState } from "../../redux/store";
import { LockerIcon, IconCalendar, IconDelete } from "../../assets/images";
import { onUploadMedia } from "../../redux/userReducer";
import { onGetCategories } from "../../redux/conferenceReducer";
import getUpload from "../../utils/getUrl";
import PdfUpload from "../PdfUpload";
import { bytesToSize } from "../../helpers/fileFunctions";
import { useAppDispatch } from "../../redux/hooks";

const baseInputStyle = {
    backgroundColor: veryLightGray,
    fontSize: "16px",
    borderRadius: "0.25rem",
    minHeight: "4rem",
    paddingLeft: "1rem",
    display: "flex",
    alignItems: "center",
    "&:focus": {
        outline: "none",
    },
};

interface Props {
    conference: any;
    goToPreview: (conference: any) => void;
}

interface FormValues {
    title: string;
    description: string;
    startTime: any;
    endTime: any;
    accessCode: string;
    address: string;
    country: string;
    city: string;
    longitude: number;
    latitude: number;
    price: number;
    posterImage: string;
    presentationImages: any;
    recordAudio: boolean;
    categoryId: string;
    language: string;
    type: string;
    isPrivate: boolean;
}

const hours = ["0", "1", "2", "3", "4", "5", "6"];
const minutes = ["00", "15", "30", "45"];

const EditForm: React.FC<Props> = ({ goToPreview, conference }) => {
    const { profile } = useSelector((state: RootState) => state.user);
    const { categories } = useSelector((state: RootState) => state.conference);
    const [location, setLocation] = useState();
    const [date, setDate] = useState(null);
    const [dateSelected, setDateSelected] = useState(false);
    const [durationH, setDurationH] = useState("HH");
    const [durationM, setDurationM] = useState("MM");
    const [addAccessCode, setAddAccessCode] = useState(false);
    const [image, setImage] = useState<any>(null);
    const [images, setImages] = useState<any[]>([]);
    const [documentUrl, setDocumentUrl] = useState<any>([]);
    const [documents, setDocuments] = useState<any[]>([]);
    const [hasDocuments, setHasDocuments] = useState(false);
    const [closeGooglePlaces, setCloseGooglePlaces] = useState(true);
    const [error, setError] = useState<boolean>(false);
    const [searchLocation, updateSearchLocation] = useState<string>("");
    const canEditAll = useRef(true);
    const dispatch = useAppDispatch();
    const [initialValues, updateInitialValues] = useState({
        title: "",
        description: "",
        startTime: null,
        endTime: null,
        accessCode: "",
        country: "",
        city: "",
        address: "",
        latitude: 0,
        longitude: 0,
        price: 0,
        posterImage: "",
        presentationImages: null,
        recordAudio: false,
        categoryId: 1,
        language: "English",
        documents: null,
        type: "video",
        isPrivate: false,
    });

    const imagesUrls = useMemo(
        () => images && images.map((image) => (typeof image === "string" ? image : URL.createObjectURL(image))),
        [images],
    );

    const addImage = (image) => {
        images.push(image);
        setImages(images.slice());
    };

    const deleteImage = (i) => {
        images.splice(i, 1);
        setImages(images.slice());
    };

    const classes = useStyle({});

    const onSubmitForm = useCallback(
        (values) => {
            const h = parseInt(durationH, 10);
            const m = parseInt(durationM, 10);
            const startTime = moment(date).toISOString();
            const endTime = moment(date).add(h, "hours").add(m, "minutes").toISOString();

            /* TODO remove lat, lon params, use Formik values */
            const conference = {
                ...values,
                startTime,
                endTime,
                posterImage: image,
                presentationImages: [...images],
                latitude: 37,
                longitude: 37,
                speakerId: profile.id,
                documents: documentUrl.slice(),
            };

            goToPreview(conference);
        },
        [date, durationH, durationM, goToPreview, image, images, profile.id, documentUrl],
    );

    const onGetDuration = useCallback((start, end) => {
        const diff = moment(end).diff(start, "minutes");
        setDurationH(Math.floor(diff / 60).toString());
        setDurationM(Math.floor(diff % 60) ? Math.floor(diff % 60).toString() : "00");
    }, []);

    useEffect(() => {
        if (conference) {
            updateInitialValues({
                title: conference.title || "",
                description: conference.description || "",
                startTime: conference.startTime || null,
                endTime: conference.endTime || null,
                accessCode: conference.accessCode || "",
                country: conference.country || "",
                city: conference.city || "",
                address: conference.address || "",
                latitude: conference.latitude || 0,
                longitude: conference.latitude || 0,
                price: conference.price || 0,
                posterImage: conference.posterImage || "",
                presentationImages: conference.presentationImages || null,
                recordAudio: conference.recordAudio || false,
                categoryId: conference.categoryId || 1,
                language: conference.language || "English",
                documents: conference.documents || null,
                type: conference.type || "video",
                isPrivate: conference.isPrivate || false,
            });

            onGetDuration(conference.startTime, conference.endTime);
            setAddAccessCode(!!conference.accessCode);
            setImage(conference.posterImage);
            setDate(conference.startTime);
            setImages(conference.presentationImages);

            if (conference.documents.length > 0) {
                setHasDocuments(true);
                let document_list = conference.documents.map((doc, i) => {
                    if (typeof doc == "string") return JSON.parse(doc);
                    else return doc;
                });
                setDocuments(document_list);
                setDocumentUrl(document_list);
            }
        }
    }, [conference, onGetDuration]);

    useEffect(() => {
        dispatch(onGetCategories());
    }, [dispatch]);

    useEffect(() => {
        if (durationH === "HH" && durationM !== "MM") {
            if (durationM === "00") {
                setDurationH("1");
            } else {
                setDurationH("0");
            }
        }
        if (durationM === "MM" && durationH !== "HH") {
            if (durationH === "0") {
                setDurationM("15");
            } else {
                setDurationM("00");
            }
        }
    }, [durationH, durationM]);

    const uploadMediaFile = useCallback(
        (file: File, callback: (image: string) => void) => {
            dispatch(onUploadMedia({ file, type: `user/${profile.id}/conference` }))
                .unwrap()
                .then((data: string) => callback(data));
        },
        [dispatch, profile.id],
    );

    const uploadDocumentFile = useCallback(
        (file: File, callback: (image: string) => void) => {
            dispatch(onUploadMedia({ file, type: `user/${profile.id}/conference` }))
                .unwrap()
                .then((data: string) => callback(file, data));
        },
        [dispatch, profile.id],
    );

    const uploadFile = useCallback((file, data) => {
        setDocumentUrl((documentUrl) => [
            ...documentUrl,
            {
                path: data,
                name: file.name,
                size: bytesToSize(file.size),
                price: 0,
            },
        ]);
        setDocuments((currDocs) => {
            if (currDocs) {
                currDocs.splice(currDocs.length, 0, {
                    path: file,
                    name: file.name,
                    size: bytesToSize(file.size),
                    price: 0,
                });
                return currDocs.slice();
            } else {
                return [
                    {
                        path: file,
                        name: file.name,
                        size: bytesToSize(file.size),
                        price: 0,
                    },
                ];
            }
        });
    }, []);

    const deleteFile = (i) => {
        let new_documents = documents.filter((document, key) => i !== key);
        setDocuments(new_documents);

        let new_documents_url = documentUrl.filter((document, key) => i !== key);
        setDocumentUrl(new_documents_url);
        // setDocumentUrl((currentDocumentUrl) => {
        //     currentDocumentUrl.splice(i, 1);
        //     return currentDocumentUrl.slice();
        // })
    };

    return (
        <div className={classes.wrapper}>
            {!canEditAll.current && (
                <span className={classes.msg}>{`${I18n.t("onlyDocs")}, ${I18n.t("onlyDocsContinue")}`}</span>
            )}
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmitForm}>
                {({ setFieldValue, values, setValues }: FormikProps<FormValues>) => (
                    <Form translate={""}>
                        <div className={classes.titleWrapper}>
                            <InputBase
                                className={classes.titleInput}
                                value={values.title}
                                placeholder={I18n.t("confTitlePlaceHolder")}
                                multiline={true}
                                onChange={(e) => {
                                    e.target.value.length < 61 && setFieldValue("title", e.target.value);
                                }}
                                style={{
                                    color: values.title ? red : black,
                                    backgroundColor: !canEditAll.current ? white : veryLightGray,
                                }}
                                disabled={!canEditAll.current}
                            />
                            {/*{id && canEditAll.current && (*/}
                            {/*    <Fab className={classes.fab} onClick={() => setShowDeletePopup(true)}>*/}
                            {/*        <Delete />*/}
                            {/*    </Fab>*/}
                            {/*)}*/}
                        </div>
                        <div className={classes.imageUploadWrapperDiv}>
                            <ImageUpload
                                multiple={false}
                                keyProp={"poster-image"}
                                image={image}
                                setImage={(file) => uploadMediaFile(file, setImage)}
                                needCrop={true}
                                posterImage={values.posterImage || profile.profileImage}
                                cropType="conference"
                                disabled={!canEditAll.current}
                            />
                        </div>
                        <InputBase
                            className={classes.descriptionInput}
                            value={values.description}
                            placeholder={I18n.t("resume")}
                            multiline={true}
                            onChange={(e) => setFieldValue("description", e.target.value)}
                            disabled={!canEditAll.current}
                            style={{ backgroundColor: !canEditAll.current ? white : veryLightGray }}
                        />
                        <div
                            className={classes.dateDurationWrapper}
                            style={{ backgroundColor: canEditAll.current ? veryLightGray : white }}
                        >
                            <div className={classes.dateDiv}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        className={classes.dateTimePicker}
                                        style={{ backgroundColor: canEditAll.current ? veryLightGray : white }}
                                        variant="inline"
                                        format={`${getFormatDD()} ${getFormatHr()}`}
                                        onChange={(newDate: Moment) => {
                                            setDate(newDate);
                                        }}
                                        onClose={() => {
                                            setDateSelected(true);
                                        }}
                                        autoOk
                                        ampm={!is24Hr()}
                                        value={date}
                                        disablePast
                                        PopoverProps={{
                                            PaperProps: {
                                                className: classes.paperDateStyle,
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <div className={classes.start}>
                                                    <SVG src={IconCalendar} className={classes.dateIcon} />
                                                    {date ? (
                                                        <></>
                                                    ) : (
                                                        <span className={classes.datePlaceholder}>Date</span>
                                                    )}
                                                </div>
                                            ),
                                            error: error,
                                        }}
                                        onError={(e) => {
                                            e && setError(true);
                                        }}
                                        helperText={
                                            // TODO setError when date and time selected a past time
                                            dateSelected && error
                                                ? `${I18n.t("invalidTime")}: ${I18n.t("invalidTimeMessage")}`
                                                : ""
                                        }
                                        disabled={!canEditAll.current}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.durationPicker}>{I18n.t("duration")}</div>
                            <div className={classes.pickerDiv}>
                                <SelectField
                                    menuItems={durationM === "00" ? hours.slice(1) : hours}
                                    placeholder={durationH === "HH"}
                                    onChange={setDurationH}
                                    placeholderText="HH"
                                    value={durationH}
                                    disabled={!canEditAll.current}
                                />
                            </div>
                            <span>:</span>
                            <div className={classes.pickerDiv}>
                                <SelectField
                                    menuItems={durationH === "0" ? minutes.slice(1) : minutes}
                                    placeholder={durationM === "MM"}
                                    onChange={setDurationM}
                                    placeholderText="MM"
                                    value={durationM}
                                    disabled={!canEditAll.current}
                                />
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            <InputBase
                                placeholder={I18n.t("location")}
                                startAdornment={<LocationOnOutlined className={classes.locationIcon} />}
                                endAdornment={
                                    searchLocation.length > 0 ? (
                                        <Cancel
                                            onClick={() => {
                                                updateSearchLocation("");
                                                setValues({ ...values, address: "" });
                                            }}
                                            className={classes.cancelIcon}
                                        />
                                    ) : null
                                }
                                style={{
                                    minHeight: location ? "2.3rem" : "4rem",
                                    backgroundColor: !canEditAll.current ? white : veryLightGray,
                                }}
                                className={classes.placeInput}
                                onChange={(e) => updateSearchLocation(e.target.value)}
                                value={values.address || searchLocation}
                                onFocus={() => setCloseGooglePlaces(false)}
                                disabled={!canEditAll.current}
                            />
                            {searchLocation.length > 2 && !closeGooglePlaces && (
                                <GooglePlacesSearch
                                    onChoose={(location) => {
                                        setValues({ ...values, ...location });
                                        setCloseGooglePlaces(true);
                                        setLocation(location);
                                    }}
                                    searchText={searchLocation}
                                    top={values.address ? "2.3rem" : "4rem"}
                                />
                            )}
                        </div>
                        <div className={classes.text}>{I18n.t("presentationImages")}</div>
                        <div style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <Box
                                className={classes.box}
                                component="div"
                                my={2}
                                overflow="auto"
                                bgcolor="background.paper"
                            >
                                <div className={classes.presentationWrapper}>
                                    {imagesUrls &&
                                        imagesUrls.length > 0 &&
                                        imagesUrls.map((img, i) => (
                                            <div key={`img-key-${i}`} className={classes.presentationImage}>
                                                {canEditAll.current && (
                                                    <SVG
                                                        src={IconDelete}
                                                        className={classes.delete}
                                                        onClick={() => deleteImage(i)}
                                                    />
                                                )}
                                                <img
                                                    alt="presentation"
                                                    className={classes.image}
                                                    src={getUpload(img)}
                                                />
                                            </div>
                                        ))}
                                    <ImageUpload
                                        multiple={true}
                                        style={images.length > 0 ? { marginLeft: "0.5rem" } : {}}
                                        keyProp={"presentation-image"}
                                        needCrop={false}
                                        setImage={(file) => uploadMediaFile(file, addImage)}
                                        disabled={!canEditAll.current}
                                    />
                                </div>
                            </Box>
                            <div className={classes.checkbox}>
                                <Checkbox
                                    onChange={(e) => setAddAccessCode(e.target.checked)}
                                    checked={addAccessCode}
                                    color="primary"
                                    disabled={!canEditAll.current}
                                />
                                <div className={classes.text}>{I18n.t("accessCode")}</div>
                                <SVG src={LockerIcon} className={classes.lockIcon} />
                                {addAccessCode && (
                                    <InputBase
                                        className={classes.accessInput}
                                        value={values.accessCode}
                                        onChange={(e) =>
                                            setFieldValue(
                                                "accessCode",
                                                e.target.value.length < 15
                                                    ? e.target.value
                                                    : e.target.value.substring(0, 15),
                                            )
                                        }
                                        disabled={!canEditAll.current}
                                        style={{ backgroundColor: !canEditAll.current ? white : veryLightGray }}
                                    />
                                )}
                            </div>
                            <div className={classes.checkbox}>
                                <Checkbox
                                    onChange={(e) => setFieldValue("isPrivate", e.target.checked)}
                                    checked={values.isPrivate}
                                    color="primary"
                                    disabled={!canEditAll.current}
                                />
                                <div className={classes.text}>{I18n.t("isPrivate")}</div>
                            </div>
                            <div className={classes.checkbox}>
                                <Checkbox
                                    onChange={(e) => setFieldValue("recordAudio", e.target.checked)}
                                    checked={values.recordAudio}
                                    color="primary"
                                    disabled={!canEditAll.current}
                                />
                                <div className={classes.text}>{I18n.t("recordAudio")}</div>
                            </div>
                            <div className={classes.radioGroup}>
                                <FormControl>
                                    <FormLabel component="legend">{I18n.t("typeOfConference")}:</FormLabel>
                                    <RadioGroup
                                        aria-label="type"
                                        name="type"
                                        value={values.type}
                                        onChange={(e) => setFieldValue("type", e.target.value)}
                                    >
                                        <FormControlLabel
                                            value="video"
                                            control={<Radio classes={{ colorSecondary: classes.radioBtn }} />}
                                            label={I18n.t("video")}
                                        />
                                        <FormControlLabel
                                            value="audio"
                                            control={<Radio classes={{ colorSecondary: classes.radioBtn }} />}
                                            label={I18n.t("audioSelect")}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className={classes.radioGroup}>
                                <FormControl>
                                    <FormLabel component="legend">{I18n.t("chooseLanguage")}:</FormLabel>
                                    <Select
                                        value={values.language}
                                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                            setFieldValue("language", e.target.value as string)
                                        }
                                        displayEmpty
                                    >
                                        {languages.map((item, i) => (
                                            <MenuItem key={`conf_lang_${i}`} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.radioGroup}>
                                <FormControl>
                                    <FormLabel component="legend">{I18n.t("chooseCategory")}:</FormLabel>
                                    <Select
                                        value={values.categoryId}
                                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                                            setFieldValue("categoryId", e.target.value as string)
                                        }
                                        displayEmpty
                                    >
                                        {categories
                                            .filter((category) => category.active)
                                            .map((item, i) => (
                                                <MenuItem key={`conf_lang_${i}`} value={item.id}>
                                                    {item.title}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <PdfUpload
                                onSelect={(file) => uploadDocumentFile(file, uploadFile)}
                                documents={documents}
                                hasDocuments={hasDocuments}
                                setHasDocuments={setHasDocuments}
                                onDeletePdf={deleteFile}
                                canEdit
                            />
                        </div>

                        <Button
                            className={classes.previewButton}
                            type="submit"
                            disabled={
                                !values.title ||
                                !values.title.trim() ||
                                values.title.length < 2 ||
                                !values.description ||
                                // (!image && !currentUser.profileImage) ||
                                !values.description.trim() ||
                                !date ||
                                // !values.address ||
                                !image ||
                                durationH === "HH" ||
                                durationM === "MM" ||
                                (durationH === "0" && durationM === "00") ||
                                (addAccessCode && (!values.accessCode || values.accessCode.trim() === ""))
                                // || (hasDocuments && (!documents || (documents && documents.length === 0)))
                            }
                        >
                            {I18n.t("preview")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const useStyle = makeStyles({
    wrapper: {
        padding: "40px 20px",
        backgroundColor: "white",
        borderRadius: "10px",
        width: "100%",
        margin: "0 auto",
        boxSizing: "border-box",
        // eslint-disable-next-line
        ["@media (max-width: 780px)"]: {
            padding: "40px 0px",
        },
    },
    titleWrapper: {
        display: "flex",
    },
    titleInput: {
        fontWeight: 600,
        ...baseInputStyle,
        fontSize: "20px",
        textAlign: "start",
        flex: 1,
        display: "flex",
    },
    fab: {
        borderRadius: 0,
        background: "none",
        boxShadow: "none",
        width: "4rem",
        height: "4rem",
        alignSelf: "center",
        "&:hover": {
            backgroundColor: "#FAFAFA",
        },
        "&:focus": {
            backgroundColor: "#FAFAFA",
        },
        "&:visited": {
            backgroundColor: "#FAFAFA",
        },
    },
    descriptionInput: {
        ...baseInputStyle,
        fontSize: "18px",
        alignItems: "flex-start",
        minHeight: "8.5rem",
        margin: "0 0 0.5rem 0",
        textAlign: "start",
    },
    dateTimePicker: {
        cursor: "pointer",
        width: "100%",
        flexDirection: "row",
        ...baseInputStyle,
    },
    paperDateStyle: {
        left: "6.3rem!important",
        marginTop: "1rem",
        // eslint-disable-next-line
        ["@media (max-width: 578px)"]: {
            left: "3rem!important",
            marginTop: "0.5rem",
        },
    },
    start: {
        display: "flex",
        alignItems: "center",
    },
    dateIcon: {
        fontSize: 0,
        marginRight: "1rem",
    },
    datePlaceholder: {
        fontSize: "16px",
        color: "#a8a9aa",
    },
    dateDurationWrapper: {
        display: "flex",
        alignItems: "center",
        backgroundColor: veryLightGray,
        flexWrap: "wrap",
        // eslint-disable-next-line
        ["@media (max-width: 578px)"]: {
            fontSize: 16,
        },
    },
    dateDiv: {
        maxWidth: "50%",
        // eslint-disable-next-line
        ["@media (max-width: 578px)"]: {
            minWidth: 168,
            width: "100%",
            maxWidth: "unset",
            marginBottom: 5,
        },
    },
    durationPicker: {
        flex: 5,
        backgroundColor: "#FFFFFF",
        height: "4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        fontWeight: 500,
        fontSize: "18px",
        paddingRight: "1rem",
        "&:focus": {
            backgroundColor: "#FAFAFA",
        },
        // eslint-disable-next-line
        ["@media (max-width: 578px)"]: {
            flex: 0,
        },
    },
    pickerDiv: {
        flex: 1,
    },
    checkbox: {
        display: "flex",
        alignItems: "center",
    },
    text: {
        fontSize: "18px",
        fontWeight: 500,
        textAlign: "start",
        margin: "0.5rem 0",
        alignSelf: "baseline",
        whiteSpace: "break-spaces",
    },
    placeInput: {
        ...baseInputStyle,
        marginTop: "0.5rem",
        transition: "height 1s linear",
    },
    locationIcon: {
        marginRight: "1rem",
    },
    address: {
        height: "16px",
        fontSize: "16px",
        backgroundColor: "#FAFAFA",
        color: "#8f9192",
        textAlign: "start",
        paddingLeft: "3.5rem",
    },
    imageUploadWrapperDiv: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "2rem 0",
        transition: "width linear 1s",
    },
    imagesWrapper: {
        display: "flex",
    },
    presentationImage: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 0.5rem",
    },
    image: {
        maxWidth: "12.5rem",
        maxHeight: "12.5rem",
        marginTop: "-1.3rem",
    },
    delete: {
        width: "2rem",
        height: "2rem",
        zIndex: 1,
        marginTop: "-1.1rem",
        alignSelf: "flex-end",
        marginRight: "-1rem",
    },
    presentationWrapper: {
        display: "flex",
        width: "max-content",
    },
    box: {
        padding: "1rem 0",
    },
    previewButton: {
        backgroundImage: linearBackgroud,
        width: "200px",
        height: "50px",
        fontSize: "16px",
        color: "white",
        "&:disabled": {
            opacity: 0.6,
            color: "white",
        },
    },
    cancelIcon: {
        marginRight: "1rem",
        cursor: "pointer",
    },
    accessInput: {
        ...baseInputStyle,
        marginLeft: "2rem",
        paddingRight: "1rem",
        flex: 1,
        alignSelf: "baseline",
    },
    lockIcon: {
        width: "1.5rem",
        height: "1.5rem",
        marginLeft: "0.8rem",
        alignSelf: "baseline",
    },
    msg: {
        fontWeight: 600,
        fontFamily: "Roboto",
        color: red,
        textAlign: "center",
        alignSelf: "center",
        fontSize: "1.7rem",
    },
    radioGroup: {
        textAlign: "left",
        padding: "0 10px",
        margin: "15px 0",
        "& > .MuiFormControl-root .MuiFormLabel-root": {
            fontSize: 18,
            color: black,
            fontWeight: 500,
            fontFamily: "Poppins !important",
            margin: "0 0 15px",
        },
        "& .MuiFormControlLabel-label": {
            fontSize: 16,
        },
        "& .MuiInput-input": {
            fontSize: 16,
        },
    },
    radioBtn: {
        color: "#ed034e",
        "&.Mui-checked": {
            color: "#ed034e",
        },
    },
    selectContainer: {},
});

export default EditForm;

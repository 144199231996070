import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import cx from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

import { linearBackgroud, red } from "../../helpers/colors";
import InstructorSlide from "../InstructorSlide";
import CourseSlide from "../CourseSlide";
import CategorySlide from "../CategorySlide";

import { PrevIcon, NextIcon, Arrows } from "../../assets/images";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useHistory } from "react-router";
import CourseSlideWithBg from "../CourseSlideWithBg";

import { I18n } from "@tkbel/common";

SwiperCore.use([Navigation, Autoplay]);

interface SliderTypes {
    title?: string;
    titleSize?: number;
    slides: any;
    slideWrapper: "course" | "category" | "people" | "courseWithBg";
    slidesPerView?: number | "auto";
    linkToMore?: string;
    showLinkViewAll?: boolean;
    breakpoints?: object;
}

const Slider: React.FC<SliderTypes> = ({
    title,
    titleSize,
    slides,
    slideWrapper,
    slidesPerView,
    linkToMore,
    showLinkViewAll,
    breakpoints,
}) => {
    const classes = useStyles({});
    const history = useHistory();
    const navigationPrevRef = React.useRef<HTMLDivElement>(null);
    const navigationNextRef = React.useRef<HTMLDivElement>(null);

    const SlideWrapper = ({ slide }) => {
        switch (slideWrapper) {
            case "course":
                return <CourseSlide slide={slide} history={history} />;
            case "category":
                return <CategorySlide slide={slide} showImages={true} />;
            case "people":
                return <InstructorSlide slide={slide} />;
            case "courseWithBg":
                return <CourseSlideWithBg slide={slide} history={history} />;
            default:
                return null;
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.sliderTitle}>
                <p style={titleSize && { fontSize: titleSize }}>{title}</p>
                {showLinkViewAll && linkToMore && (
                    <span onClick={() => history.push(linkToMore)}>
                        {I18n.t("viewAll")} <Arrows />
                    </span>
                )}
            </div>
            <div className={classes.slider}>
                <Swiper
                    className={classes.swiper}
                    spaceBetween={25}
                    slidesPerView={slidesPerView}
                    navigation={{
                        prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
                        nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
                    }}
                    breakpoints={breakpoints}
                    onInit={(swiper) => {
                        // @ts-ignore
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        // @ts-ignore
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                        swiper.navigation.update();
                    }}
                >
                    {slides.length > 0 &&
                        slides.map((slide, index) => (
                            <>
                                <SwiperSlide key={index}>
                                    <SlideWrapper slide={slide} />
                                </SwiperSlide>
                            </>
                        ))}
                    <div className={cx(classes.navBtn, classes.navPrev)} ref={navigationPrevRef}>
                        <SVG src={PrevIcon} onClick={(e) => e.stopPropagation()} />
                    </div>
                    <div className={cx(classes.navBtn, classes.navNext)} ref={navigationNextRef}>
                        <SVG src={NextIcon} onClick={(e) => e.stopPropagation()} />
                    </div>
                </Swiper>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        margin: "35px 0",
        textAlign: "left",
    },
    sliderTitle: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        margin: "0 0 15px",
        width: "100%",
        "& p": {
            color: "#222b45",
            fontSize: "26px",
        },

        "& span": {
            color: red,
            fontSize: "16px",
            cursor: "pointer",
        },

        "& svg": {
            width: 10,
            height: 10,
        },
    },
    slider: {
        position: "relative",
    },
    swiper: {
        margin: "0 -22px",
        padding: "0 22px",
        "& .swiper-button-next": {
            display: "none",
        },
        "& .swiper-button-prev": {
            display: "none",
        },
        "& .swiper-wrapper": {
            height: "auto",
        },
    },
    slide: {
        height: "100%",
    },
    image: {
        width: "100%",
        height: "150px",
        borderRadius: "10px",
        overflow: "hidden",
        margin: "0 0 10px",
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    title: {
        color: "#222b45",
        width: "100%",
        fontSize: "18px",
        margin: "5px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    rating: {
        display: "flex",
        alignItems: "center",
    },
    price: {
        color: "#fd0053",
        fontSize: "16px",
        fontWeight: 600,
        margin: "10px 0 0",
    },
    button: {
        height: "50px",
        padding: "10px",
        boxSizing: "border-box",
        fontSize: "14px",
        fontWeight: 600,
        background: linearBackgroud,
        color: "#ffffff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "10px 0 0",
        borderRadius: "4px",
        cursor: "pointer",
    },
    speaker: {
        color: "#8f9bb3",
        fontSize: "18px",
        margin: "5px 0",
    },
    rateSign: {
        display: "flex",
        alignItems: "center",
        color: "#8f9bb3",
        fontSize: "13px",
        "& svg": {
            margin: "0 4px 0 0",
        },
    },
    reviews: {
        color: "#c5cee0",
        fontSize: "13px",
        margin: "0 0 0 6px",
    },
    navBtn: {
        width: "50px",
        height: "50px",
        position: "absolute",
        top: "50%",
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        transform: "translate(0, -50%)",
        "&.swiper-button-disabled": {
            opacity: "0.8",
            cursor: "not-allowed",
        },
        "& svg": {
            width: "30px",
            height: "30px",
        },
    },
    navPrev: {
        left: "-15px",
    },
    navNext: {
        right: "-15px",
    },
});

export default Slider;
